import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as ArrowRightIcon } from 'common/assets/images/arrow-narrow-right.svg';
import { ReactComponent as BlackArrowRightIcon } from 'common/assets/images/arrow-narrow-right-black.svg';
import { RedTheme } from 'common/global';
import { useScreen } from 'common/hooks/useScreen';


export const LoaDialog = ({
  isOpen,
  isCloseIconVisible,
  onClose = () => {},
  isLeftButtonVisible,
  leftButtonText,
  onLeftButtonClick,
  isRightButtonVisible,
  rightButtonText,
  onRightButtonClick,
  dialogTitle,
  dialogBodyMessage1,
  dialogBodyMessage2,
}) => {
  let redtheme = RedTheme;
  const screen = useScreen();

  const handleClose = () => {
    onClose();
  };

  const handleRightButtonClick = () => {
    onRightButtonClick();
  };

  const handleLeftButtonClick = () => {
    onLeftButtonClick();
  };
  return (
    <Dialog
      data-testid='loaDialog'
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{
        sx: {
          width: screen.isMobile ? '99vw' : '420px',
          height: screen.isMobile ? '325px' : '340px',
          borderRadius: '16px',
          padding:screen.isMobile ? '14px 28px' : '24px 32px',
          overflow : 'hidden'
        },
      }}
      sx={{
        backdropFilter: 'blur(8px)',
        marginTop: '167px',
      }}
    >
      <DialogTitle id='alert-dialog-title' sx={{ padding: 0 }}>
        <Typography
          data-testid='goToSummitDialog'
          component='div'
          variant='div'
          sx={{
            width: '320px',
            fontSize: '31px',
            lineHeight: '32px',
            fontWeight: 700,
            fontFamily: '"JohnsonDisplay-Bold"',
            height: '35px',
          }}
        >
          {dialogTitle}
        </Typography>
      </DialogTitle>
      {isCloseIconVisible && (
        <IconButton onClick={handleClose} style={{ position: 'absolute', top: 5, right: 3 }}>
          <CloseIcon />
        </IconButton>
      )}
      <DialogContent
        sx={{
          padding: '0px',
          marginTop: '16px',
          overflow : 'hidden'
        }}
      >
        <DialogContentText id='alert-dialog-description' sx={{ padding: 0 }}>
          <Typography
            component='span'
            sx={{
              width: '420px',
              height: '290px',
              fontSize: '16px',
              lineHeight: '16px',
              fontWeight: 500,
              fontFamily: '"JohnsonText-Light"',
            }}
          >
            {dialogBodyMessage1}
            <br /> <br />
            {dialogBodyMessage2}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          top: 'auto',
          bottom: 0,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
          gap: '15px',
          background: redtheme.palette.background.default,
          height: screen.isMobile ? '50px' : '64px',
          flexWrap: 'nowrap',
        }}
      >
        {isLeftButtonVisible && (
          <Button
            onClick={handleLeftButtonClick}
            variant='contained'
            size='medium'
            endIcon={<BlackArrowRightIcon />}
            sx={{
              left: '0%',
              right: '0%',
              top: '0%',
              bottom: '0%',
              width: '90%',
              height: '40px',
              background: redtheme.palette.background.default,
              color: redtheme.palette.button.black,
              ':hover': {
                background: redtheme.palette.background.default,
                color: redtheme.palette.button.black,
              },
              textTransform: 'none',
              borderRadius: '12px',
              border: `1px solid ${redtheme.palette.button.pure_red}`,
              fontSize: '16px',
              lineHeight: '16px',
              fontWeight: 500,
              fontFamily: '"JohnsonText-Light"',
            }}
          >
            {leftButtonText}
          </Button>
        )}
        {isRightButtonVisible && (
          <Button
            onClick={handleRightButtonClick}
            data-testid='askGsBtn'
            variant='contained'
            size='medium'
            endIcon={<ArrowRightIcon />}
            sx={{
              left: '0%',
              right: '0%',
              top: '0%',
              bottom: '0%',
              width: '50%',
              height: '40px',
              background: redtheme.palette.primary.main,
              color: redtheme.palette.background.default,
              ':hover': {
                background: redtheme.palette.primary.main,
                color: redtheme.palette.background.default,
              },
              textTransform: 'none',
              borderRadius: '12px',
              fontSize: '18px',
              lineHeight: '24px',
              fontWeight: 500,
              fontFamily: '"JohnsonText-Light"',
            }}
          >
            {rightButtonText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};
