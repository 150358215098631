/**
* TrainingMetricsReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const TrainingMetricsReducer = (state = {}, action) => {
  switch (action.type) {
    /****
     * GET_DASHBOARD_DATA
     ****/
    case 'GET_DASHBOARD_DATA':
      return {
        ...state,
        dashboardDetails: action.payload,
      };
    /****
     * POST_COMPLETIONBYDEPT_DATA
     ****/
    case 'POST_COMPLETIONBYDEPT_DATA':
      return {
        ...state,
        completionByDeptDetails: action.payload,
      };
    /****
     * GET_COMPLETIONBY_TIMELINESS_DATA
     ****/
    case 'GET_COMPLETIONBY_TIMELINESS_DATA':
      return {
        ...state,
        completionByTimelinessDetails: action.payload,
      };
    /****
     * GET_COMPLETION_ON_TIME_DATA
     ****/
   
    case 'GET_TRAINING_STATUS_DATA':
      return {
        ...state,
        trainingStatusDetails: action.payload
      };
    case 'GET_TRAININGTITLES_DATA_SEARCH':
      return {
        ...state,
        searchTrainingTitlesData: action.payload
      };
    case 'POST_BARCHART_DATA':
      return {
        ...state,
        barChartDetails: action.payload,
      };

    case 'CHECK_DUPLICATE_RECORDS':
      return {
        ...state,
        duplicatecount: action.payload,
      };

    case 'API_START':
     
      if (action.payload === "FETCH_TRAININGSTATUS_DATA") {
        return {
          ...state,
          isLoadingMyPerfTrainingStatusData: true,
        };
      }
      else if (action.payload === "FETCH_TRAININGTITLES_DATA_SEARCH") {
        return {
          ...state,
          isSearchingTrainingTitleData: true,
        };
      } else {
        return state;
      }

    case 'API_END':
    
      if (action.payload === "FETCH_TRAININGSTATUS_DATA") {
        return {
          ...state,
          isLoadingMyPerfTrainingStatusData: false,
        };
      }
      else if (action.payload === "FETCH_TRAININGTITLES_DATA_SEARCH") {
        return {
          ...state,
          isSearchingTrainingTitleData: false,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};
