export const TrainingViewIndividualUserReducer = (state = {}, action) => {
    switch (action.type) {
        case 'POST_TRAINING_VIEW_INDIVIDUAL_USER_DETAILS':
            return {
                ...state,
                APIResponse: action.payload
            }

        case 'API_START':
            if (action.payload === 'FETCH_TRAINING_VIEW_INDIVIDUAL_USER_DATA') {
                return {
                    ...state,
                    isFetchingTVIndividualUserData: true,
                };
            }
            break;

        case 'API_END':
            if (action.payload === 'FETCH_TRAINING_VIEW_INDIVIDUAL_USER_DATA') {
                return {
                    ...state,
                    isFetchingTVIndividualUserData: false,
                };
            }
            break;
        default:
            return state;
    }
    return state;
};

export const TrainingViewIndividualUserSearchSuggestionsReducer = (state = {}, action) => {
    switch (action.type) {
        case 'POST_TRAINING_VIEW_INDIVIDUAL_USER_CURRICULUM_SEARCH_SUGGESTIONS':
            return {
                ...state,
                curriculumResponse: action.payload
            }
        case 'POST_TRAINING_VIEW_INDIVIDUAL_USER_TRAINING_SEARCH_SUGGESTIONS':
            return {
                ...state,
                trainingResponse: action.payload
            }
        default:
            return state;
    }
};

