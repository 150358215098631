
import apiAction from 'common/redux/actions/apiAction';
import {
  POST_TEAM_DIRECT_REPORT_TRAINING_OVERDUE_URL,
  POST_TEAM_DIRECT_REPORT_TRAINING_UP_COMING_DUE_URL,
} from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

const getDirectTrainingOverdueData = data => {
  return {
    type: 'GET_DIRECT_TRAINING_OVERDUE_DATA',
    payload: data,
  };
};

export const DirectTrainingOverdueData = configParams => {
  return apiAction.postAPI({
    url: POST_TEAM_DIRECT_REPORT_TRAINING_OVERDUE_URL(),
    data: configParams,
    onSuccess: getDirectTrainingOverdueData,
    onFailure: err =>  logError('Error occurred in fetching DirectTrainingOverdueData:', err),
    label: 'FETCH_DIRECT_TRAININGOVERDUE_DATA',
  });
};
const getDirectTrainingUpcomingOverdueData = data => {
  return {
    type: 'GET_DIRECT_TRAINING_UPCOMINGDUE_DATA',
    payload: data,
  };
};

export const DirectTrainingUpcomingOverdueData = configParams => {
  return apiAction.postAPI({
    url: POST_TEAM_DIRECT_REPORT_TRAINING_UP_COMING_DUE_URL(),
    data: configParams,
    onSuccess: getDirectTrainingUpcomingOverdueData,
    onFailure: err => logError('Error occured in DirectTrainingUpcomingOverdueData data.:', err),
    label: 'FETCH_DIRECT_TRAININGUPCOMINGDUE_DATA',
  });
};
