import React from 'react';
import { Tooltip, Typography, Box, tooltipClasses, styled } from '@mui/material';
import {  RedTheme } from 'common/global';

let redtheme =RedTheme;
const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: redtheme.palette.tooltip.dark_blue,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: redtheme.palette.tooltip.white,
        color: redtheme.palette.tooltip.black_gray,
        fontSize: '14px',
        border: `3px solid #${redtheme.palette.tooltip.dark_blue}`,
        borderRadius: '11px',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        padding: '20px',
        gap: '8px',
    },
}));

export const ToolTipWrapperDataGrid = ({ title, placement, header, children }) => {
    return (
        <HtmlTooltip
            placement={placement}
            title={
                <>
                    <Typography
                        variant='body1'
                        sx={{
                            fontFamily: '"J&J CircularTT Black Web"',
                            color: redtheme.palette.tooltip.black,
                            fontWeight: '700',
                            fontSize: '18px',
                            marginBottom: '8px',
                        }}
                    >
                        {header}
                    </Typography>
                    {title}
                </>
            }
            arrow
        >
            <Box component='span'>
                {children}
            </Box>
        </HtmlTooltip>
    );
};
