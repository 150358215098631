import React from 'react';
import { TrainingTile } from './trainingTile';
import { Grid } from '@mui/material';
import { useScreen } from 'common/hooks/useScreen';

export const ActionButtonWrapper = ({
  processDataLeft,
  processDataRight,
  handlePopupButtonClick,
  handleSelectedDaysChange,
  selectedDays,
  toolTipHeaderTextLeft,
  toolTipTitleTextLeft,
  fontcolorLeft,
  toolTipHeaderTextRight,
  toolTipTitleTextRight,
  fontcolorRight,
  actionTypeLeft,
  tileTypeTextLeft,
  actionTypeRight,
  tileTypeTextRight,
  isComplianceAction
}) => {
  const screen = useScreen(); 
  return (
    <>
    {/* <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}> */}
    <Grid container spacing={2}>
    <Grid item xs={screen.isMobile ? 12 : 6}>
      <TrainingTile
        progressData={processDataLeft}
        handlePopupButtonClick={handlePopupButtonClick}
        handleSelectedDaysChange={handleSelectedDaysChange}
        toolTipHeaderText={toolTipHeaderTextLeft}
        toolTipTitleText={toolTipTitleTextLeft}
        selectedDays={selectedDays}
        actionType={actionTypeLeft}
        tileTypeText={tileTypeTextLeft}
        fontcolor={fontcolorLeft}
        isComplianceAction = {isComplianceAction}
      />
      </Grid>
      <Grid item xs={screen.isMobile ? 12 : 6}>
      <TrainingTile
        progressData={processDataRight}
        handlePopupButtonClick={handlePopupButtonClick}
        handleSelectedDaysChange={handleSelectedDaysChange}
        toolTipHeaderText={toolTipHeaderTextRight}
        toolTipTitleText={toolTipTitleTextRight}
        selectedDays={selectedDays}
        actionType={actionTypeRight}
        tileTypeText={tileTypeTextRight}
        fontcolor={fontcolorRight}
        isComplianceAction = {isComplianceAction}
      />
      </Grid>
      </Grid>
    {/* </Box> */}

  </>
  );
};
