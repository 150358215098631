import React, { useState, useEffect } from "react";
import axios from "axios";
import { getAuthToken } from "../authentication/authToken";
import { UserDetail } from "./userDetail";
import Avatar from '@mui/material/Avatar';
import { logError } from 'TAP/utils/commonMethods';
import { useScreen } from 'common/hooks/useScreen';
/**
 * UserProfileImage.
 * This component using Avatar component to show image
 * This component using UserDetail component to get user data
 * @returns {JSX.Element}
 */
export const UserProfileImage = () => {
  let token = getAuthToken();

  const [initializer, setInitializer] = useState(true);
  const [imageURL, setImageURL] = useState('');
  const { isMobile } = useScreen();

  useEffect(() => {
    if (initializer && token?.graphToken) {
      axios
        .get('https://graph.microsoft.com/v1.0/me/photo/$value', {
          responseType: 'blob',
          headers: { Authorization: `Bearer ${token.graphToken}` },
        })
        .then((response) => {
          let imgUrl = URL.createObjectURL(response.data);
          setImageURL(imgUrl);
        })
        .catch((error) => {
          logError('Profile photo not found for the logged in user. ', error);
        });
      setInitializer(false);
    }
  }, [initializer, token]);

  return (
    <>
      <Avatar
        sx={isMobile ? { height: '32px', width: '32px', fontSize: '18px' } : {}}
        alt='Profile Photo'
        src={imageURL}
        data-testid='profileImg'
      >
        {UserDetail().userInitials}
      </Avatar>
    </>
  );
};
