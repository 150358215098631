import apiAction from 'common/redux/actions/apiAction';
import { POST_MY_ORG_TRAININGS_URL,POST_MY_ORG_COMPLIANCE_TABULAR_DATA_URL } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

/****
 * Filter My Org Trainigs Status Data 
 ****/
export const FilterMyOrgTrainingsData = configParams => {
    return apiAction.postAPI({
        url: POST_MY_ORG_TRAININGS_URL(),
        data: configParams,
        onSuccess: getMyOrgTrainingsFilteredData,
        onFailure: err => logError('Error occured in FilterMyOrgTrainingsData:', err),
        label: 'FILTER_MY_ORG_TRAININGS_DATA',
    });
};

const getMyOrgTrainingsFilteredData = data => {
    return {
        type: 'GET_MY_ORG_TRAINIGS_FILTERED_DATA',
        payload: data,
    };
};

export const SearchMyOrgTrainingsData = configParams => {
    return apiAction.postAPI({
        url: POST_MY_ORG_COMPLIANCE_TABULAR_DATA_URL(),
        data: configParams,
        onSuccess: getMyOrgTrainingsSearchData,
        onFailure: err => logError('Error occured in SearchMyOrgTrainingsData:', err),
        label: 'SEARCH_MY_ORG_TRAININGS_DATA',
    });
};

const getMyOrgTrainingsSearchData = data => {
    return {
        type: 'GET_MY_ORG_TRAINIGS_SEARCH_DATA',
        payload: data,
    };
};
