/**
* ThemeSwitcherReducer.
* @param {object} state
* @param {object} delay
* @returns {object}
*/
export const ThemeSwitcherReducer = (state = {}, action) => {
  if(action.type === 'GET_THEME_TYPE'){
    return {
      ...state,
      selectedTheme: action.payload,
    };
  }
  else{
    return state;
  }
};
