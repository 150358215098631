import React,{useEffect,useState} from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GetThemeSwitcherData } from '../redux/actions';
import { messages } from 'common/global';

export const ThemeSwitcherComponent = props => {
  const themeList = messages.themes;

  const expandedProps = {
    ...props,
    useDark: props.useDark || false,
    lightPrompt: props.darkPrompt || 'Use Blue theme mode',
    darkPrompt: props.darkPrompt || 'Use Red theme mode',
    tooltipText: props.tooltipText || 'Use theme mode: ',
    themeType: props.themeType || 'BlueTheme',
  };
  const [initializer, setInitializer] = useState(false);
  const dispatch = useDispatch();
  let setThemeInState = useSelector(state =>  state.themeSwitcherRd);

  useEffect(() => {
    if (!initializer) {
      document.body.className = '';
      document.body.classList.add(messages.themes[0]);
      dispatch(GetThemeSwitcherData({ ...expandedProps, themeType: 0 }));
    }
    setInitializer(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initializer]);

  const handleChangeTheme = event => {
    document.body.className = '';
    dispatch(GetThemeSwitcherData({ ...expandedProps, themeType: event.target.value }));
    document.body.classList.add(messages.themes[event.target.value]);
  };

  return (
    <>
      {setThemeInState.selectedTheme && Object.keys(setThemeInState.selectedTheme).length ? (
        <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }} size='small'>
          <InputLabel id='demo-select-small'>Theme</InputLabel>
          <Select
            labelId='demo-select-small'
            id='demo-select-small'
            value={setThemeInState.selectedTheme.themeType}
            label='Theme'
            onChange={handleChangeTheme}
            inputProps={{ MenuProps: { disableScrollLock: true } }}
          >
            {themeList.length
              ? themeList.map((item, index) => {
                // Here goes your theme option
                return (
                  <MenuItem key={index} value={index}>
                    {item}
                  </MenuItem>
                );
              })
              : null}
          </Select>
        </FormControl>
      ) : (
        ''
      )}
    </>
  );
};