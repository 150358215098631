import {
  BarChart,
  ButtonsTab,
  CircleWithText,
  DataTable,
  PieChart,
  StaticResponse,
} from 'common/components';
import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import MenuIcon from '@mui/icons-material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { NUMBER_ARRAY } from 'common/global/constants';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(NUMBER_ARRAY.three),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

export const StyleGuide = () => {
  const [setLabel] = useState('');
  const sharedComponentList = [
    { name: 'Pie Chart', link: '#pieChart' },
    { name: 'Bar Chart', link: '#barChart' },
    { name: 'Data Grid Pagination', link: '#gridP' },
    { name: 'Data Grid Progresive Scroll', link: '#gridS' },
  ];
  const completedOnTimePercentage = StaticResponse?.pieChart[0]['Completed_On_Time'];
  const overallCompletedPercentage = StaticResponse?.pieChart[1]['Completed_OverAll'];
  const complianceByQuarterData = StaticResponse?.barChart;
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position='fixed' open={open}>
          <Toolbar>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              onClick={handleDrawerOpen}
              edge='start'
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant='h6' noWrap component='div'>
              Shared Components
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant='persistent'
          anchor='left'
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {sharedComponentList.map(item => (
              <ListItem key={item.name} disablePadding>
                <ListItemButton>
                  <Link href={item.link} underline='none'>
                    {item.name}
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
          <List>
            {['Comp1', 'Comp2', 'Comp3'].map((text, index) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemIcon>{index % NUMBER_ARRAY.two === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Box
            className='shared-component-main'
            sx={{
              flexGrow: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}
          >
            <Box
              id='pieChart'
              className='pie-chart gradient-border'
              sx={{
                display: 'flex',
                flexDirection: 'row',
                borderRadius: 5,
                border: '1px solid #ccc',
                padding: '16px',
                marginBottom: '32px',
                minHeight: '300px',
              }}
            >
              <Typography gutterBottom variant='h6' component='h6'>
                # Pie Chart - (D3.js)
              </Typography>
              <PieChart
                id={'completionOnTimePieChart'}
                boxSize={500}
                data={completedOnTimePercentage}
                colors={['#CA001B', '#FBEAEA']}
              />
              <Stack sx={{ fontWeight: '900', marginTop: '125px', marginLeft: '-75px' }}>
                <Box sx={{ fontSize: '14px', lineHeight: '16px', width: '88px' }}>
                  COMPLETED ON TIME
                </Box>
                <Box sx={{ fontSize: '25px', lineHeight: '28px' }}>6000 of 12000</Box>
              </Stack>
              <PieChart
                id={'overallCompletedPercentage'}
                boxSize={1200}
                data={overallCompletedPercentage}
                colors={['#000099', '#DBDBF4']}
              />
              <Stack sx={{ fontWeight: '900', marginTop: '125px', marginLeft: '-75px' }}>
                <Box sx={{ fontSize: '14px', lineHeight: '16px', width: '88px' }}>
                  COMPLETED OVERALL
                </Box>
                <Box sx={{ fontSize: '25px', lineHeight: '28px' }}>6000 of 12000</Box>
              </Stack>
            </Box>
            <Box
              id='circleWithText'
              className='circleWithText gradient-border'
              sx={{
                flexGrow: 0,
                display: 'flex',
                justifyContent: 'flex-start',
                borderRadius: '5px',
                mb: 3,
              }}
            >
              <Typography gutterBottom variant='h6' component='h6'>
                # Circle With Text
              </Typography>
              <CircleWithText id='cwt1' data={600} bgColor='#CA001B' fgColor='white' fontWeight='600'
                fontSize='18'
                lineHeight='32'
                marginBottom='0'
                fontFamily='"J&J CircularTT Medium Web"' />
              <CircleWithText id='cwt2' data={'90%'} size='86' bgColor='#6EBD44' fgColor='white' fontWeight='700'
                fontSize='20'
                lineHeight='32'
                marginBottom='0'
                fontFamily='"J&J CircularTT Medium Web"' />
              <CircleWithText id='cwt3' data={'65%'} size='96' bgColor='#F5AC25' fontWeight='900'
                fontSize='24'
                lineHeight='32'
                marginBottom='0'
                fontFamily='"J&J CircularTT Medium Web"' />
            </Box>
            <Box
              id='buttonsTab'
              className='buttonsTab gradient-border'
              sx={{
                flexGrow: 0,
                display: 'flex',
                justifyContent: 'flex-start',
                borderRadius: '5px',
                mb: 3,
              }}
            >
              <Typography gutterBottom variant='h6' component='h6'>
                # Buttons Tab
              </Typography>
              <ButtonsTab />
            </Box>
            <Box
              id='barChart'
              className='bar-chart gradient-border'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: 5,
                border: '1px solid #ccc',
                padding: '16px',
                marginBottom: '32px',
                minHeight: '300px',
              }}
            >
              <Typography gutterBottom variant='h6' component='h6'>
                #Bar Chart
              </Typography>
              <BarChart
                id={'complianceByQuarterBarChart'}
                setValue={setLabel}
                width={330}
                height={165}
                data={complianceByQuarterData}
              />
            </Box>

            <Box
              id='gridP'
              className='grid-chart gradient-border'
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 0,
                mb: 3,
                minHeight: '300px',
                borderRadius: '5px',
                boxShadow: '0 1px 6px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Typography
                variant='h6'
                component='h6'
                sx={{
                  padding: '16px',
                }}
              >
                #Grid
              </Typography>
              <DataTable />
            </Box>
          </Box>

        </Main>
      </Box>
    </>
  );
};
