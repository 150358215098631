import { MediaContext } from 'common/context/MediaContext';
import { useContext } from 'react';

export const useScreen = () => {
  const context = useContext(MediaContext);

  if (!context) throw new Error('context invoked ouside provider');

  return context;
};
