import { useEffect, useRef, useState } from "react";
import {AUTHENTICATION_TIMEOUT} from 'common/global/constants'

/**
* UseDebounce.
* @param {number} value
* @param {number} delay
* @returns {JSX.Element}
*/

export const UseDebounce = (value, delay = AUTHENTICATION_TIMEOUT) => {
  const [debouncedValue, setDebouncedValue] = useState("");
  const timerRef = useRef();

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};