import React from 'react';
import DropDownSVG from 'TAP/assets/images/downArrow.svg';

export const DropDownIcon = props => (
  <img
    src={DropDownSVG}
    style={{
      width: '10px',
      height: '10px',
      position: 'absolute',
      top: '50%',
      right: '8px',
      transform: 'translateY(-50%)',
    }}
    {...props}
  />
);
