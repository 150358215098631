import React from 'react';
import { Button, Typography, Box } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import {  RedTheme } from 'common/global';

let redtheme =RedTheme;
export const DataRefresh = () => {

    const handleNavigate = function () {
        window.location.href = process.env.REACT_APP_REDIRECT_URI;
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                backgroundColor: redtheme.palette.box.very_light_grey
            }}
        >
            <Typography variant='h1' className='refresh' sx={{ fontSize: '41px', fontWeight: 900, fontFamily: '"J&J CircularTT Medium Web"', mb: 3, color: redtheme.palette.fontColor.black_gray }}>
                Data Refresh<span>.</span><span>.</span><span>.</span><span>.</span>
            </Typography>
            <Typography sx={{ fontSize: '26px', fontWeight: 900, fontFamily: '"J&J CircularTT Medium Web"', mb: 3, color: redtheme.palette.fontColor.dark_grayish_blue }}>
                TAP is currently unavailable due to a data refresh. Please check back later.
            </Typography>
            <Button
                onClick={handleNavigate}
                sx={{
                    color: redtheme.palette.button.black,
                    bgcolor: redtheme.palette.button.white, // set background color
                    fontSize: '20px', // increase font size
                    lineHeight: '24px', // increase line height
                    textTransform: 'capitalize',
                    fontWeight: 800,
                    fontFamily: '"J&J CircularTT Bold Web"',
                    '&:hover': { backgroundColor: redtheme.palette.button.white, boxShadow: '' },
                    mt: 3, // add margin-top
                    px: 4, // increase horizontal padding
                    py: 2, // increase vertical padding
                }}
                color='primary'
                variant='contained'
            >
                <HomeIcon sx={{ marginTop: '-2px', marginRight: '5px' }}></HomeIcon> BRQC Control Tower
            </Button>
        </Box>
    )
}
