import apiAction from 'common/redux/actions/apiAction';
import {
  POST_TRAINING_STATUS_URL,
  POST_MYPERFORMANCE_SEARCH_TRAINING_BYTITLE_URL
} from './apiPathConstants';
import{logError} from 'TAP/utils/commonMethods';

/****
 * Training status post method
 ****/
const getTrainingStatusData = data => {
  return {
    type: 'GET_TRAINING_STATUS_DATA',
    payload: data,
  };
};

export const TrainingStatusData = configParams => {
  return apiAction.postAPI({
    url: POST_TRAINING_STATUS_URL(),
    data: configParams,
    onSuccess: getTrainingStatusData,
    onFailure: err => logError('Error occured in fetching dashboard data.', err),
    label: 'FETCH_TRAININGSTATUS_DATA',
  });
};

/****
 * Search My Performance Trainings table by Training Title - post method
 ****/
export const SearchTrainingsDataByTitle = configParams => {
  return apiAction.postAPI({
    url: POST_MYPERFORMANCE_SEARCH_TRAINING_BYTITLE_URL(),
    data: configParams,
    onSuccess: searchTrainingsTitle,
    onFailure: err => logError('Error occured in SearchTrainingsDataByTitle API...', err),
    label: 'FETCH_TRAININGTITLES_DATA_SEARCH',
  });
};

const searchTrainingsTitle = data => {
  return {
    type: 'GET_TRAININGTITLES_DATA_SEARCH',
    payload: data,
  };
};
