import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { RedTheme } from 'common/global';
import CloseIcon from '@mui/icons-material/Close';

export const Disclaimer = ({
  handleclose,
  spacing,
  innerWidth,
  contentHeight,
  contentPadding,
  disclaimerText,
}) => {
  const redtheme = RedTheme;
  return (
    <Box>
      <Stack direction='row' spacing={spacing}>
        <Box sx={{ paddingLeft: '16px', paddingTop: '14px' }}>
          <WarningAmberRoundedIcon
            sx={{
              width: '24px',
              height: '24px',
              color: redtheme.palette.warning.main,
            }}
          />
        </Box>
        <Box
          sx={{
            width: { innerWidth },
            height: contentHeight || '36px',
            padding: contentPadding || '7px 0 0 0',
          }}
        >
          <Typography
            sx={{
              fontSize: '10px',
              fontWeight: 500,
              ontFamily: '"JohnsonText-Bold"',
              lineHeight: '12px',
            }}
          >
            {disclaimerText}
          </Typography>
        </Box>
        <Box>
          <IconButton sx={{ marginRight: '5px' }} onClick={handleclose}>
            <CloseIcon
              sx={{
                color: redtheme.palette.tooltip.black,
                width: '16px',
                height: '16px',
                fontWeight: 'bold',
              }}
            />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  );
};