import apiAction from 'common/redux/actions/apiAction';
import { POST_TRAINING_VIEW_INDIVIDUAL_USER_URL } from './apiPathConstants';
import{logError} from 'TAP/utils/commonMethods';

/****
 * Get Training View Plan - Individual User Curriculum Data 
 ****/
const getTrainingViewIndividualUserDetails = data => {
    return {
        type: 'POST_TRAINING_VIEW_INDIVIDUAL_USER_DETAILS',
        payload: data,
    };
};

export const TrainingViewIndividualUserData = configParams => {
    return apiAction.postAPI({
        url: POST_TRAINING_VIEW_INDIVIDUAL_USER_URL(),
        data: configParams,
        onSuccess: getTrainingViewIndividualUserDetails,
        onFailure: err => logError('Error occurred in fetching Training View Individual User data.', err),
        label: 'FETCH_TRAINING_VIEW_INDIVIDUAL_USER_DATA',
    });
};

/****
 * Get Suggestions for CURRICULUM search
 ****/
const getTrainingViewIndividualUserCurriculumSuggestions = data => {
    return {
        type: 'POST_TRAINING_VIEW_INDIVIDUAL_USER_CURRICULUM_SEARCH_SUGGESTIONS',
        payload: data,
    };
};

export const TrainingViewIndividualUserCurriculumSuggestionsData = configParams => {
    return apiAction.postAPI({
        url: POST_TRAINING_VIEW_INDIVIDUAL_USER_URL(),
        data: configParams,
        onSuccess: getTrainingViewIndividualUserCurriculumSuggestions,
        onFailure: err => logError('Error occurred in fetching Training View Individual User CURRICULUM Search suggestions.', err),
        label: 'FETCH_TRAINING_VIEW_INDIVIDUAL_USER_CURRICULUM_SEARCH_SUGGESTIONS_DATA',
    });
};

/****
 * Get Suggestions for TRAINING search
 ****/
const getTrainingViewIndividualUserTrainingSuggestions = data => {
    return {
        type: 'POST_TRAINING_VIEW_INDIVIDUAL_USER_TRAINING_SEARCH_SUGGESTIONS',
        payload: data,
    };
};

export const TrainingViewIndividualUserTrainingSuggestionsData = configParams => {
    return apiAction.postAPI({
        url: POST_TRAINING_VIEW_INDIVIDUAL_USER_URL(),
        data: configParams,
        onSuccess: getTrainingViewIndividualUserTrainingSuggestions,
        onFailure: err => logError('Error occured in fetching Training View Individual User TRAINING Search suggestions.', err),
        label: 'FETCH_TRAINING_VIEW_INDIVIDUAL_USER_TRAINING_SEARCH_SUGGESTIONS_DATA',
    });
};