import React, { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';
import { select, selectAll, arc, pie } from 'd3';
import Box from '@mui/material/Box';
import { TapGlobalMessages } from "TAP/global"
import {  RedTheme } from 'common/global';
import { donutchart } from 'common/global/constants';
import { percent } from 'TAP/styles/genericStyles/percentage';
import { height } from 'TAP/styles/genericStyles/height';

/**
* redtheme.
* redtheme configuration defined in global.
*/
let redtheme =RedTheme;

/**
* donutColors.
* Default donut chart color configuration.
*/
const donutColors = {
  Red: [redtheme.palette.donutChart.red,  redtheme.palette.donutChart.light_red],
  Yellow: [redtheme.palette.donutChart.yellow,  redtheme.palette.donutChart.light_orange],
  Green: [ redtheme.palette.donutChart.green,  redtheme.palette.donutChart.light_green],
};

/**
* getColor.
*/
function getColor(percentage) {
  if (percentage >= percent.pr95) {
    return donutColors.Green;
  } else if (percentage >= percent.pr85) {
    return donutColors.Yellow;
  } else {
    return donutColors.Red;
  }
}

/**
* drawChartDivision.
*/
const drawChartDivision = (timeRange,textUnderDonut) => {
  if (timeRange === 'Month') {
    textUnderDonut = '*Current month plus the past 11 months';
  } else if (timeRange === 'Year') {
    textUnderDonut = '*Current year plus the past 3 years';
  } else if (timeRange === 'Quarter') {
    textUnderDonut = '*Current quarter plus the past 4 quarters';
  } else{
    textUnderDonut = '';
  }
  return textUnderDonut
}

/**
* drawChart.
*/
function drawChart(chartData) {
  selectAll('#' + chartData.id + ' svg').remove();
  let svg = select('#' + chartData.id);
  let textUnderDonut = (chartData.isTaglineVisible) ? (chartData.tagLine) : ' ';
  
  drawChartDivision(chartData.timeRange,chartData.textUnderDonut)
  svg = svg
    .append('svg')
    .attr('preserveAspectRatio', 'xMidYMid meet')
    .attr('height', '100%')
    .attr('width', '100%')
    .attr('viewBox', `0 0 ${chartData.boxSize} ${chartData.boxSize}`)
    .append('g')
    .attr('transform', `translate(${chartData.boxSize / donutchart.two}, ${chartData.boxSize / donutchart.two})`);

  svg
    .append('g')
    .append('text')
    .attr('text-anchor', 'middle')
    .text(textUnderDonut)
    .style('font-family', '"J&J CircularTT Medium Web"')
    .style('font-weight', '450')
    .style('font-size', '38px')
    .style('font-weight', '450')
    .style('line-height', '16px')
    .attr('transform', `translate(0, ${chartData.boxSize / donutchart.twopointone})`);


    // Creating a pie chart with radius 250
  const arcGenerator = arc().innerRadius(donutchart.twoTwenty).outerRadius(donutchart.twofifty);
  // Define which field name will be used to render the chart data
  const pieGenerator = pie()
    .endAngle(donutchart.minustwo * Math.PI)
    .value(d => d.value);

  // Render pie chart
  const arcs = svg.selectAll().data(pieGenerator(chartData.transformData)).enter();
  arcs
    .append("path")
    .attr("d", arcGenerator)
    .style("fill", (_d, i) =>
    chartData.color.length ? chartData.color[i % chartData.transformData.length] : getColor(chartData.transformData[0].value)[i % chartData.transformData.length]
    )
    .append("title")
    .text((d, i) => {
      if (i === 0) {
        return `${d.data.value}% Completed`;
      } else {
        return `${d.data.value}% Incomplete`;
      }
    });

  const tooltip = select("body")
    .append("div")
    .attr("class", "tooltip")
    .style("position", "absolute")
    .style("font-size", "14px")
    .style("background", redtheme.palette.tooltip.white)
    .style("border", `3px solid ${redtheme.palette.button.white}`)
    .style("box-shadow", "0px 4px 24px 0px rgba(0, 0, 0, 0.24)")
    .style("border-radius", "11px")
    .style("pointer-events", "none")
    .style("opacity", 0);

  let tooltipTitle;

  if (chartData.trainingState === "Trainings Completed On Time") {
    tooltipTitle =
      "Completed on Time = total trainings completed on or before the due date / total trainings due for the time period selected.";
  } else if (chartData.trainingState === "Trainings Completed Overall") {
    tooltipTitle =
      "Completed Overall = total trainings completed (regardless of due date) / total trainings completed for current year plus past three (3) years.";
  }
  else {
    tooltipTitle = ' ';
  }

  if (chartData.timeRange === "Month") {
    tooltipTitle +=
      "total trainings completed for the current month plus the past 11 months";
  } else if (chartData.timeRange === "Quarter") {
    tooltipTitle +=
      "total trainings completed for the current quarter plus the past 4 quarters";
  } else if (chartData.timeRange === "Year") {
    tooltipTitle +=
      "total trainings completed for the current year plus the past 3 years";
  }
  else {
    tooltipTitle += ' '; 
  }
  /**
  * tooltipContent.
  */
  const tooltipContent = ReactDOMServer.renderToString(
    <div
      style={{
        width: '320px',
        padding: '20px',
        gap: '8px',
        color: redtheme.palette.tooltip.black_gray,
      }}
    >
      <div
        style={{
          fontFamily: '"JohnsonDisplay-Bold"',
          color: redtheme.palette.tooltip.black,
          fontWeight: '700',
          fontSize: '18px',
          marginBottom: '8px',
        }}
      >
        {TapGlobalMessages.myTeamsPerformance.percentageCalculationHeader}
      </div>
      <div>{tooltipTitle}</div>
    </div>
  );

  arcs
    .append("foreignObject")
    .attr("x", donutchart.minusonethirty)
    .attr("y", donutchart.minussixty)
    .attr("width", donutchart.twoeighty)
    .attr("height", donutchart.onethirty)
    .append("xhtml:div")
    .style("display", "flex")
    .style("justify-content", "center")
    .style("align-items", "center")
    .style("height", "100%")
    .style("cursor", "pointer")
    .html(`${chartData.transformData[0].value}%`)
    .on("mouseover", event => {
      tooltip.transition().duration(donutchart.twoHundred).style("opacity", 1.0);
      tooltip
        .html(tooltipContent)
        .style(
          "left",
          event.pageX - tooltip.node().getBoundingClientRect().width / donutchart.two + "px"
        )
        .style(
          "top",
          event.pageY -
          tooltip.node().getBoundingClientRect().height -
          height.h28 +
          "px"
        );
    })
    .on("mouseout", () => {
      tooltip.transition().duration(donutchart.fiveHundred).style("opacity", 0);
    })
    .style('fill', 'black')
    .style('font-family', '"JohnsonDisplay-Bold"')
    .style('font-size', '100px')
    .style('font-weight', '900')
}

/**
* DonutChart.
* DonutChat is use for create donut chart UI with the help of given parameter
* This component using Box component for layout.
* @param {number} id
* @param {object} data
* @param {number} boxSize
* @param {number} boxWidth
* @param {string} timeRange
* @param {string} trainingState
* @param {string} tagLine
* @param {boolean} isTaglineVisible
* @param {string} color
* @returns {JSX.Element}
*/

export const DonutChart = ({ id, data, boxSize, boxWidth, timeRange, color, trainingState, tagLine, isTaglineVisible }) => {
  useEffect(() => {
    if (data !== undefined && data >= 0) {
      let transformData = [];
      let dataObj1 = {},
        dataObj2 = {};
      dataObj1.value = data.toFixed();
      transformData.push(dataObj1);
      if (data < donutchart.hundred) {
        dataObj2.value = donutchart.hundred - dataObj1.value;
        transformData.push(dataObj2);
      }
      /**
      * drawChart.
      */
      const chartData = { id, transformData, boxSize, timeRange, color, trainingState, tagLine, isTaglineVisible }
      drawChart(chartData);
    }
  }, [id, data, boxSize,timeRange]);
  return <Box id={id} className="chart donutChart" sx={{ width: boxWidth }}></Box>;
};