export const fontWeightStyles = {
    normal: { fontWeight: 'normal' },
    b: { fontWeight: 'bold' },
    fw1: { fontWeight: '100px' },
    fw2: { fontWeight: '200px' },
    fw3: { fontWeight: '300px' },
    fw4: { fontWeight: '400px' },
    fw5: { fontWeight: '500px' },
    fw6: { fontWeight: '600px' },
    fw7: { fontWeight: '700px' },
    fw8: { fontWeight: '800px' },
    fw9: { fontWeight: '900px' },
    fw45: { fontWeight: '450px' }
  };
  