import React, { useEffect } from 'react';
import { select, scaleBand, scaleLinear, axisBottom, axisLeft } from 'd3';
import { margin } from 'TAP/styles/genericStyles/margin';
import { width } from 'TAP/styles/genericStyles/width';
import { percent } from 'TAP/styles/genericStyles/percentage';
import { NUMBER_ARRAY } from 'common/global/constants';
import { useScreen } from 'common/hooks/useScreen';
export const BarChartComponent = props => {
  // This function is being used to wrap labels along X-Axis
  const screen = useScreen();

  const wrap = (text, widthValue) => {
    text.each(function () {
      let text = select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1,
        y = text.attr('y'),
        dy = parseFloat(text.attr('dy')),
        tspan = text
          .text(null)
          .append('tspan')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', dy + 'em');
      while (words.length > 0) {
        word = words.pop();
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > widthValue) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text
            .append('tspan')
            .attr('x', 0)
            .attr('y', y)
            .attr('dy', ++lineNumber * lineHeight + dy + 'em')
            .text(word);
        }
      }
    });
  };
  const createBarChart = wrapChart => {
    //Selects container in which bar chart is and defines dimensions for bar chart
    const container = document.getElementById('chart-container');
    // Get the width of the container
    const containerWidth = parseInt(select('#chart-container')?.style('width'), width.wid10); 
    // Set a fixed height
    const containerHeight = props.height; 
    const svgMargin = { top: margin.m20, right: margin.m10, bottom: margin.m50, left: margin.m35 };
    const svgWidth = containerWidth - margin.m60;
    const height = containerHeight - svgMargin.top - svgMargin.bottom;

    select(container).select('svg').remove();

    const svg = select(container)
      .append('svg')
      // Set the initial width based on the container
      .attr('class', 'ComplianceBarChartSvg')
      .attr('width',  screen.isTablet ?  '330' : containerWidth ) 
      .attr('height', containerHeight)
      .append('g')
      .attr('transform', `translate(${svgMargin.left},${svgMargin.top})`);

    // Defines the X-Scale
    // Increase or Decrease the padding to control thickness of bars
   
    const xScale = scaleBand()
    .range([0,  screen.isTablet ? '233' : svgWidth])
    .padding(props.padding).paddingOuter(0)
    .domain(props.data.map(d => d.label));
   

    // Defines Y- Scale with axis being zero and max being 100
    const yScale = scaleLinear().range([height, 0]).domain([0, percent.pr100]);

    svg.append('g').attr('transform', `translate(0,${height})`).call(axisBottom(xScale));
    svg.append('g').call(
      axisLeft(yScale)
        .tickValues(props.yAxisTickValues)
        .tickFormat(d => `${d}% `),
    );

    // To make small ticks blend with the background
    svg.selectAll('.tick line').attr('stroke', 'white');

    // Renders the Grid visible in the background
    // tickValues array decides what values will be shown on y-axis

    svg
      .append('g')
      .call(axisLeft(yScale).tickValues(props.yAxisTickValues).tickSize(-svgWidth).tickFormat(''))
      .style('opacity', NUMBER_ARRAY.zeroPointTwo)
      .attr('class', 'grid');

    // Blends the X-axis and Y-axis lines with Background
    svg.selectAll('.domain').attr('stroke', 'white');

    // Aligns Wraps the x-axis labels using wrap function
    svg
      .selectAll('.tick text')
      .attr('text-anchor', 'center')
      .call(wrapChart, xScale.bandwidth() + width.wid40)
      .style('font-family', "'JohnsonText-Regular'")
      .style('font-size', screen.isTablet ? '10px !important' : '12px');

    // Designs the bars
    const bars = svg
      .selectAll('.bar-group')
      .data(props.data)
      .enter()
      .append('g')
      .attr('class', 'bar-group')
      .attr('transform', d => `translate(${xScale(d.label)},0)`);
    //  Tooltip component is added and kept invisible and is made visible only on hover
    const tooltip = select(container)
      .append('div')
      .attr('class', 'tooltip')
      .style('visibility', 'hidden');
    // Responsible for rendering bottom part of each bar
    bars
      .append('rect')
      .attr('class', 'compliance-bar')
      .attr('data-testid', d => `${d.label} compliance-bar`)
      .attr('y', d => yScale(d.compliantPercent))
      .attr('height', d => height - yScale(d.compliantPercent))
      .attr('width', xScale.bandwidth())
      .style('fill', props.bottomBarColor)
      .on('mouseover', (event, d) => {
        tooltip.transition().duration(NUMBER_ARRAY.hundred).style('visibility', 'visible');
        tooltip
          .html(`<strong>${d.label}</strong><br>Compliance: ${d.compliantPercent}%`)
          .style('left', event.pageX - tooltip.node().getBoundingClientRect().width / width.wid2 + 'px')
          .style('top', event.pageY - tooltip.node().getBoundingClientRect().height - width.wid28 + 'px')
          .style('position', 'absolute')
          .style('background-color', 'black')
          .style('color', 'white')
          .style('padding', '5px')
          .style('border-radius', '5px')
          .style('box-shadow', '0px 4px 20px rgba(0, 0, 0, 0.2)');
      })
      .on('mouseout', () => {
        tooltip.transition().duration(NUMBER_ARRAY.fiveHundred).style('visibility', 'hidden');
      });
    // Responsible for rendering middle part of each bar
    bars
      .append('rect')
      .attr('class', 'at-risk')
      .attr('data-testid', d => `${d.label} at-risk`)
      .attr('y', d => yScale(d.compliantPercent + d.atRiskPercent))
      .attr('height', d => height - yScale(d.atRiskPercent))
      .attr('width', xScale.bandwidth())
      .style('fill', props.middleBarColor)
      .on('mouseover', (event, d) => {
        tooltip.transition().duration(NUMBER_ARRAY.twoHundred).style('visibility', 'visible');
        tooltip
          .html(`<strong>${d.label}</strong><br>At Risk: ${d.atRiskPercent}%`)
          .style('left', event.pageX - tooltip.node().getBoundingClientRect().width / width.wid2 + 'px')
          .style('top', event.pageY - tooltip.node().getBoundingClientRect().height - width.wid28 + 'px')
          .style('position', 'absolute')
          .style('background-color', 'black')
          .style('color', 'white')
          .style('padding', '10px')
          .style('border-radius', '11px')
          .style('box-shadow', '0px 4px 24px 0px rgba(0, 0, 0, 0.24)');
      })
      .on('mouseout', () => {
        tooltip.transition().duration(NUMBER_ARRAY.fiveHundred).style('visibility', 'hidden');
      });

    // Responsible for rendering top part of each bar
    bars
      .append('rect')
      .attr('class', 'non-compliance-bar')
      .attr('data-testid', d => `${d.label} non-compliance`)
      .attr('y', d => yScale(d.compliantPercent + d.nonCompliantPercent + d.atRiskPercent))
      .attr('height', d => height - yScale(d.nonCompliantPercent))
      .attr('width', xScale.bandwidth())
      .style('fill', props.topBarColor)
      .on('mouseover', (event, d) => {
        tooltip.transition().duration(NUMBER_ARRAY.twoHundred).style('visibility', 'visible');
        tooltip
          .html(`<strong>${d.label}</strong><br>Non-Compliance: ${d.nonCompliantPercent}%`)
          .style('left', event.pageX - tooltip.node().getBoundingClientRect().width / width.wid2 + 'px')
          .style('top', event.pageY - tooltip.node().getBoundingClientRect().height - width.wid28 + 'px')
          .style('position', 'absolute')
          .style('background-color', 'black')
          .style('color', 'white')
          .style('padding', '5px')
          .style('border-radius', '5px')
          .style('box-shadow', '0px 4px 20px rgba(0, 0, 0, 0.2)');
      })
      .on('mouseout', () => {
        tooltip.transition().duration(NUMBER_ARRAY.fiveHundred).style('visibility', 'hidden');
      });
  };

  useEffect(() => {
    createBarChart(wrap);
  }, [props]);

/**
* Dynamically update SVG width on window resize
* Update the SVG width
*/
  window.addEventListener('resize', () => {
    createBarChart(wrap); 
  });
  return <div id='chart-container'></div>;
};
