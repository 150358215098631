/**
* MyOrgTrainingCompletionReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const MyDirectTrainingCompletionReducer = (state = {}, action) => {
    if(action.type === 'GET_MY_DIRECT_REPORT_COMPLETION_DATA'){
      return {
        ...state,
        completionDetails: action.payload,
      };
    }
    else{
      return state;
    }
  };