import { SearchMyComplianceStatusData } from 'TAP/redux/actions';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import EastSharpIcon from '@mui/icons-material/EastSharp';
import { Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import { LoaDialog } from 'TAP/components/loaDialog';
import { RedTheme } from 'common/global';
import { ShowLoader } from 'TAP/global/showLoader';
import Stack from '@mui/material/Stack';
import { TapGlobalMessages } from 'TAP/global';
import { TrainingTable } from 'TAP/components';
import Typography from '@mui/material/Typography';
import { formatTime, getGAMeasurementId } from 'TAP/utils/commonMethods';
import { MyComplianceStatus } from 'TAP/components/MyComplianceStatus';
import { Disclaimer } from 'TAP/components/Disclaimer';
import ReactGA from "react-ga4";
import { useScreen } from 'common/hooks/useScreen';
import { NUMBER_ARRAY } from 'common/global/constants';

export const MyPerformance = () => {

    /* Below data are kept as state 
    userId -> Id of logged in user,
    lastRefreshDate -> Last page refreshed date  ,
    completedOnTimePercentage , 
    completedOverAllPercentage ,
    userOnLeave -> Responsible for showing LOA dialog,
    openModal(for Summit Dialog)
  */


  const [userId, setUserId] = useState('');
  const [lastRefreshDate, setLastRefreshDate] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [userOnLeave, setUserOnLeave] = useState(false);
  const [loggedInUserId, setLoggedInUserId] = useState('');
  const [isDisclaimerToShow, setIsDisclaimerToShow] = useState(true);
  const screen = useScreen();

  // Open modal on click of Summit button
  const handleModalClickOpen = () => {
    setOpenModal(true);
  };

  // Close modal on click of Cross Icon or No button on Summit redirect Popup
  const handleModalClose = () => {
    setOpenModal(false);
  };

  // Redirects to Summit page on click of yes button in Summit popup
  const handleOpenSummitLink = () => {
    window.open('https://jnj.csod.com/ui/lms-learner-home/home/', '_blank');
    setOpenModal(false);
  };

  const dispatch = useDispatch();
  // All color will be used from this which is described in theme.js
  let redtheme = RedTheme;

  // Fetch user data and dashboard data from redux store
  let getLoggedInUserDetails = useSelector(state => state.tapUserDetailsRd);

  useEffect(() => {
    if (getLoggedInUserDetails && Object.keys(getLoggedInUserDetails).length) {
      setUserId(getLoggedInUserDetails?.userDetails?.data[0].user_id);
      setLoggedInUserId(getLoggedInUserDetails?.userDetails?.data[0].user_id);

      //Checks if user is on Leave and sets leaveIndicator
      let leaveIndicator = getLoggedInUserDetails?.userDetails?.data[0].leave_of_absence;
      leaveIndicator.trim() === 'N' ? setUserOnLeave(false) : setUserOnLeave(true);

      // Uses function formatTime to format the refresh time stamp received from API
      const formattedLastRefreshTime = formatTime(
        getLoggedInUserDetails?.userDetails?.data[0].last_refresh_date,
      );
      setLastRefreshDate(formattedLastRefreshTime);
    }
  }, [getLoggedInUserDetails]);

  useEffect(()=>{
    ReactGA.initialize(`G-${getGAMeasurementId()}`);
    ReactGA.send({ hitType: "pageview", page: "/my-path", title: "My Performance" });
   }, []);

  // Dispatches Action to fetch myComplainceStatusData with userId & loggedInUserId as Payload
  useEffect(() => {
    if (userId !== undefined && userId !== '') {
      const payloadUser = {
        userId: userId,
        loggedInUserId: loggedInUserId,
      };
      dispatch(SearchMyComplianceStatusData(payloadUser));
    }
  }, [userId, loggedInUserId]);

  /* 
    sets completedOnTime and Completed overall state,
     only if it is not undefined or 0,
     Else it sets it to empty ('')
  */

  // opens a new tab and redirects to askGS if LOA dialog is open and askGs button is clicked
  const handleOpenAskGSLink = () => {
    window.open(TapGlobalMessages.URLS.ASK_GS, '_blank');
  };

  return (
    <>
      {/* Shows Loader while data is getting fetched from API */}
      <ShowLoader></ShowLoader>
      {/* 
        Shows Loa Dialog if user is on Leave using userOnLeave state
        AskGS button is present 
      */}
      <LoaDialog
        isOpen={userOnLeave}
        isRightButtonVisible={true}
        rightButtonText={`AskGS`}
        dialogTitle={`Sorry!`}
        dialogBodyMessage1={TapGlobalMessages.LOA.LOA_NO_DATA}
        dialogBodyMessage2={TapGlobalMessages.LOA.ASK_GS_HELPER}
        onRightButtonClick={handleOpenAskGSLink}
      />
      <Box
        className='my-performance-page'
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        {/* This Box holds My Performance header and Last refresh Date */}
        <Box
          sx={{
            padding: screen.resolveStyles({
              mobile: '16px',
              tablet: '0 24px 24px 24px',
              desktop: '0 72px 0 72px',
            }),
            height: screen.resolveStyles({ mobile: 'auto', tablet: 'auto', desktop: '148px' }),
            width: screen.isMobile ? '100%' : 'auto',
          }}
        >
          {/* This block renders My Performance Heading*/}
          <Grid container>
            <Grid
              item
              xs={screen.resolveStyles({
                mobile: NUMBER_ARRAY.twelve,
                tablet: NUMBER_ARRAY.six,
                desktop: NUMBER_ARRAY.four,
              })}
            >
              <Typography
                data-testid='MyPerformance'
                gutterBottom
                variant='h3'
                component='h3'
                sx={{
                  fontSize: screen.resolveStyles({
                    mobile: '25px',
                    tablet: '31px',
                    desktop: '39px',
                  }),
                  lineHeight: screen.resolveStyles({ mobile: '32px', desktop: '48px' }, 'mobile'),
                  mb: 0,
                  fontWeight: 700,
                  fontFamily: '"JohnsonDisplay-Bold"',
                }}
              >
                My Performance
                {screen.isTablet && (
                  <Typography
                    data-testid='lastRefreshDate'
                    component='p'
                    sx={{
                      fontSize: screen.isMobile ? '10px' : '14px',
                      lineHeight: screen.isMobile && '12px',
                      pb: screen.isMobile ? '12px' : '15px',
                      color: redtheme.palette.fontColor.gray,
                      fontWeight: 400,
                    }}
                  >
                    Last refresh {lastRefreshDate}
                  </Typography>
                )}
              </Typography>
            </Grid>
            <Grid
              item
              xs={screen.resolveStyles({
                mobile: NUMBER_ARRAY.twelve,
                tablet: NUMBER_ARRAY.six,
                desktop: NUMBER_ARRAY.eight,
              })}
              order={screen.isMobile ? NUMBER_ARRAY.three : NUMBER_ARRAY.two}
            >
              {isDisclaimerToShow && (
                <Box
                  sx={{
                    width: screen.resolveStyles({ mobile: '100%', desktop: '478px' }, 'mobile'),
                    height: screen.resolveStyles({ mobile: 'auto', desktop: '48px' }, 'mobile'),
                    borderRadius: '8px',
                    bgcolor: redtheme.palette.warning.light,
                  }}
                >
                  <Disclaimer
                    handleclose={() => setIsDisclaimerToShow(false)}
                    spacing={0.65}
                    innerWidth={screen.resolveStyles(
                      { mobile: '100%', desktop: '390px' },
                      'mobile',
                    )}
                    contentHeight={screen.resolveStyles(
                      { mobile: 'auto', desktop: '36px' },
                      'mobile',
                    )}
                    contentPadding={screen.isTablet && '8px 0'}
                    disclaimerText={TapGlobalMessages.disclaimertext.myperdisclaimertext}
                  />
                </Box>
              )}
            </Grid>
            {!screen.isTablet && (
              <Grid item xs={screen.isMobile ? 12 : 4} order={screen.isMobile ? 2 : 3}>
                {/* This block renders Last refresh date of the page*/}
                <Typography
                  data-testid='lastRefreshDate'
                  gutterBottom
                  variant='div'
                  component='div'
                  sx={{
                    fontSize: screen.isMobile ? '10px' : '14px',
                    lineHeight: screen.isMobile && '12px',
                    pb: screen.isMobile ? '12px' : '15px',
                    color: redtheme.palette.fontColor.gray,
                    fontWeight: 400,
                  }}
                >
                  Last refresh {lastRefreshDate}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
        {/* This Box hold MyComplianceStatus component and renders it*/}
        <Box
          width='100%'
          sx={{
            padding: screen.resolveStyles({
              mobile: '0 16px',
              tablet: '0 24px',
              desktop: NUMBER_ARRAY.zero,
            }),
          }}
        >
          <Box
            sx={{
              height: screen.resolveStyles({ mobile: 'auto', desktop: '110px' }, 'mobile'),
              width: screen.resolveStyles({ mobile: '100%', desktop: '92%' }, 'mobile'),
              margin: screen.resolveStyles(
                { mobile: '0 0 24px 0', desktop: '0px 72px 12px 72px' },
                'mobile',
              ),
              background: RedTheme.palette.box.white,
              borderLeft: `3px solid ${RedTheme.palette.info.dark}`,
              borderRadius: '8px',
              boxShadow: '0px 0px 20px 0px rgba(0, 0, 0, 0.20);',
              display: 'flex',
              justifyContent: 'space-evenly',
              transform: screen.isDesktop && 'translateY(-40px)',
              padding: screen.resolveStyles({
                mobile: '12px 12px 8px 12px',
                desktop: '0 30px 0 15px',
                tablet: '12px 16px',
              }),
            }}
          >
            <MyComplianceStatus />
          </Box>
        </Box>
        {/* This Box holds Both tiles which renders Completed on Time percentage and Completed Overall Percentage*/}
        <Box sx={{ background: redtheme.palette.box.white }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: screen.isMobile ? 'initial' : 'center',
              flexDirection: screen.isMobile ? 'column' : 'row',
              justifyContent: screen.isMobile ? 'flex-start' : 'space-between',
              margin: '0px 0 12px',
              background: redtheme.palette.box.white,
              padding: screen.resolveStyles({
                mobile: '0 16px',
                tablet: '0 24px',
                desktop: '0 72px',
              }),
            }}
          >
            <Box sx={screen.isMobile && { marginBottom: '8px' }}>
              <Typography
                component='h4'
                variant='h4'
                sx={{
                  fontSize: screen.resolveStyles({
                    mobile: '20px',
                    tablet: '25px',
                    desktop: '31px',
                  }),
                  fontWeight: 700,
                  marginRight: '26px',
                  lineHeight: screen.resolveStyles({ mobile: '24px', tablet: '32px' }, 'tablet'),
                  fontFamily: '"JohnsonDisplay-Bold"',
                }}
              >
                My Training Status
              </Typography>
            </Box>
            <Box
              sx={
                (screen.isMobile || screen.isTablet) && {
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }
              }
            >
              {/* This link renders Request to add or Remove training Button*/}
              <Link underline='none' href='mailto:ra-jacnl-lprdadmins@its.jnj.com'>
                <Button
                  data-testid='requestToAddOrRemoveTrainingBtn'
                  sx={{
                    width: screen.resolveStyles({ mobile: 'auto', desktop: '293px' }, 'mobile'),
                    height: screen.resolveStyles({ mobile: 'auto', desktop: '32px' }, 'mobile'),
                    borderRadius: '8px',
                    marginRight: screen.isMobile ? NUMBER_ARRAY.zero : NUMBER_ARRAY.two,
                    color: redtheme.palette.text.primary,
                    border: `1px solid ${redtheme.palette.text.primary}`,
                    bgcolor: redtheme.palette.background.default,
                    textTransform: 'none',
                    fontWeight: 500,
                    fontSize: screen.isMobile ? '14px' : '16px',
                    lineHeight: '16px',
                    padding: screen.resolveStyles({
                      mobile: '4px 8px',
                      tablet: '8px',
                      desktop: 'auto',
                    }),
                    fontFamily: '"JohnsonText-Medium"',
                    '&:hover': {
                      backgroundColor: redtheme.palette.text.primary,
                      color: redtheme.palette.background.default,
                      fontFamily: '"JohnsonText-Medium"',
                    },
                    '&:focus': { outline: 'none' },
                  }}
                >
                  Request to Add or Remove Trainings
                </Button>
              </Link>
              {/* This fragment renders Summit button*/}
              <>
                <Button
                  data-testid='goToSummit'
                  onClick={handleModalClickOpen}
                  endIcon={<EastSharpIcon fontSize={screen.isMobile ? 'inherit' : 'initial'} />}
                  sx={{
                    width: screen.resolveStyles({ mobile: 'auto', desktop: '120px' }, 'mobile'),
                    height: screen.resolveStyles({ mobile: 'auto', desktop: '32px' }, 'mobile'),
                    padding: screen.resolveStyles({
                      mobile: '4px 8px',
                      tablet: '8px 16px',
                      desktop: 'auto',
                    }),
                    borderRadius: '8px',
                    color: redtheme.palette.button.black,
                    border: `1px solid ${redtheme.palette.primary.main}`,
                    bgcolor: 'transparent',
                    fontSize: screen.isMobile || screen.isTablet ? '14px' : '16px',
                    lineHeight: '16px',
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    fontFamily: screen.isMobile ? '"JohnsonText-Regular"' : '"JohnsonText-Medium"',
                    '&:hover': { backgroundColor: 'transparent', boxShadow: '' },
                  }}
                >
                  SUMMIT
                </Button>
                {/* This Dialog opens on click of summit button*/}
                <Dialog
                  data-testid='loaDialog'
                  open={openModal}
                  aria-labelledby='alert-dialog-title'
                  aria-describedby='alert-dialog-description'
                  PaperProps={{
                    sx: {
                      width: screen.isMobile ? '95vw' : '420px',
                      height: screen.isMobile ? '220px':'284px',
                      borderRadius: '16px',
                      overflow: 'hidden', 
                      marginTop: '32px',
                      marginBottom: '32px',
                      marginLeft: screen.isMobile ? '32px' : '20px',
                      marginRight:screen.isMobile ? '32px' : '18px'
                    },
                  }}
                  sx={{
                    backdropFilter: 'blur(8px)',
                  }}
                >
                  <DialogTitle id='alert-dialog-title' 
                  sx={{
                    padding: screen.isMobile ? '7px 11px':'16px 11px'
                  }} >
                    <Typography
                      data-testid='goToSummitDialog'
                      component='div'
                      variant='div'
                      sx={{
                        width: screen.isMobile ? '280px' : '370px',
                        fontSize: screen.isMobile ? '20px' :'31px',
                        lineHeight: '32px',
                        fontWeight: 700,
                        marginTop: screen.isMobile ? '33px':'50px',
                        fontFamily: '"JohnsonDisplay-Bold"',
                        height: '64px'
                        
                      }}
                    >
                      You are about to leave this secured website
                    </Typography>
                    <IconButton
                      data-testid='myTrainingsStatusDialogClose'
                      aria-label='close'
                      onClick={handleModalClose}
                      sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: redtheme.palette.text.primary,
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent
                    sx={{
                      paddingTop: screen.isMobile ? '0px':'16px',
                      overflow : 'hidden',
                      paddingLeft: screen.isMobile ? '11px':null
                    }}
                  >
                    <DialogContentText id='alert-dialog-description'>
                      <Typography
                        component='span'
                        sx={{
                          width: screen.isMobile ? '280px':'372px',
                          height: '32px',
                          fontSize: screen.isMobile ? '14px':'16px',
                          lineHeight: '16px',
                          fontWeight: 500,
                          fontFamily: '"JohnsonText-Light"',
                        }}
                      >
                        You will be redirected to SUMMIT System in a new tab. Do you want to
                        continue?
                      </Typography>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions
                    sx={{
                      top: 'auto',
                      bottom: 0,
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: '15px',
                      background: redtheme.palette.background.default,
                      paddingRight: '24px',
                      paddingBottom: screen.isMobile ? '0':'30px',
                      height: '64px',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <Button
                          onClick={handleModalClose}
                          variant='text'
                          size='medium'
                          sx={{
                            width: '100%',
                            height: '40px',
                            background: redtheme.palette.button.white,
                            color: redtheme.palette.primary.main,
                            ':hover': {
                              background: redtheme.palette.button.white,
                              color: redtheme.palette.primary.main,
                            },
                            textTransform: 'none',
                            borderRadius: screen.isMobile ? '8px':'12px',
                            fontWeight: 500,
                            fontSize: '18px',
                            lineHeight: '24px',
                            // fontWeight: 700,
                            fontFamily: '"JohnsonText-Light"',
                            paddingBottom: screen.isMobile ? '15px':'0'
                          }}
                        >
                          No
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          data-testid='summitRedirectConfirmationBtn'
                          autoFocus
                          onClick={handleOpenSummitLink}
                          variant='contained'
                          size='medium'
                          //width='4000px'
                          sx={{
                            left: '0%',
                            right: '0%',
                            top: '0%',
                            bottom: '0%',
                            width: '100%',
                            height: screen.isMobile ? '30px':'40px',
                            background: redtheme.palette.primary.main,
                            color: redtheme.palette.button.white,
                            ':hover': {
                              background: redtheme.palette.primary.main,
                              color: redtheme.palette.button.white,
                            },
                            textTransform: 'none',
                            borderRadius: screen.isMobile ? '8px':'12px',
                            fontSize: '18px',
                            lineHeight: '24px',
                            fontWeight: 500,
                            fontFamily: '"JohnsonText-Light"',
                          }}
                        >
                          Yes
                        </Button>
                      </Grid>
                    </Grid>
                  </DialogActions>
                </Dialog>
              </>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* This Stack renders Training Table which renders TrainingTable Component*/}
      <Stack
        direction='row'
        spacing={1}
        sx={{
          background: redtheme.palette.background.default,
          padding: screen.resolveStyles({ mobile: '0 16px', tablet: '0 24px', desktop: '0 72px' }),
        }}
      >
        <TrainingTable />
      </Stack>
    </>
  );
};
