import { Stack, Typography } from "@mui/material"
import { useScreen } from "common/hooks/useScreen"
export const DonutChartStatistics = ({count,totalCount, donutLabelText}) => {
  const screen = useScreen(); 

    return (
      <Stack
      direction='column'
      sx={{
        justifyContent: 'center',
      }}
    >
      <Typography
          sx={{
            fontFamily: '"JohnsonText-Bold"',
            fontWeight: 700,
            fontSize: screen.resolveStyles({
              mobile: '12px',
              tablet: '12px',
              desktop: '14px',
            }),
            lineHeight: '16px',
            width: '223px',
            textTransform: 'uppercase',
            mb: '8px',
          }}
        >
        <div dangerouslySetInnerHTML={{ __html: donutLabelText }} />
        </Typography>

      
      <Typography
        sx={{
          fontFamily: '"JohnsonText-Bold"',
          fontWeight: 700,
          fontSize : screen.resolveStyles({
              mobile: '18px',
              tablet: '20px',
              desktop: '24px',
            }),
          lineHeight: '16px',
        }}
      >
        {count} OF{' '}
        {totalCount}
      </Typography>
    </Stack>
    
    )
}