export const AdminQuickFilterReducer = (state = {}, action) => {
    if (action.type === 'GET_ADMIN_QUICK_FILTER') {
      return {
        ...state,
        quickFilterDetails: action.payload,
      };
    } else {
      return state;
    }
  };
  