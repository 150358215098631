import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { LoaDialog } from 'TAP/components/loaDialog';
import { RedTheme } from 'common/global';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { TapGlobalMessages } from 'TAP/global';
import { TrainingPlanViewIndividualUser } from 'TAP/pages/trainingPlanViewIndividualUser';
import { TrainingPlanViewManagerSingleTeam } from 'TAP/pages/trainingPlanViewManagerSingleTeam';
import { formatTime, getGAMeasurementId } from 'TAP/utils/commonMethods';
import { useSelector } from 'react-redux';
import { NUMBER_ARRAY } from 'common/global/constants';
import { userRole as userRoleData } from 'TAP/styles/genericStyles/userRole';
import ReactGA from "react-ga4";
import { useScreen } from 'common/hooks/useScreen';

export const TrainingView = () => {
  const [pageHeading, setPageHeading] = useState('');
  const [userRole, setUserRole] = useState(0);
  const [selectedTabValue, setSelectedTabValue] = useState(0);
  const [pageTabs, setPageTabs] = useState([]);
  const [lastRefreshDate, setLastRefreshDate] = useState('');
  const [leaveDialogStatus, setLeaveDialogStatus] = useState(false);
  const screen = useScreen();
  let params = {}
  let redtheme = RedTheme;
  let getLoggedInUserDetails = useSelector(state => state.tapUserDetailsRd);
  
  useEffect(()=>{
    ReactGA.initialize(`G-${getGAMeasurementId()}`);
    ReactGA.send({ hitType: "pageview", page: "/TAP/trainingview", title: "Training Plan View" });
   }, []);
  useEffect(() => {
    if (getLoggedInUserDetails && Object.keys(getLoggedInUserDetails).length) {
      setUserRole(parseInt(getLoggedInUserDetails?.userDetails?.data[0].user_role));
      let leaveIndicator = getLoggedInUserDetails?.userDetails?.data[0].leave_of_absence;

      if (leaveIndicator.trim() === 'N') {
        setLeaveDialogStatus(false);
      } else {
        setLeaveDialogStatus(true);
        setSelectedTabValue(1);
      }

      const formattedLastRefreshTime = formatTime(
        getLoggedInUserDetails?.userDetails?.data[0].last_refresh_date,
      );
      setLastRefreshDate(formattedLastRefreshTime);
    }
  }, [getLoggedInUserDetails]);

  useEffect(() => {
    setPageHeading('Training Plan View');

    if (userRole === NUMBER_ARRAY.two) {
      setPageTabs([{ name: 'My Plan' }, { name: 'My Team' }]);
    }
    if (userRole === NUMBER_ARRAY.three) {
      setPageTabs([{ name: 'My Plan' }, { name: 'My Direct Reports' }]);
    }
  }, [userRole]);

  const handleChange = (_event, newValue) => {
    setSelectedTabValue(newValue);
  };

  const handleCloseLoaDialog = () => {
    setLeaveDialogStatus(false);
    setSelectedTabValue(1);
    if (selectedTabValue === 0) {
      setLeaveDialogStatus(true);
    }
  };

  const handleOpenAskGSLink = () => {
    window.open(TapGlobalMessages.URLS.ASK_GS, '_blank');
  };
 
  const renderLAODialog = ( userRoleValue) => {
    params = {
      isOpen: true,
      isRightButtonVisible: true,
      rightButtonText: `AskGS`,
      dialogTitle: `Sorry!`,
      onRightButtonClick: handleOpenAskGSLink,
    };
    params = {
      ...params,
      onClose: userRoleValue == '3' ? null : handleCloseLoaDialog,
      leftButtonText: userRoleValue == '1' ? `View My Team's Data` : null,
      isLeftButtonVisible:userRoleValue == '1' ? true : null,
      onLeftButtonClick: userRoleValue == '1' ? handleCloseLoaDialog : null,
      isCloseIconVisible: userRoleValue == '2' ? true : null,
      dialogBodyMessage1:
      userRoleValue == '3'
          ? TapGlobalMessages.LOA.LOA_NO_DATA
          : TapGlobalMessages.LOA.LOA_NO_DATA_MANAGER,
      dialogBodyMessage2:
      userRoleValue == '3'
          ? TapGlobalMessages.LOA.ASK_GS_HELPER
          : TapGlobalMessages.LOA.ASK_GS_HELPER_MANAGER,
    };
    
    return(
      <LoaDialog {...params}/>)
    };

  return (
    <>
      <Box sx={{ width: '100%', padding: !screen.isDesktop ? '16px' : '0 72px', mb: '27px' }}>
        <Typography
          //gutterBottom
          variant='h3'
          component='h3'
          sx={{
            fontSize: !screen.isDesktop ? '25px' : '39px',
            fontFamily: '"JohnsonDisplay-Bold"',
            lineHeight: !screen.isDesktop ? '32px' : '48px',
            fontWeight: 700,
            marginBottom: '4px',
          }}
        >
          {pageHeading}
        </Typography>
        <Typography
          data-testid='lastRefreshDate'
          //gutterBottom
          variant='div'
          component='div'
          sx={{
            fontSize: screen.isMobile ? '12px':'14px',
            lineHeight: '16px',
            color: redtheme.palette.fontColor.gray,
            fontFamily: '"JohnsonText-Light"',
            fontWeight: screen.isMobile ? 200:400,
            marginBottom: '9px',
          }}
        >
          Last refresh {lastRefreshDate}
        </Typography>
        <Typography
          //gutterBottom
          variant='div'
          component='div'
          sx={{
            fontFamily: '"JohnsonText-Light"',
            fontSize: screen.isMobile ? '12px':'14px',
            fontStyle: 'normal',
            fontWeight: screen.isMobile ? 200:400,
            lineHeight: '16px',
            color: redtheme.palette.fontColor.gray,
          }}
          data-testid='tpvbreifdescription'
        >
          {TapGlobalMessages.trainingPlanView.breifDescription}
        </Typography>
      </Box>

      {userRole === 1 ? (
        leaveDialogStatus === true ? (
          renderLAODialog(userRoleData.usr3)
          ) : (
          <>
            <br></br>
            <br></br>
            <TrainingPlanViewIndividualUser />
          </>
        )
      ) : (
        <>
          <Box
            sx={{
              // display: 'flex',
              marginTop: '10px',
              background: redtheme.palette.box.white,
            }}
          >
            <Box sx={{ width: !screen.isDesktop ? '100%' : '52%', mb: '35px', padding: !screen.isDesktop ? '16px' : '0 72px', marginTop: '15px' }}>
              <Tabs
                data-testid='managerSingleTeamMainTabs'
                value={selectedTabValue}
                onChange={handleChange}
                TabIndicatorProps={{
                  sx: {
                    height: '3px',
                    borderRadius: '3px',
                  },
                }}
                sx={{
                  minHeight: '45px',
                  '& .Mui-selected': {
                    fontWeight: 700,
                    color: redtheme.palette.box.strong_red,
                  },
                }}
                aria-label='TAP Navigation'
              >
                {pageTabs &&
                  pageTabs?.length !== 0 &&
                  pageTabs.map((link, index) => (
                    <Tab
                      key={index}
                      label={link.name}
                      data-testid={link.name}
                      sx={{
                        textTransform: 'none',
                        borderBottom: 3,
                        fontSize: '16px',
                        borderColor: 'divider',
                        fontWeight: '400',
                        minHeight: '45px',
                        color: `${redtheme.palette.fontColor.very_dark_grey} !important`,
                      }}
                    />
                  ))}
              </Tabs>
            </Box>
          </Box>

          {selectedTabValue === 0 ? (
            leaveDialogStatus === true ? (
              userRole === 1 ? (
                renderLAODialog(userRoleData.usr2) 
              ) : (     
                renderLAODialog(userRoleData.usr1) 
              )
            ) : (
              <TrainingPlanViewIndividualUser />
            )
          ) : (
            <TrainingPlanViewManagerSingleTeam />
          )}
        </>
      )}
    </>
  );
};
