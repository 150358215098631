/**
* TapManagerSingleTeamReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const TapManagerSingleTeamReducer = (state = {}, action) => {
    if(action.type === 'POST_MANAGER_SINGLE_TEAM_DETAILS'){
        return {
            ...state,
            managerSingleTeamAPIResponse: action.payload
        }
      }
      else{
        return state;
      }
};
