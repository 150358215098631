import { Box } from "@mui/material"
import { labels } from "common/global/constants"
import {Button} from "@mui/material"
import DTH_LOGO from 'common/assets/images/DTH_LOGO.png'
import Info from 'common/assets/images/Info.svg'
import Maintenance from 'common/assets/images/Maintenance.svg'
import Reverse from 'common/assets/images/Reverse.svg'
import { Header } from "TAP/navigation"
import { Footer } from "common/components"
import { RedTheme } from 'common/global';
import { useScreen } from "common/hooks/useScreen"

export const CustomErrorPage = ({
  header = '',
  body = '',
  buttonText = labels.errorButtonText,
  isButtonVisible = false,
  buttonHandler = () => {}, 
  subHeader = '', 
  isMaintenancePage  = false, 
}) => {
  let redtheme = RedTheme;

  const screen = useScreen(); 
  
  const renderHeader = () => {
    const headerArray = header.split(' '); 
    if(screen.isMobile && isMaintenancePage) {
      return (<><div style={{
            lineHeight : '64px', 
            fontSize:'48px',
            fontFamily: '"JohnsonText-Bold"',
            fontWeight: 'bold'
        }}>{headerArray[0]+' '+headerArray[1]}</div>
        <div style={{
            lineHeight : '64px', 
            fontSize:'48px',
            fontFamily: '"JohnsonText-Bold"',
            fontWeight: 'bold'
        }}>{headerArray[2]}</div></>)
    }
    else {
      return (<div style={{
            lineHeight : '64px', 
            fontSize:'48px',
            fontFamily: '"JohnsonText-Bold"',
            fontWeight: 'bold'
        }}>{header}</div>)
    }
  }
return (
    <>
    <Header isNavigationMenuVisible={false}/>
    <div
    style={{
        display: 'flex', 
        flexGrow: '1', 
        justifyContent: 'center', 
        alignItems: 'center'
    }}
    >
      <Box
        sx={{
          position: 'fixed',
          top: '110px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: -1000,
        }}
      >
        <img src={DTH_LOGO} alt="DTH Logo water mark" style={{ height: '404px', width: '558px', zIndex: -10 }} />
      </Box>
      <div
      style={{
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center'
      }}
      >
        <img src={isMaintenancePage ? Maintenance : Info} alt="Info Icon" />
        {renderHeader()}
        <div style={{
            lineHeight : '50px', 
            fontSize:'20px',
            fontFamily: '"JohnsonText-Bold"',
            fontWeight: 'bold'
        }}>{subHeader}</div>
        <div className="text-[15px] pt-3 leading-[50px] text-justify font-jnjTRegular" style={{
            fontSize:'15px', 
            lineHeight:'50px', 
            fontFamily: '"JohnsonText-Regular"'
        }}>{body}</div>
        {isButtonVisible && (
          <div className="px-[10%] pt-70" style={{
            paddingTop: '20px'
          }}>
            <Button sx={{
                color: redtheme.palette.button.white,
                bgcolor: redtheme.palette.primary.main,
                borderRadius: '10px',
                fontFamily: '"JohnsonText-Medium"',
                paddingLeft: '10px', 
                paddingRight: '10px', 
                '&:hover': {
                    backgroundColor: redtheme.palette.primary.main,
                    color: redtheme.palette.button.white,
                    fontFamily: '"JohnsonText-Medium"',
                },
            }}
            onClick={buttonHandler}>
              {buttonText}
              <img src={Reverse} alt="Reverse Icon" style={{paddingLeft : '10px'}}/>
            </Button>
          </div>
        )}
      </div>
    </div>
    <Footer/>
    </>
)
}
