/**
* myPerformanceComplianceStatusReducer.
* This Reducer is used for search My complaince status data
* @param {object} state
* @param {string} action
* @returns {object}
*/
export const myPerformanceComplianceStatusReducer = (state = {}, action) => {
    if (action.type === 'GET_MY_COMPLIANCE_STATUS_SEARCH_DATA') {
        return {
            ...state,
            myComplainceStatusSearchData: action.payload,
        }
    }
    else {
        return state;
    }
}