export const AdminFilterPopUpDetailsReducer = (state = {}, action) => {
    
    if(action.type==='GET_ADMIN_POPUP_FILTER_DATA') {
      return {
        ...state,
        popupFilterData: action.payload,
      }
    }
    else {
      return state;
    }
};
