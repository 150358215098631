export const MyDirectReporteeComplianceProfileReducer = (state = {}, action) => {
    if (action.type === 'GET_MY_DIRECT_REPORTEE_COMPLIANCE_DATA') {
      return {
        ...state,
        orgComplianceDetails: action.payload,
      };
    } else {
      return state;
    }
  };
  