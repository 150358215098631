/**
* TapUserDetailsReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const TapUserDetailsReducer = (state = {}, action) => {
    if(action.type === 'GET_USER_DETAILS'){
        return {
            ...state,
            userDetails: action.payload
        }
      }
      else{
        return state;
      }
};
