export const width = {
    wid1: 1,
    wid2:2,
    wid8:8,
    wid10:10,
    wid28:28,
    wid40:40,
    wid50:50,
    wid100:100,
    wid110: 110,
    wid150: 150, 
    wid160: 160,
    wid180: 180,
    wid200: 200, 
    wid250: 250, 
    wid300: 300, 
    wid350: 350,
    wid400: 400,
    wid1500 : 1500
};
  