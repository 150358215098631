import apiAction from 'common/redux/actions/apiAction';
import {
  POST_DIRECT_REPORTEE_NON_COMPLIANT_URL,
  POST_DIRECT_REPORTEE_AT_RISK_URL,
} from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

const getDirectNonCompliantData = data => {
  return {
    type: 'GET_DIRECT_NON_COMPLIANT_DATA',
    payload: data,
  };
};

export const DirectNonCompliantData = configParams => {
  return apiAction.postAPI({
    url: POST_DIRECT_REPORTEE_NON_COMPLIANT_URL(),
    data: configParams,
    onSuccess: getDirectNonCompliantData,
    onFailure: err => logError('Error occured in fetching DirectNonCompliantData data.:', err),
    label: 'FETCH_DIRECT_NON_COMPLIANT_DATA',
  });
};
const getDirectAtRiskData = data => {
  return {
    type: 'GET_DIRECT_AT_RISK_DATA',
    payload: data,
  };
};

export const DirectAtRiskData = configParams => {
  return apiAction.postAPI({
    url: POST_DIRECT_REPORTEE_AT_RISK_URL(),
    data: configParams,
    onSuccess: getDirectAtRiskData,
    onFailure: err => logError('Error occured in fetching DirectAtRiskData data.:', err),
    label: 'FETCH_DIRECT_AT_RISK_DATA',
  });
};
