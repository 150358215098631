import React from 'react';
import Box from '@mui/material/Box';

export const AtRiskTooltipBody = () => {
  return (
    <Box>
      <Box>
        This view shows individuals from your Organization that are At Risk and in which area.
      </Box>
      <br />
      <Box>
        See Training Actions{' > '}Trainings Overdue for details on actionable past due trainings.
      </Box>
    </Box>
  );
};
