export const AdminReducer = (state = {}, action) => {
    switch (action.type) {
  
      case 'GET_ADMIN_OVERDUE_DATA':
        return {
          ...state,
          trainingOverdueData: action.payload,
        };
      case 'GET_ADMIN_UPCOMINGDUE_DATA':
        return {
          ...state,
          trainingUpcomingdueData: action.payload,
        };
     
      case 'API_START':
        if (action.payload === "FETCH_ADMINOVERDUE_DATA") {
          return {
            ...state,
            isLoadingADTrainingOverDueData: true,
          };
        }
        else if (action.payload === "FETCH_ADMINUPCOMINGDUE_DATA") {
          return {
            ...state,
            isLoadingADTrainingUpcomingDueData: true,
          };
        }
        else {
          return state;
        }
        
  
      case 'API_END':
        if (action.payload === "FETCH_ADMINOVERDUE_DATA") {
          return {
            ...state,
            isLoadingADTrainingOverDueData: false,
          };
        }
        else if (action.payload === "FETCH_ADMINUPCOMINGDUE_DATA") {
          return {
            ...state,
            isLoadingADTrainingUpcomingDueData: false,
          };
        }
        else {
          return state;
        }
        
  
      default:
        return state;
    }
  };