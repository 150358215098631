import React from 'react';
import { Tooltip, Typography, Box, tooltipClasses, styled } from '@mui/material';
import { RedTheme } from 'common/global';

let redtheme = RedTheme;
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: redtheme.palette.tooltip.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: redtheme.palette.tooltip.white,
    color: redtheme.palette.tooltip.black_gray,
    fontSize: '14px',
    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.24)',
    border: `1px solid ${redtheme.palette.tooltip.white}`,
    borderRadius: '16px',
    height:'39px', 
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding:'10px', 
    gap: '8px',
  },
}));

export const LoaToolTip = ({ title, placement, children }) => {
  return (
    <HtmlTooltip
      placement={placement}
      title={
        <>
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Light"',
              color: redtheme.palette.tooltip.black_gray,
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '16px',
              marginBottom: '18px',
            }}
          >
            {title}
          </Typography>
          <br></br>
        </>
      }
      arrow
    >
      <Box sx={{ color: redtheme.palette.tooltip.black_gray, fontSize: 14 }}>
        <Typography
          sx={{
            color: redtheme.palette.tooltip.black_gray,
            fontSize: '14px',
            lineHeight: '14px',
            fontFamily: '"JohnsonText-Regular"',
            fontWeight: 500,
          }}
        >
          {children}
        </Typography>
      </Box>
    </HtmlTooltip>
  );
};
