import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux';
import { RootReducer } from 'common/redux/reducer';
import { ApiMiddleware } from 'common/middleware';

/**
* configureStore.
* root reducer with router state
*/
function configureStore(preloadedState) {
  return createStore(
    RootReducer(),
    preloadedState,
    compose(
      applyMiddleware(
        ApiMiddleware,
      ),
    ),
  );
}

export default configureStore;