import apiAction from 'common/redux/actions/apiAction';
import { POST_ADMINVIEW_COMPLETED_STATUS_URL } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';
/****
 * POST TRAINING PLAN VIEW DIRECT REPORTEE MANAGER SINGLE TEAM DETAILS
 ****/
const getAdminViewCompletedStatusDetails = data => {
    return {
        type: 'POST_ADMINVIEW_COMPLETED_STATUS_DETAILS',
        payload: data,
    };
};

export const AdminViewCompletedStatusDetails = configParams => {
    return apiAction.postAPI({
        url: POST_ADMINVIEW_COMPLETED_STATUS_URL(),
        data: configParams,
        onSuccess: getAdminViewCompletedStatusDetails,
        onFailure: err => logError('Error occured in fetching dashboard data.',err),
        label: 'FETCH_ADMINVIEW_COMPLETED_STATUS_DATA',
    });
};
