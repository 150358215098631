import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button, Typography, Box } from '@mui/material';
import {  RedTheme } from 'common/global';

let redtheme =RedTheme;

export const Error = () => {
  let { instance } = useMsal();

  const handlelogoutUser = () => {
    localStorage.clear();
    instance.logout();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        // set background color
        backgroundColor: redtheme.palette.box.light_gray, 
      }}
    >
      <Typography variant='h1' sx={{ fontWeight: 'bold', fontSize: '3rem', mb: 3 }}>
        Unexpected Error
      </Typography>
      <Typography variant='h6' sx={{ fontWeight: 'bold', mb: 3 }}>
        Sorry for the inconvenience
      </Typography>
      <p>
        {' '}
        Please try refreshing this page. If the problem persists, please close your browser and
        re-load, or you can logout the application also.
      </p>
      <Button
        onClick={handlelogoutUser}
        sx={{
          color: redtheme.palette.button.black,
           // set background color
          bgcolor: redtheme.palette.button.white,
          // increase font size
          fontSize: '20px', 
          // increase line height
          lineHeight: '24px', 
          textTransform: 'capitalize',
          fontWeight: 800,
          fontFamily: '"J&J CircularTT Bold Web"',
          '&:hover': { backgroundColor: redtheme.palette.button.white, boxShadow: '' },
          // add margin-top
          mt: 3, 
          // increase horizontal padding
          px: 4,
           // increase vertical padding 
          py: 2,
        }}
        color='primary'
        variant='contained'
      >
        Logout
      </Button>
      
    </Box>
  );
};
