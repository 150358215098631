import axios from "axios";
import { POST_MY_ORG_COMPLIANCE_TABULAR_DATA_URL } from 'TAP/redux/actions/apiPathConstants';
/**
* Fetch Training Table Data.
* @param {object} requestPayload
* @returns {object}
*/
export const fetchTrainingTableData = async requestPayload => {
    const URL = process.env.REACT_APP_API_BASE_URL + POST_MY_ORG_COMPLIANCE_TABULAR_DATA_URL();

    const apiData = await axios.post(URL, requestPayload); //NOSONAR
    return apiData; //NOSONAR
}