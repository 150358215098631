import apiAction from 'common/redux/actions/apiAction';
import { POST_MY_DIRECT_REPORTEE_COMPLIANCE_URL } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

const getMyDirectReporteeComplianceData = data => {
  return {
    type: 'GET_MY_DIRECT_REPORTEE_COMPLIANCE_DATA',
    payload: data,
  };
};

export const MyDirectReporteeComplianceData = configParams => {
  return apiAction.postAPI({
    url: POST_MY_DIRECT_REPORTEE_COMPLIANCE_URL(),
    data: configParams,
    onSuccess: getMyDirectReporteeComplianceData,
    onFailure: err => logError('Error occured in fetching dashboard data.:', err),
    label: 'FETCH_MY_DIRECT_REPORTEE_COMPLIANCE_DATA',
  });
};
