import apiAction from 'common/redux/actions/apiAction';
import { POST_ADMIN_FAVOURITE_FILTER } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

const getAdminFavouriteFilter = data => {
  return {
    type: 'GET_ADMIN_FAVOURITE_FILTER',
    payload: data,
  };
};

export const AdminFavouriteFilterAction = configParams => {
  return apiAction.postAPI({
    url: POST_ADMIN_FAVOURITE_FILTER(),
    data: configParams,
    onSuccess: getAdminFavouriteFilter,
    onFailure: err => logError('Error occured in fetching Admin Favourite Filter data:', err),
    label: 'FETCH_ADMIN_FAVOURITE_FILTER',
  });
};
