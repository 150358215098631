import apiAction from 'common/redux/actions/apiAction';
import { logError } from 'TAP/utils/commonMethods';

import {
  POST_MY_ORG_COMPLETION_URL
} from './apiPathConstants';

const getMyOrgCompletionData = (data) => {
  return {
    type: 'GET_MY_ORG_COMPLETION_DATA',
    payload: data,
  };
};

export const MyOrgCompletionData = (configParams) => {
  return apiAction.postAPI({
    url: POST_MY_ORG_COMPLETION_URL(),
    data: configParams,
    onSuccess: getMyOrgCompletionData,
    onFailure: err => logError('Error occured in fetching My Org training completion data.:', err),
    label: 'FETCH_MYORG_COMPLETION_DATA',
  });
};