import apiAction from 'common/redux/actions/apiAction';
import { POST_TEAM_PERFORMANCE_POPUP_REMINDER } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

/****
 * POST POPUP LISTING DETAILS
 ****/
const getPopupUpReminder = data => {
  return {
    type: 'GET_POPUP_REMINDER',
    payload: data,
  };
};

export const PopupUpReminder = configParams => {
  return apiAction.postAPI({
    url: POST_TEAM_PERFORMANCE_POPUP_REMINDER(),
    data: configParams,
    onSuccess: getPopupUpReminder,
    onFailure: err =>  logError('Error occurred in fetching dashboard data:', err),
    label: 'FETCH_POPUP_REMINDER',
  });
};