import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { SelectedTheme } from 'common/global';
import { useScreen } from 'common/hooks/useScreen';
/**
* BarChartFilter.
* Use Box Component
* Use Stack Component
* Use Typography Component
* @param {object} legends
* @param {object} filters
* @param {func} onChangeFilter
* @returns {JSX.Element}
*/
export const BarChartFilter = ({
  legends,
  filters,
  onChangeFilter,
  handleButtonClick,
  activeButton
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const theme = SelectedTheme();
  const [windowWidth,setWindowWidth] = useState(0); 
  const [marginLeft,setMarginLeft] = useState((window.innerWidth/2) - 598); 
  const screen = useScreen(); 
  /**
  * useEffect
  */
  useEffect(() => {
    setIsDisabled(false);
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setMarginLeft((window.innerWidth/2) - 598)
  }, [windowWidth]);


  const getSelectedFilterValue = filterName => {
    let filterValue;
    if(filterName == 'Year')
      filterValue = 'Yearly'
    else if(filterName == 'Quarter')
       filterValue = 'Quarterly'
    else if(filterName == 'Month')
      filterValue = 'Monthly'
    else
       filterValue = filterName
    return filterValue
   
  }

  const SelectFilter = () => {
    return (<Stack
            direction='row'
            spacing={1}
            sx={{
              padding: '0px',
              // paddingleft: '10px',
              borderRadius: '40px',
              background: filters?.settings.background,
              margin: '5px',
              marginLeft : `${!screen.isDesktop ? 60 : marginLeft}px`,
              width: screen.resolveStyles({
                mobile : '75%', 
                tablet : '85%', 
                desktop : null,
              })
            }}
          >
            {filters?.data?.map((filter, index) => (
              <Button
                key={index}
                onClick={() => { onChangeFilter(filter.id, filter.name); handleButtonClick(filter.id) }}
                variant={activeButton === filter.id ? 'contained' : 'text'}
                disabled={isDisabled}
                sx={{
                  borderRadius: '40px',
                  border: activeButton === filter.id ? '2px solid #f7a299' : '',
                  color:
                    activeButton === filter.id
                      ? 'primary'
                      : `${theme.palette.fontColor.gray_orange}`,
                }}
                data-testid={filter.testId}
              >
                <Typography
                  sx={{
                    fontFamily: filters?.settings.fontFamily,
                    fontWeight: filters?.settings.fontWeight,
                    fontSize: filters?.settings.fontSize,
                    lineHeight: filters?.settings.lineHeight,
                    textTransform: filters?.settings.textTransform,
                  }}
                >
                  {getSelectedFilterValue(filter.name)}
                </Typography>
              </Button>
            ))}
          </Stack>)
  }

  return (
    <Box
      sx={{
        paddingTop: '5px',
        borderRadius: '20px',
        margin: '5px',
      }}
    >
      <Stack sx={{marginBottom : filters === undefined ? '22px' : ' '}} spacing={legends.settings.spacing} direction='row'>


        {legends?.data?.map((legend, index) => (

          <Box key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: legends.settings.gap,
              width: legends.settings.width,
            }}
          >
            <Box
              sx={{
                height: legends.settings.circleHeight,
                width: legends.settings.circleWidth,
                borderRadius: '50%',
                background: legend.color,
              }}
            ></Box>
            <Typography
              variant='h5'
              sx={{
                fontFamily: legends.settings.fontFamily,
                fontWeight: legends.settings.fontWeight,
                fontSize: legends.settings.fontSize,
                lineHeight: legends.settings.lineHeight,
                textTransform: legends.settings.textTransform,
                paddingTop: legends.settings.paddingTop,
              }}
            >
              {legend.title}
            </Typography>
          </Box>
        ))}
        <Stack sx={{}}>
          {screen.isDesktop && SelectFilter()}
        </Stack>
      </Stack>
      {!screen.isDesktop && SelectFilter()}
    </Box>
  );
};
