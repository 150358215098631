import apiAction from 'common/redux/actions/apiAction';
import { POST_DIRECT_REPORTEE_TRAININGS_POPUP_FILTER } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

/****
 * POST POPUP LISTING DETAILS
 ****/
const getDirectReporteeTrainingsPopupFilterData = data => {
  return {
    type: 'GET_DIRECT_REPORTEE_TRAINING_POPUP_FILTER_DATA',
    payload: data,
  };
};

export const DirectReporteeTrainingsPopupFilterData = configParams => {
  return apiAction.postAPI({
    url: POST_DIRECT_REPORTEE_TRAININGS_POPUP_FILTER(configParams.actionsType),
    data: configParams,
    onSuccess: getDirectReporteeTrainingsPopupFilterData,
    onFailure: err => logError('Error occured in fetching Direct Reportee Trainings PopupUp Filter data.:', err),
    label: 'FETCHT_DIRECT_REPORTEE_TRAINING_POPUP_FILTER_DATA',
  });
};