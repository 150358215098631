export const AdminManageFilterListReducer = (state = {}, action) => {
    if (action.type === 'GET_MANAGE_FAVOURITE_FILTER_LIST') {
      return {
        ...state,
        manageFilterDetails: action.payload,
      };
    } else {
      return state;
    }
  };

  export const AdminManageFilterLoadReducer = (state = {}, action) => {
    if (action.type === 'GET_MANAGE_FAVOURITE_FILTER_LOAD') {
      return {
        ...state,
        manageFilterDetails: action.payload,
      };
    } else {
      return state;
    }
  };

  export const AdminManageFilterDeleteReducer = (state = {}, action) => {
    if (action.type === 'GET_MANAGE_FAVOURITE_FILTER_DELETE') {
      return {
        ...state,
        manageFilterDetails: action.payload,
      };
    } else {
      return state;
    }
  };
  