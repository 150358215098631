import React, { useState } from 'react';
import { DataGrid, GridOverlay, GridToolbar } from '@mui/x-data-grid';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button,
  TextField,
  Autocomplete,
  Typography,
  IconButton,
} from '@mui/material';
import { mockDataTest } from './staticData';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import {  RedTheme} from 'common/global';

const columns = [
  {
    field: 'User_Full_Name',
    headerName: 'User Full Name',
    flex: 1,
    cellClassName: 'name-column--cell',
  },
  {
    field: 'Manager_User_Full_Name',
    headerName: 'Manager Name',
    flex: 1,
  },
  {
    field: 'Indirect_Manager_User_Full_Name',
    headerName: 'Indirect Manager Name',
    flex: 1,
  },
  { field: 'Department', headerName: 'Department', flex: 1 },
  { field: 'Curriculum_Title', headerName: 'Curriculam Title', flex: 1 },
  { field: 'Training_Title', headerName: 'Training Title', flex: 1 },
  { field: 'Transcript_Due_Date', headerName: 'Transcript Due Date', flex: 1 },
  {
    field: 'Transcript_Completed_Date',
    headerName: 'Transcript Completed Date ',
    flex: 1,
  },
];

const subColumns = [
  {
    field: 'User_Full_Name',
    headerName: 'Name',
    cellClassName: 'name-column--cell',
    flex: 0.7,
  },
  { field: 'Manager_User_Full_Name', headerName: 'Manager', flex: 2 },
  { field: 'Transcript_Due_Date', headerName: 'Due Date', flex: 1 },
];

export const DataTable = () => {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  let redtheme = RedTheme;
  const handleRowClick = (params) => {
    if (params.field === 'Department') {
      const headerName = columns.find((column) => column.field === 'Department').headerName;
      setSelectedRow({ headerName, rowData: params.row });
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedRow(null);
  };

  return (
    <Box m='20px'>
      <Box m='40px 0 0 0' height='75vh'>
        <DataGrid
          checkboxSelection
          rows={mockDataTest}
          columns={columns}
          pageSize={5}
          onCellClick={handleRowClick}
          components={{
            LoadingOverlay: GridOverlay,
            NoRowsOverlay: GridOverlay,
            Toolbar: GridToolbar,
          }}
        />
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Typography fontWeight='bold'>
            {selectedRow && selectedRow.headerName}
          </Typography>
          <IconButton onClick={handleClose} style={{ position: 'absolute', top: 5, right: 3 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box display='flex' flexDirection='row' mb={2}>
            <Autocomplete
              options={mockDataTest}
              getOptionLabel={(option) => option.User_Full_Name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Name'
                  sx={{ borderBottom: '1px solid black', width: '270px', fontSize: '14px' }} // Set font size to 14px
                />
              )}
              sx={{ mr: 2 }}
            />
            <Autocomplete
              options={mockDataTest}
              getOptionLabel={(option) => option.Manager_User_Full_Name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Manager'
                  sx={{ borderBottom: '1px solid black', fontSize: '14px' }}
                /> // Set font size to 14px
              )}
              sx={{ mr: 2 }}
            />
            <Autocomplete
              options={mockDataTest}
              getOptionLabel={(option) => option.Transcript_Due_Date}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label='Date'
                  sx={{ borderBottom: '1px solid black', fontSize: '14px', width: '120px' }}
                /> // Set font size to 14px
              )}
            />
          </Box>

          <Box sx={{ height: '300px', maxWidth: '100%', margin: '0 auto' }}>
            {selectedRow && (
              <DataGrid
                checkboxSelection
                rows={mockDataTest}
                columns={subColumns}
                pageSize={5}
                components={{
                  LoadingOverlay: GridOverlay,
                  NoRowsOverlay: GridOverlay,
                }}
                sx={{ border: 'none', fontSize: '14px' }} // Set font size to 14px
              />
            )}
          </Box>

          <DialogContent>
            <Box position='absolute' bottom={0} right={0} m={2} display='flex'>
              <Button
                variant='outlined'
                startIcon={<MailOutlineIcon />}
                sx={{
                  border: '1px solid black',
                  color: 'black',
                  marginLeft: '10px',
                  fontSize: '14px',
                }} // Set font size to 14px
              >
                Send Reminder
              </Button>
              <Button
                variant='outlined'
                startIcon={<ArrowDownwardIcon />}
                sx={{
                  border: `1px solid ${redtheme.palette.button.strong_red}`,
                  color: 'black',
                  marginLeft: '10px',
                  fontSize: '14px',
                }} // Set font size to 14px
              >
                Download Excel
              </Button>
            </Box>
          </DialogContent>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
