import apiAction from 'common/redux/actions/apiAction';
import { POST_MANAGER_SINGLETEAM_URL } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

/****
 * POST MANAGER SINGLE TEAM DETAILS
 ****/
const getManagerSingleTeamDetails = data => {
    return {
        type: 'POST_MANAGER_SINGLE_TEAM_DETAILS',
        payload: data,
    };
};

export const ManagerSingleTeamDetails = configParams => {
    return apiAction.postAPI({
        url: POST_MANAGER_SINGLETEAM_URL(),
        data: configParams,
        onSuccess: getManagerSingleTeamDetails,
        onFailure: err => logError('Error occured in fetching dashboard data.:', err),
        label: 'FETCH_MANAGERSINGLETEAM_DATA',
    });
};
