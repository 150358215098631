/**
* TapManagerSingleTeamFilterReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const TapManagerSingleTeamFilterReducer = (state = {}, action) => {
    if(action.type === 'POST_FILTER_MANAGER_SINGLETEAM_MEMBER_DATA_BYWWID'){
        return {
            ...state,
            managerSingleTeamFilterByWWID: action.payload,
        };
      }
      else{
        return state;
      }
};
