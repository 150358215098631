import { Modal, IconButton, Box, Typography, TextField, Button } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { RedTheme } from "common/global";
import { useScreen } from "common/hooks/useScreen";

export const UserInputPopup = ({
    open,
    handleClose,
    popupHeader,
    inputFieldHeader,
    placeholder,
    leftBtnText,
    rightBtnText,
    AddFavoriteFilter,
    favName,
    saveFavHandler,
    CharLimit,
    favUserInputValidation
}) => {
    let redtheme = RedTheme;
    const screen = useScreen(); 

    return (
        <>
            <Modal open={open}>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: redtheme.palette.box.white,
                        padding: screen.isMobile? '12px':'24px',
                        gap: '36px',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        justifyContent: 'flex-end',
                        width: screen.isMobile? '95vw':'420px',
                        height: screen.isMobile? '220px':'276px',
                        borderRadius: '16px'
                    }}
                >
                    <IconButton
                        className='btn-close-popup'
                        sx={{
                            width: '20.01px',
                            height: '20px',
                            position: 'absolute',
                            top: '24px',
                            right: '24px',
                        }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Box sx={{ width: screen.isMobile? '330px':'372px', height: '96px' }}>
                        <Typography
                            component='h4'
                            variant='h4'
                            sx={{
                                fontSize: '31px',
                                lineHeight: '32px',
                                fontWeight: 700,
                                marginTop: '53px',
                                fontFamily: '"JohnsonDisplay-Bold"',
                                width: screen.isMobile? '330px':'370px',
                            }}
                        >
                            {popupHeader}
                        </Typography>
                        <TextField
                            onChange={(event) => AddFavoriteFilter(event.target.value)}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{ maxLength: 100 }}
                            placeholder={placeholder}
                            variant='standard'
                            sx={{
                                width: screen.isMobile? '330px':'372px',
                                height: '56px',
                                marginTop: '5px',
                            }}
                            label={
                                <>
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            lineHeight: '12px',
                                            fontWeight: 500,
                                            fontFamily: '"JohnsonText-Bold"',
                                            color: favUserInputValidation ? 'red' : redtheme.palette.text.primary,
                                            width: '322px',
                                            height: '12px',
                                            display: 'inline'
                                        }}
                                    >
                                      {inputFieldHeader}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontSize: '14px',
                                            lineHeight: '12px',
                                            fontWeight: 500,
                                            fontFamily: '"JohnsonText-Bold"',
                                            color: redtheme.palette.fontColor.gray,
                                            width: '322px',
                                            height: '12px',
                                            display: 'inline',
                                            fontStyle: 'italic',
                                            paddingLeft: favUserInputValidation ? '60px' : screen.isMobile ? '190px':'250px',
                                        }}
                                    >
                                        {CharLimit}
                                    </Typography>
                                </>
                            }
                        />
                    </Box>
                    <Box sx={{ width: screen.isMobile? '330px':'372px', height: '40px', gap: '8px' }}>
                        <Button
                            onClick={handleClose}
                            sx={{
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: '16px',
                                width: screen.isMobile? '161px':'182px',
                                height: screen.isMobile? '30px':'40px',
                                borderRadius: '8px',
                                fontFamily: '"JohnsonText-Medium"',
                                textTransform: 'none',
                            }}
                        >
                            {leftBtnText}
                        </Button>
                        <Button
                            onClick={() => { saveFavHandler(favName); handleClose() }}
                            disabled={favUserInputValidation}
                            sx={{
                                fontWeight: 500,
                                fontSize: '16px',
                                lineHeight: '16px',
                                width: screen.isMobile? '161px':'182px',
                                height: screen.isMobile? '30px':'40px',
                                borderRadius: '8px',
                                fontFamily: '"JohnsonText-Medium"',
                                textTransform: 'none',
                                 color: favUserInputValidation ? redtheme.palette.button.gray
                                :redtheme.palette.button.white,
                                bgcolor: favUserInputValidation ? 'transparent' : redtheme.palette.primary.main,
                                '&:hover': {
                                    color: redtheme.palette.button.white,
                                    bgcolor: redtheme.palette.primary.main,
                                    fontFamily: '"JohnsonText-Medium"',
                                }
                            }}
                            variant="primary"
                        >
                            {rightBtnText}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </>
    )
}