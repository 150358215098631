import apiAction from 'common/redux/actions/apiAction';
import { POST_ADMIN_VIEW_POPUP_DATA } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

/****
 * POST POPUP LISTING DETAILS
 ****/
const getPopUpDetailsData = data => {
  return {
    type: 'GET_POPUP_LISTING_DATA',
    payload: data,
  };
};

export const AdminPopupDetailsData = configParams => {
  const url = POST_ADMIN_VIEW_POPUP_DATA(configParams.urlStatus);
  return apiAction.postAPI({
    url,
    data: configParams,
    onSuccess: getPopUpDetailsData,
    onFailure: err => logError('Error occured in fetching dashboard data:', err),
    label: 'FETCH_ADMINVIEWLISTING_DATA',
  });
};

