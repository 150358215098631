/**
* PopUpReminderReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const PopUpReminderReducer = (state = {}, action) => {
  if(action.type === 'GET_POPUP_REMINDER'){
    return {
      ...state,
      popupReminder: action.payload,
    }
  }
  else{
    return state;
  }
};
