import apiAction from 'common/redux/actions/apiAction';
import { POST_ADMIN_MANAGE_FILTER } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

const logInUserDetails =(JSON.parse(localStorage.getItem('userDetails')));
const loggedInUserId = logInUserDetails && logInUserDetails.user_id ?  logInUserDetails.user_id : '';

const getAdminManageFilterList = data => {
  return {
    type: 'GET_MANAGE_FAVOURITE_FILTER_LIST',
    payload: data,
  };
 
};

export const AdminManageFilterListAction = configParams => {
  return apiAction.postAPI({
    url: POST_ADMIN_MANAGE_FILTER()+loggedInUserId,
    data: configParams,
    onSuccess: getAdminManageFilterList,
    onFailure: err => logError('Error occured in fetching Admin Favourite Filter data:', err),
    label: 'FETCH_ADMIN_MANAGE_FILTER',
  });
};

const getAdminManageFilterLoad = data => {
  return {
    type: 'GET_MANAGE_FAVOURITE_FILTER_LOAD',
    payload: data,
  };
};

export const AdminManageFilterLoadAction = configParams => {
  return apiAction.postAPI({
    url: POST_ADMIN_MANAGE_FILTER()+loggedInUserId,
    data: configParams,
    onSuccess: getAdminManageFilterLoad,
    onFailure: err => logError('Error occured in fetching Admin Favourite Filter data:', err),
    label: 'FETCH_ADMIN_MANAGE_FILTER',
  });
};

const getAdminManageFilterDelete = data => {
  return {
    type: 'GET_MANAGE_FAVOURITE_FILTER_DELETE',
    payload: data,
  };
};

export const AdminManageFilterDeleteAction = configParams => {
  return apiAction.postAPI({
    url: POST_ADMIN_MANAGE_FILTER()+loggedInUserId,
    data: configParams,
    onSuccess: getAdminManageFilterDelete,
    onFailure: err => logError('Error occured in fetching Admin Favourite Filter data:', err),
    label: 'FETCH_ADMIN_MANAGE_FILTER',
  });
};
