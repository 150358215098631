import React, { useEffect } from 'react';
import {
  Route,
  Routes,
  Outlet
} from 'react-router-dom';
import {
  HomeRootLayoutView,
  MyPerformance,
  MyOrgPerformance,
  TrainingView
} from 'TAP/pages';
import {
  LogoutView,
  Error,
  // UserNotFound,
} from 'common/pages';
import { NotFound } from 'common/navigation';
import { RoleMatrix } from 'TAP/global';
import 'TAP/assets/style.css'
import { logError } from 'TAP/utils/commonMethods';
import { AdminView } from 'TAP/pages/adminView';
import {CustomErrorPage} from '../../common/pages/customErrorPage'
/**
* TAP Router Config.
*/
export const TAPRouterConfig = () => {
  let userDetailsData = null;
  /**
  * Get User Details Data.
  */
  userDetailsData = RoleMatrix();
  useEffect(() => {
    if (userDetailsData && Object.keys(userDetailsData).length) {
      logError('getUserDetailsApi','getUserDetailsApi');
    }
  }, [userDetailsData]);

  return (userDetailsData && Object.keys(userDetailsData).length) ? (
    <>
      <Routes>
        <Route path='/' element={<HomeRootLayoutView />}>
          <Route index='true' element={<MyPerformance />} />
          <Route path='/myorganisation' element={userDetailsData?.user_role != 1 ? <MyOrgPerformance /> : <NotFound />} />
          <Route path='/trainingview' element={<TrainingView />} />
          <Route path='/adminview' element={<AdminView />} />
          <Route path='*' element={<NotFound />} />
        </Route>
        <Route path='error' element={<Error />} />
        <Route path='unauthorized' element={<CustomErrorPage />} />
        <Route path='logout' element={<LogoutView />}></Route>
      </Routes>

      <Outlet />
    </>
  ) : ''
};
