export const MyOrgComplianceActionsReducer = (state = {}, action) => {
  switch (action.type) {
    case 'GET_NON_COMPLIANT_DATA':
      return {
        ...state,
        nonCompliantData: action.payload,
      };
    case 'GET_AT_RISK_DATA':
      return {
        ...state,
        atRiskData: action.payload,
      };

    case 'API_START':
      if (action.payload === 'FETCH_NON_COMPLIANT_DATA') {
        return {
          ...state,
          isLoadingNonCompliantData: true,
        };
      } else if (action.payload === 'FETCH_AT_RISK_DATA') {
        return {
          ...state,
          isLoadingAtRiskData: true,
        };
      } else {
        return state;
      }

    case 'API_END':
      if (action.payload === 'FETCH_NON_COMPLIANT_DATA') {
        return {
          ...state,
          isLoadingNonCompliantData: false,
        };
      } else if (action.payload === 'FETCH_AT_RISK_DATA') {
        return {
          ...state,
          isLoadingAtRiskData: false,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};
