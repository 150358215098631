
import apiAction from 'common/redux/actions/apiAction';
import {
    POST_ADMINVIEW_TRAINING_OVER_DUE_URL,
    POST_ADMINVIEW_TRAINING_UPCOMMING_DUE_URL,
} from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

const getAdminOverdueData = data => {
  return {
    type: 'GET_ADMIN_OVERDUE_DATA',
    payload: data,
  };
};

export const AdminOverdueData = configParams => {
  return apiAction.postAPI({
    url: POST_ADMINVIEW_TRAINING_OVER_DUE_URL(),
    data: configParams,
    onSuccess: getAdminOverdueData,
    onFailure: err => logError('Error occured in fetching dashboard data.:', err),
    label: 'FETCH_ADMINOVERDUE_DATA',
  });
};
const getAdminUpcomingOverdueData = data => {
  return {
    type: 'GET_ADMIN_UPCOMINGDUE_DATA',
    payload: data,
  };
};

export const AdminUpcomingOverdueData = configParams => {
  return apiAction.postAPI({
    url: POST_ADMINVIEW_TRAINING_UPCOMMING_DUE_URL(),
    data: configParams,
    onSuccess: getAdminUpcomingOverdueData,
    onFailure: err => logError('Error occured in fetching dashboard data.:', err),
    label: 'FETCH_ADMINUPCOMINGDUE_DATA',
  });
};
