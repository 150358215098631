/**
* FilterPopUpDetailsReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const FilterPopUpDetailsReducer = (state = {}, action) => {
  if(action.type === 'GET_POPUP_FILTER_DATA'){
    return {
      ...state,
      popupFilterData: action.payload,
    }
  }
  else{
    return state;
  }
};
