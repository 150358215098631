import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { UserMenu } from 'common/navigation';
import { NavigationMenu } from './navigationMenu';
import TAPLogo from 'TAP/assets/images/Logo-TAP.svg';
import DTH_Logo from 'common/assets/images/DTHLogo.png';
import TAP_LOGO from 'common/assets/images/TAPLogo.svg';
import {  RedTheme} from 'common/global';
import { useScreen } from 'common/hooks/useScreen';

export const Header = ({
  isNavigationMenuVisible = true
}) => {
  const URLarray = window.location.href.split('/');
  const linktoTAP = process.env.REACT_APP_REDIRECT_URI + '/TAP';
  let redtheme = RedTheme;
  const [isDTHLogoVisible, setIsDTHLogoVisible] = useState(false);
  const screen = useScreen(); 
  useEffect(() => {
    setIsDTHLogoVisible(URLarray[URLarray.length - 1] === 'unauthorized');
  }, [URLarray]);
  return (
    <>
      {/** Sticky header appbar */}
      <AppBar
        className='tap-appbar-header1'
        position='sticky'
        sx={{
          bgcolor:  redtheme.palette.appBar.white ,
          margin: '8px',
          width: 'auto',
          paddingBottom: '0px',
          borderRadius: '8px',
          boxShadow: '0px 4px 26px 0px rgba(0, 0, 0, 0.16)',
        }}
      >
        {isNavigationMenuVisible && <Box className='tap-message-bar' sx={{
          background: redtheme.palette.type,
          height: '34px',
          color: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '16px',
          fontFamily: '"JohnsonDisplay-Bold"',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px'
        }}>TAP is for individual and management oversight. It must not be used for audit/inspection response.</Box>}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: {
              xs: '0px 10px',
              sm: '0px 10px',
              md: '0px 42px',
              lg: '0px 72px',
              xl: '0px 72px',
            },
            height: '64px',
            background: redtheme.palette.box.white,
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px'
          }}
        >
          {/** App Logo */}
          <Box sx={{
            position: 'relative',
            width: '50%',
            display: 'flex',
            alignItems: 'flex-start',
          }}
          >
            <Link href={linktoTAP} sx={{ lineHeight: 'initial' }}>
              {isDTHLogoVisible ? <img
                src={DTH_Logo}
                className='tap-logo'
                title='Training Analytics & Performance'
                alt='Training Analytics & Performance'
                height='42px'
                width='172px'
              /> : (screen.isMobile ? <img
                src={TAP_LOGO}
                className='tap-logo'
                title='Training Analytics & Performance'
                alt='Training Analytics & Performance'
                height='28%'
                width='32%'
              /> : <img
                src={TAPLogo}
                className='tap-logo'
                title='Training Analytics & Performance'
                alt='Training Analytics & Performance'
                height='48%'
                width='52%'
              /> 
              )}
            </Link>
          </Box>
          {/** Logged In Profile User */}
          <Box
            sx={{
              flexGrow: 0,
              width: '50%',
              display: 'flex',
              justifyContent: 'flex-end',
              gap:1,
              alignItems:'center'
            }}
          >
            <UserMenu></UserMenu>
          </Box>
        </Box>
        {/** Navigation menu */}
        {isNavigationMenuVisible && <NavigationMenu></NavigationMenu>}
      </AppBar>
    </>
  );
};
