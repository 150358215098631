import React from 'react';
import Box from '@mui/material/Box';
import { RedTheme } from 'common/global';
import { Link } from '@mui/material';
import Overall from '../assets/Overall Compliance Profile Criteria.pdf';
let redtheme = RedTheme;

export const ComplianceDonutChartTooltipBody = () => {
  return (
    <Box>
      <Box>
        Overall Compliance Profile is compliant and green when Completion % for past 3 years is ≥
        95, Completion on Time % for past 6 months is ≥95, SOE(CV) and JD are uploaded and Manager
        has reviewed SOE(CV)/JD and training plan within 2 years.
      </Box>
      <br />
      <Box>
        Click{' '}
        <Box
          component='span'
          sx={{
            color: redtheme.palette.primary.main,
            fontFamily: '"JohnsonText-Medium"',
            fontSize: '14px',
            fontWeight: 'bold',
          }}
        >
            <Link href={Overall} target = "_blank" color={redtheme.palette.primary.main} underline='none'>here</Link>
        </Box>{' '}
        for more details.
      </Box>
    </Box>
  );
};
