import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import DTH_Logo from 'common/assets/images/DTHLogo.png';
import { UserMenu } from 'common/navigation';
import {  RedTheme } from 'common/global';
import NavigationMenuMobile from 'common/navigation/NavigationMenuMobile';
import { useScreen } from 'common/hooks/useScreen';

export const Header = ({ logo, showNavigation }) => {
  let redtheme = RedTheme;
  const screen = useScreen();

  return (
    <>
      {/** Sticky header appbar */}
      <AppBar
        className='tap-appbar-header1'
        position='sticky'
        sx={{
          bgcolor: redtheme.palette.box.white,
          ...(screen.isMobile ? { boxShadow: 'none', padding: '8px', bgcolor: 'transparent' } : {}),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: {
              xs: '0px 10px',
              sm: '0px 10px',
              md: '0px 42px',
              lg: '0px 72px',
              xl: '0px 72px',
            },
            height: '64px',
            background: redtheme.palette.box.white,
            ...(screen.isMobile
              ? { borderRadius: '8px', boxShadow: '0 0 16px 0 rgba(0,0,0,0.25)' }
              : {}),
          }}
        >
          {/** App Logo */}
          <Box
            sx={{
              position: 'relative',
              width: '50%',
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            {logo || (
              <img
                src={DTH_Logo}
                className='dth-logo'
                title='DTH'
                alt='DTH'
                height='42px'
                width='172px'
              />
            )}
          </Box>
          {/** Logged In Profile User */}
          <Box
            sx={{
              flexGrow: 0,
              width: '50%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '10px',
            }}
          >
            <UserMenu></UserMenu>
            {showNavigation && <NavigationMenuMobile />}
          </Box>
        </Box>
      </AppBar>
    </>
  );
};
