import React from 'react';
import { DatePicker, ConfigProvider } from 'antd';
const { RangePicker } = DatePicker;
import { SelectedTheme , RedTheme } from 'common/global';
import { ReactComponent as JNJCalendarIcon } from 'common/assets/images/Calender.svg';
import { useScreen } from 'common/hooks/useScreen';
/**
 * DateRangePicker.
 * This component using RangePicker component to show range picker UI.
 * This component using ConfigProvider component.
 * Using selectedTheme and RedTheme constants from global configurations.
 * @param {func} onChange
 * @param {object} defaultValue
 * @returns {JSX.Element}
 */
export const DateRangePicker = ({ onChange, defaultValue, isAdmin = false, value, width = '0px' }) => {
  const screen = useScreen();
  const theme = SelectedTheme();
  let redtheme = RedTheme;
  /**
   * handleDateRangeChange.
   */
  const handleDateRangeChange = (_date, dateStrings) => {
    onChange(dateStrings, defaultValue);
  };
  /**
   * getCustomPopupContainer.
   */
  const getCustomPopupContainer = () => document.getElementById('custom-popup-container');
  /**
   * boldPlaceholderStyle.
   */
  const boldPlaceholderStyle = {
    fontWeight: 'bold',
  };
  /**
   * customStyle.
   */
  const customStyle = {
    ...(!screen.isTablet ? { width: width !=='0px' ? width : !isAdmin ? '250px' : screen.isMobile ? '300px' : '350px' } : {}),
    height: '40px',
    borderRadius: '1px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: `2px solid ${redtheme.palette.dateRangePicker.gray_orange}`,
    marginTop: '25px',
    padding: '4px 11px 4px',
    placeholder: [boldPlaceholderStyle, boldPlaceholderStyle],
  };

  const popupStyle = {
    position: 'absolute',
    zIndex: 9999,
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: `${theme.palette.primary.main}`,
            fontFamily: '"JohnsonText-Light"',
            fontSize: '16px',
            lineHeight: '16px',
            fontWeight: 400,
            colorTextPlaceholder: redtheme.palette.dateRangePicker.dark_grey,
            colorIcon: '0,0,0,0.88',
            paddigXXS: '0px',
            borderRadius: '16px',
          },
        }}
      >
        <div id='custom-popup-container'></div>
        <RangePicker
          onChange={handleDateRangeChange}
          getPopupContainer={getCustomPopupContainer}
          style={customStyle}
          placeholder={['dd.mm.yyyy', 'dd.mm.yyyy']}
          popupStyle={popupStyle}
          suffixIcon={<JNJCalendarIcon height='14' width='14' />}
          defaultValue={defaultValue}
          value={value}
          allowClear
        ></RangePicker>
      </ConfigProvider>
    </div>
  );
};
