import apiAction from "common/redux/actions/apiAction";
import { POST_MY_PERFORMANCE_COMPLIANCE_STATUS_DATA_URL } from "./apiPathConstants";
import { logError } from 'TAP/utils/commonMethods';
/****
 * Filter My Compliance Status Data 
 ****/
const getMyComplianceStatusSearchData = data => {
    return {
        type: 'GET_MY_COMPLIANCE_STATUS_SEARCH_DATA',
        payload: data
    };
};

export const SearchMyComplianceStatusData = configParams => {
    return apiAction.postAPI({
        url: POST_MY_PERFORMANCE_COMPLIANCE_STATUS_DATA_URL(),
        data: configParams,
        onSuccess: getMyComplianceStatusSearchData,
        onFailure: err => logError('Error occured in SearchMyComplianceStatusData:', err),
        label: 'SEARCH_MY_COMPLIANCE_STATUS_DATA'
    });
}; 