export const AdminPopUpDetailsReducer = (state = {}, action) => {
    
    if(action.type==='GET_POPUP_LISTING_DATA') {
      return {
        ...state,
        popupDetailsData: action.payload,
      };
    }
    else {
      return state;
    }
};
