import { Button } from "@mui/material"
import { RedTheme } from 'common/global';
import { ReactComponent as Download } from 'common/assets/images/New_Downld.svg';
import { useScreen } from "common/hooks/useScreen";

export const CustomButton = ({
  width,
  buttonText,
  clickHandler,
  isIconToShow = false
}) => {
  const screen = useScreen(); 
  let redtheme = RedTheme;
  return (
    <Button
      data-testid='requestToAddOrRemoveTrainingBtn'
      onClick={clickHandler}
      sx={{
        paddingTop: '7px',
        width: { width },
        height: '32px',
        borderRadius: '8px',
        marginLeft: screen.resolveStyles({
          mobile : '0px',
          tablet : '10px',
          desktop : '20px',
        }),
        marginTop: '15px',
        color: redtheme.palette.button.white,
        bgcolor: redtheme.palette.primary.main,
        textTransform: 'none',
        fontWeight: screen.isMobile ? 300:500,
        fontSize: screen.isMobile ? '14px':'16px',
        lineHeight: screen.isMobile ? '14px':'16px',
        fontFamily: '"JohnsonText-Medium"',
        '&:hover': {
          backgroundColor: redtheme.palette.primary.main,
          color: redtheme.palette.button.white,
          fontFamily: '"JohnsonText-Medium"',
        },
        '&:focus': { outline: 'none' },
      }}
    >
      {buttonText}
      {isIconToShow ? <Download /> : null}
    </Button>
  )
}