import apiAction from 'common/redux/actions/apiAction';
import { POST_TRAININGPLANVIEW_DIRECTREPORTEE_MRGSINGLETEAM_URL } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';


/****
 * POST TRAINING PLAN VIEW MANAGER SINGLE TEAM FILTER DETAILS
 ****/

//Filter by WWID
const getTPVFilterManagerSingleTeamDataByWWID = data => {
    return {
        type: 'POST_TPV_FILTER_MRNG_SNGLTEAM_MEMBER_DATA_BYWWID',
        payload: data,
    };
};

export const TPVFilterManagerSingleTeamDataWWID = configParams => {
    return apiAction.postAPI({
        url: POST_TRAININGPLANVIEW_DIRECTREPORTEE_MRGSINGLETEAM_URL(),
        data: configParams,
        onSuccess: getTPVFilterManagerSingleTeamDataByWWID,
        onFailure: err => logError('Error occured in fetching dashboard data.:', err),
        label: 'FETCH_TPV_FILTER_MRNG_SNGLTEAM_MEMBER_DATA_BYWWIDD',
    });
};

//Filter by Curriculum Title
const getTPVFilterManagerSingleTeamDataByCurriculumTitle = data => {
    return {
        type: 'POST_TPV_FILTER_MRNG_SNGLTEAM_MEMBER_DATA_BYCURRICULUM',
        payload: data,
    };
};

export const TPVFilterManagerSingleTeamDataCurriculumTitle = configParams => {
    return apiAction.postAPI({
        url: POST_TRAININGPLANVIEW_DIRECTREPORTEE_MRGSINGLETEAM_URL(),
        data: configParams,
        onSuccess: getTPVFilterManagerSingleTeamDataByCurriculumTitle,
        onFailure: err => logError('Error occured in fetching dashboard data.:', err),
        label: 'FETCH_TPV_FILTER_MRNG_SNGLTEAM_MEMBER_DATA_BYCURRICULUM',
    });
};

//Filter by Training Title
const getTPVFilterManagerSingleTeamDataByTrainingTitle = data => {
    return {
        type: 'POST_TPV_FILTER_MRNG_SNGLTEAM_MEMBER_DATA_BYTRAINING',
        payload: data,
    };
};

export const TPVFilterManagerSingleTeamDataTrainingTitle = configParams => {
    return apiAction.postAPI({
        url: POST_TRAININGPLANVIEW_DIRECTREPORTEE_MRGSINGLETEAM_URL(),
        data: configParams,
        onSuccess: getTPVFilterManagerSingleTeamDataByTrainingTitle,
        onFailure: err => logError('Error occured in fetching dashboard data.:', err),
        label: 'FETCH_TPV_FILTER_MRNG_SNGLTEAM_MEMBER_DATA_BYTRAINING',
    });
};

