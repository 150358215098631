import React, { useState } from 'react';
import { useMsal } from '@azure/msal-react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';
import { UserDetail, UserProfileImage } from 'common/components';
import { ListItemIcon } from '@mui/material';
import { useScreen } from 'common/hooks/useScreen';

/**
 * UserMenu.
 * Use UserProfileImage Component
 * This component using Box components for layour design
 * This component using Typography for text styling
 * This component using Tooltip Component to show tooltip on hover/click
 * This component using Menu Component to navigate user on specific screen
 * This component using MenuItem Component to show menu items
 * This component using IconButton Component to show Icon type button
 * @returns {JSX.Element}
 */
export const UserMenu = () => {
  let { instance } = useMsal();
  const { isMobile } = useScreen();

  /**
   * Handle Logout User.
   */
  const handlelogoutUser = () => {
    localStorage.clear();
    instance.logout();
  };

  const [anchorElUser, setAnchorElUser] = useState(null);

  /**
   * Handle Open user Menu.
   */
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  /**
   * Handle Close User Menu.
   */
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 0,
          display: 'flex',
        }}
      >
        <Typography
          variant='h6'
          noWrap
          component='a'
          sx={{
            mr: 1,
            width: '100%',
            height: '18px',
            fontFamily: '"JohnsonText-Light"',
            fontStyle: 'normal',
            fontWeight: isMobile ? '400' : '700',
            fontSize: '16px',
            lineHeight: isMobile ? '16px' : '18px',
            color: '#212121',
            textDecoration: 'none',
            alignSelf: 'center',
            textAlign: 'end',
          }}
        >
          Hi {UserDetail()?.userFirstName} {UserDetail()?.userLastName}
        </Typography>
        <Tooltip>
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <UserProfileImage></UserProfileImage>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorElUser}
          id='account-menu'
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
          onClick={handleCloseUserMenu}
          disableScrollLock={true}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handlelogoutUser}>
            <ListItemIcon>
              <Logout fontSize='small' />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </>
  );
};
