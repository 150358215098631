import React from 'react';
import { Box, Typography } from '@mui/material';
import clsx from 'clsx';
import { ToolTip } from 'common/components/toolTip';
import {RedTheme } from 'common/global';

export const ManagerSingleTeamColumnConfig = status => {
  let redtheme = RedTheme;

  const statusPopUpColumns = [
    {
      status: 'training_completed_on_time_percent',
      columnArray: [
        {
          field: 'training_title',
          flex: 1,
          minWidth: 250,
          disableColumnMenu: true,
          renderHeader: () => (
            <Box>
              <Typography
                sx={{
                  fontFamily: '"JohnsonText-Bold"',
                  fontsize: '16px',
                  fontWeight: 700,
                  lineHeight: '18px',
                }}
              >
                Training Name
              </Typography>
            </Box>
          ),
          renderCell: params => {
            const training_title = <Typography sx={{fontFamily: '"JohnsonText-Regular"'}}>{params.row?.training_title}</Typography>;

            return (
              <Box display='flex' flexDirection='column'>
                <ToolTip
                  title={training_title}
                  placement='top'
                  header='Training Name'
                  noOfLetters={45}
                >
                  <Typography
                    variant='body2'
                    sx={{
                      color: redtheme.palette.fontColor.gray ,
                      fontFamily: '"JohnsonText-Regular"',
                      fontSize: '16px',
                      lineHeight: '18px',
                      fontWeight: 450,
                    }}
                  >
                    {training_title}
                  </Typography>
                </ToolTip>
              </Box>
            );
          },
        },
        {
          field: 'transcript_completed_date',
          width: 260,
          disableColumnMenu: true,
          renderHeader: () => (
            <Box>
              <Typography
                sx={{
                  fontFamily: '"JohnsonText-Bold"',
                  fontsize: '16px',
                  fontWeight: 700,
                  lineHeight: '18px',
                }}
              >
                Completion Date
              </Typography>

            </Box>
          ),
          renderCell: params => {
            return (
              <Box display='flex' flexDirection='column'>
                <Box
                  sx={{
                    color: redtheme.palette.box.black_gray ,
                    fontFamily: '"JohnsonText-Regular"',
                    fontSize: '16px',
                    lineHeight: '18px',
                    fontWeight: 450,
                  }}
                >
                  {params.row?.transcript_completed_date}
                </Box>
              </Box>
            );
          },
        },
        {
          field: 'transcript_due_date',
          width: 170,
          disableColumnMenu: true,
          renderHeader: () => (
            <Box>
              <Typography
                sx={{
                  fontFamily: '"JohnsonText-Bold"',
                  fontsize: '16px',
                  fontWeight: 700,
                  lineHeight: '18px',
                }}
              >
                Due Date
              </Typography>
              <Typography
                sx={{
                  fontFamily: '"JohnsonText-Bold"',
                  fontsize: '16px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  color: redtheme.palette.fontColor.gray 
                }}
              >
                Days Overdue
              </Typography>
            </Box>
          ),
          cellClassName: params => {
            return clsx('super-app', {
              incompleteoverdue: params.field.toLowerCase() === 'training_completed_on_time_percent',
            });
          },
          renderCell: params => {
            return (
              <Box display='flex' flexDirection='column'>
                <Box
                  sx={{
                    color: redtheme.palette.box.black_gray ,
                    fontFamily: '"JohnsonText-Regular"',
                    fontSize: '16px',
                    lineHeight: '18px',
                    fontWeight: 450,
                  }}
                >
                  {params.row?.transcript_due_date}
                </Box>
                <Box
                  sx={{
                    color: params.row?.days_overdue === 0 ? redtheme.palette.fontColor.gray : redtheme.palette.box.red,
                    fontFamily: '"JohnsonText-Regular"',
                    fontSize: '16px',
                    lineHeight: '18px',
                    fontWeight: 450,
                  }}
                >
                  <span>{params.row?.days_overdue} Days Overdue</span>
                </Box>
              </Box>
            );
          },
        },
      ],
    },
    {
      status: 'training_completed_overall_percent',
      columnArray: [
        {
          field: 'training_title',
          flex: 1,
          minWidth: 250,
          disableColumnMenu: true,
          renderHeader: () => (
            <Box>
              <Typography
                sx={{
                  fontFamily: '"JohnsonText-Bold"',
                  fontsize: '16px',
                  fontWeight: 700,
                  lineHeight: '18px',
                }}
              >
                Training Name
              </Typography>
            </Box>
          ),
          renderCell: params => {
            const training_title = <Typography sx={{fontFamily: '"JohnsonText-Regular"'}}>{params.row?.training_title}</Typography>;
            return (
              <Box display='flex' flexDirection='column'>
                <ToolTip
                  title={training_title}
                  placement='top'
                  header='Training Name'
                  noOfLetters={45}
                >
                  <Typography
                    variant='body2'
                    sx={{
                      color:redtheme.palette.fontColor.gray ,
                      fontFamily: '"JohnsonText-Regular"',
                      fontSize: '16px',
                      lineHeight: '18px',
                      fontWeight: 450,
                    }}
                  >
                    {training_title}
                  </Typography>
                </ToolTip>
              </Box>
            );
          },
        },

        {
          field: 'transcript_completed_date',
          width: 260,
          disableColumnMenu: true,
          renderHeader: () => (
            <Box>
              <Typography
                sx={{
                  fontFamily: '"JohnsonText-Bold"',
                  fontsize: '16px',
                  fontWeight: 700,
                  lineHeight: '18px',
                }}
              >
                Completion Date
              </Typography>

            </Box>
          ),
          renderCell: params => {
            return (
              <Box display='flex' flexDirection='column'>
                <Box
                  sx={{
                    color: redtheme.palette.box.black_gray ,
                    fontFamily: '"JohnsonText-Regular"',
                    fontSize: '16px',
                    lineHeight: '18px',
                    fontWeight: 450,
                  }}
                >
                  {params.row?.transcript_completed_date}
                </Box>
              </Box>
            );
          },
        },

        {
          field: 'transcript_due_date',
          width: 170,
          disableColumnMenu: true,
          renderHeader: () => (
            <Box>
              <Typography
                sx={{
                  fontFamily: '"JohnsonText-Bold"',
                  fontsize: '16px',
                  fontWeight: 700,
                  lineHeight: '18px',
                }}
              >
                Due Date
              </Typography>
              <Typography
                sx={{
                  fontFamily: '"JohnsonText-Bold"',
                  fontsize: '16px',
                  fontWeight: 700,
                  lineHeight: '18px',
                  color: redtheme.palette.fontColor.gray 
                }}
              >
                Days Overdue
              </Typography>
            </Box>
          ),
          cellClassName: params => {
            return clsx('super-app', {
              incompleteoverdue: params.field.toLowerCase() === 'training_completed_on_time_percent',
            });
          },
          renderCell: params => {
            return (
              <Box display='flex' flexDirection='column'>
                <Box
                  sx={{
                    color: redtheme.palette.box.black_gray ,
                    fontFamily: '"JohnsonText-Regular"',
                    fontSize: '16px',
                    lineHeight: '18px',
                    fontWeight: 450,
                  }}
                >
                  {params.row?.transcript_due_date}
                </Box>
                <Box
                  sx={{
                    color: params.row?.days_overdue === 0 ? redtheme.palette.fontColor.gray : redtheme.palette.box.red,
                    fontFamily: '"JohnsonText-Regular"',
                    fontSize: '16px',
                    lineHeight: '18px',
                    fontWeight: 450,
                  }}
                >
                  <span>{params.row?.days_overdue} Days Overdue</span>
                </Box>
              </Box>
            );
          },
        },
      ],
    },
  ];
  return statusPopUpColumns.filter(item => item.status === status);
};
