/**
* getApiAction.
* This method is use to call get API
* @param {string} url
* @param {string} method
* @param {any} data
* @param {string} accessToken
* @param {func} onSuccess
* @param {func} onFailure
* @param {string} label
* @param {object} headersOverride
* @returns {object}
*/

const getApiAction = function ({ //NOSONAR
  url = '',
  method = 'GET',
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = '',
  headersOverride = null,
}) {
  const payload = {url, method, data, accessToken, onSuccess, onFailure, label, headersOverride}
  return apiResponse(payload);
};

/**
* postApiAction.
* This method is use to call POST API
* @param {string} url
* @param {string} method
* @param {any} data
* @param {string} accessToken
* @param {func} onSuccess
* @param {func} onFailure
* @param {string} label
* @param {object} headersOverride
* @returns {object}
*/
const postApiAction = function ({ //NOSONAR
  url = '',
  method = 'POST',
  data = null,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = '',
  headersOverride = null,
}) {
  const payload = {url, method, data, accessToken, onSuccess, onFailure, label, headersOverride}
  return apiResponse(payload);
};

/**
* updateApiAction.
* This method is use to call PUT API
* @param {string} url
* @param {string} method
* @param {any} data
* @param {string} accessToken
* @param {func} onSuccess
* @param {func} onFailure
* @param {string} label
* @param {object} headersOverride
* @returns {object}
*/
const updateApiAction = function ({ //NOSONAR
  url = '',
  method = '',
  // eslint-disable-next-line no-use-before-define
  data = data,
  accessToken = null,
  onSuccess = () => {},
  onFailure = () => {},
  label = '',
  headersOverride = null,
}) {
  const payload = {url, method, data, accessToken, onSuccess, onFailure, label, headersOverride}
  return apiResponse(payload);
};

const apiAction = {
  getAPI: getApiAction,
  postAPI: postApiAction,
  updateAPI: updateApiAction,
};

export default apiAction;

function apiResponse(payload) {
  return {
    type: 'API',
    payload,
  };
}

