export const AdminViewCompleteStatReducer = (state = {}, action) => {

    if(action.type==='POST_ADMINVIEW_COMPLETED_STATUS_DETAILS') {
        return {
            ...state,
            adminViewCompleteStatusAPIResponse: action.payload
        }
    }
    else {
        return state;
    }

};
