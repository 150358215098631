import apiAction from 'common/redux/actions/apiAction';
import {
  POST_ORG_PERFORMANCE_NON_COMPLIANT_URL,
  POST_ORG_PERFORMANCE_AT_RISK_URL,
} from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

const getNonCompliantData = data => {
  return {
    type: 'GET_NON_COMPLIANT_DATA',
    payload: data,
  };
};

export const NonCompliantData = configParams => {
  return apiAction.postAPI({
    url: POST_ORG_PERFORMANCE_NON_COMPLIANT_URL(),
    data: configParams,
    onSuccess: getNonCompliantData,
    onFailure: err => logError('Error occured in fetching dashboard data.:', err),
    label: 'FETCH_NON_COMPLIANT_DATA',
  });
};
const getAtRiskData = data => {
  return {
    type: 'GET_AT_RISK_DATA',
    payload: data,
  };
};

export const AtRiskData = configParams => {
  return apiAction.postAPI({
    url: POST_ORG_PERFORMANCE_AT_RISK_URL(),
    data: configParams,
    onSuccess: getAtRiskData,
    onFailure: err => logError('Error occured in fetching dashboard data.:', err),
    label: 'FETCH_AT_RISK_DATA',
  });
};
