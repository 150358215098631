import { createContext, useState, useEffect, useCallback } from 'react';
import { NUMBER_ARRAY } from 'common/global/constants';

export const MediaContext = createContext({
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  resolveStyles: () => {},
});

const getScreenData = ({ mobileLimit, tabletLimit }) => {
  const clientWidth = document.documentElement.clientWidth;
  return {
    isMobile: clientWidth <= mobileLimit,
    isTablet: clientWidth > mobileLimit && clientWidth <= tabletLimit,
    isDesktop: clientWidth > tabletLimit,
  };
};

export const MediaProvider = ({
  children,
  config = {
    mobileLimit: NUMBER_ARRAY.fourHundredThirty,
    tabletLimit: NUMBER_ARRAY.oneThousandTwentyFour,
  },
}) => {
  const [screenState, setScreenState] = useState(getScreenData(config));

  useEffect(() => {
    window.addEventListener('resize', updateScreenState, false);
    window.addEventListener('load', updateScreenState, false);

    return () => {
      window.removeEventListener('resize', updateScreenState, false);
      window.removeEventListener('load', updateScreenState, false);
    };
  }, []);

  const updateScreenState = () => setScreenState(getScreenData(config));

  const resolveStyles = useCallback(
    (styles, defaultValue = 'desktop') => {
      const { isMobile, isTablet, isDesktop } = screenState;
      if (isMobile) return styles.mobile || styles[defaultValue];
      if (isTablet) return styles.tablet || styles[defaultValue];
      if (isDesktop) return styles.desktop || styles[defaultValue];
    },
    [screenState],
  );

  return (
    <MediaContext.Provider value={{ ...screenState, resolveStyles }}>
      {children}
    </MediaContext.Provider>
  );
};
