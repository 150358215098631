import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { ComplianceDonutChart, BarChartComponent, BarChart, DonutChart } from 'common/components';
import { DonutChartStatistics } from 'common/components/DonutChartStatistics';
import { RedTheme } from 'common/global';
import { percent } from 'TAP/styles/genericStyles/percentage';
import { BarChartFilter } from '../../common/components/barChartFilter';
import { NUMBER_ARRAY } from 'common/global/constants';
import { Disclaimer } from './Disclaimer';
import { useScreen } from 'common/hooks/useScreen';

// Empty Data for compliance bar chart
const barChartData = [
  { label: 'Job Description', nonCompliantPercent: 0, compliantPercent: 0, atRiskPercent: 0 },
  {
    label: 'Summary of Experience',
    nonCompliantPercent: 0,
    compliantPercent: 0,
    atRiskPercent: 0,
  },
  {
    label: 'Individual Training Plan',
    nonCompliantPercent: 0,
    compliantPercent: 0,
    atRiskPercent: 0,
  },
  {
    label: 'Training Completed On Time',
    nonCompliantPercent: 0,
    compliantPercent: 0,
    atRiskPercent: 0,
  },
  {
    label: 'Training Completed Overall',
    nonCompliantPercent: 0,
    compliantPercent: 0,
    atRiskPercent: 0,
  },
];

//Empty data for compliance Donut Chart
const donutChartData = [
  { label: 'Compliance', value: 0, color: 'green' },
  { label: 'At Risk', value: 0, color: 'yellow' },
  { label: 'Non-Compliant', value: 0, color: 'red' },
];

export const GraphTile = ({
  tileHeaderText,
  myOrgCompletedData,
  donutLabelText,
  timeRangeState,
  donutId,
  barId,
  legends,
  filters,
  subHeader,
  tagline,
  margin,
  color,
  barChartColor,
  isMultiColorBarLine,
  isMultiColorDonut,
  isTaglineVisible,
  complianceChartData,
  onChangeFilterOuter,
  handleButtonClick,
  activeButton,
  DisclaimerToShow,
  disclaimerText,
}) => {
  const redtheme = RedTheme;
  const [complianceBarChartData, setComplianceBarChartData] = useState(barChartData);
  const [complianceDonutChartData, setComplianceDonutChartData] = useState(donutChartData);
  const [compliantProfileCount, setCompliantProfileCount] = useState(0);
  const [totalProfileCount, setTotalProfileCount] = useState(0);
  const [isDisclaimerToShow, setIsDisclaimerToShow] = useState(DisclaimerToShow);
  const screen = useScreen(); 

  /**
  * handleFilter.
  * The method gets called when the time filter is applied on the bar chart. 
  * It sends the selected button's details to the parent component.
  * @param {string} button
  * @param {string} timeRange
  */
  const handleFilter = (button, timeRange) => {
    onChangeFilterOuter(button, timeRange);
  }
  useEffect(() => {
    if (complianceChartData !== undefined && complianceChartData?.orgComplianceDetails !== undefined ) {
      setComplianceBarChartData([
        complianceChartData?.orgComplianceDetails?.data?.barChartData[0][0],
        complianceChartData?.orgComplianceDetails?.data?.barChartData[1][0],
        complianceChartData?.orgComplianceDetails?.data?.barChartData[NUMBER_ARRAY.two][0],
        complianceChartData?.orgComplianceDetails?.data?.barChartData[NUMBER_ARRAY.three][0],
        complianceChartData?.orgComplianceDetails?.data?.barChartData[NUMBER_ARRAY.four][0],
      ]);
      setComplianceDonutChartData([
        {
          label: 'Compliance',
          value:
            complianceChartData?.orgComplianceDetails?.data?.donutChartData[0]?.compliantPercent,
          color: 'green',
        },
        {
          label: 'At Risk',
          value: complianceChartData?.orgComplianceDetails?.data?.donutChartData[0]?.atRiskPercent,
          color: 'yellow',
        },
        {
          label: 'Non-Compliant',
          value:
            complianceChartData?.orgComplianceDetails?.data?.donutChartData[0]?.nonCompliantPercent,
          color: 'red',
        },
      ]);
      setCompliantProfileCount(
        complianceChartData?.orgComplianceDetails?.data?.donutChartData[0]?.totalCompliantProfiles,
      );
      setTotalProfileCount(
        complianceChartData?.orgComplianceDetails?.data?.donutChartData[0]?.totalProfiles,
      );
    } else {
      setComplianceDonutChartData(donutChartData);
      setComplianceBarChartData(barChartData);
    }
  }, [complianceChartData]);

  return (
    <Box
      id='barChart2'
      data-testid='barChart2'
      className='bar-chart'
      sx={{
        height: '580px',
        boxShadow: '0px 0px 8px 0px rgba(0, 0, 0, 0.16)',
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '16px',
      }}
    >
      <Stack
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          padding: screen.isMobile ? '12px' : '16px 32px',
          isolation: 'isolate',
        }}
      >
        <Stack>
          <Typography
            component='h4'
            variant='h4'
            sx={{
              fontSize: screen.resolveStyles({
                  mobile: '20px', 
                  tablet: '20px',
                  desktop: '25px',
                  }),
              lineHeight: screen.resolveStyles({
                  mobile: '24px', 
                  tablet: '24px',
                  desktop: '32px',
                  }),
              fontWeight: 700,
              marginRight: '26px',
              fontFamily: '"JohnsonDisplay-Bold"',
            }}
            data-testid='training-label'
          >
            {tileHeaderText}
          </Typography>
          {subHeader}

          {myOrgCompletedData &&
            Object.keys(myOrgCompletedData).length &&
            complianceChartData &&
            Object.keys(complianceChartData).length ? (
            <>
              <Stack direction='row' sx={{ marginTop: { margin }, marginLeft: '0', marginBottom: '0'}}>
                {isMultiColorDonut ? (
                  <Box sx={{ mr: '20px', mt: screen.resolveStyles({
              mobile: '0px',
              tablet: '35px',
              desktop: '35px',
            }), }}>
                    <ComplianceDonutChart
                      data={complianceDonutChartData}
                      colors={[
                        redtheme.palette.success.main,
                        redtheme.palette.warning.main,
                        redtheme.palette.error.dark,
                      ]}
                    />
                  </Box>
                ) : (
                  <DonutChart
                    id={donutId}
                    boxSize={700}
                    boxWidth={screen.resolveStyles({
                      tablet : '118px', 
                      mobile : '136px', 
                      desktop : '172px'
                    })}
                    timeRange={timeRangeState}
                    trainingState={tileHeaderText}
                    color={color}
                    data={myOrgCompletedData?.donutChartData[0]?.percentage}
                    tagLine={tagline}
                    isTaglineVisible={isTaglineVisible}
                  />
                )}
                <DonutChartStatistics
                  totalCount={
                    isMultiColorDonut
                      ? totalProfileCount
                      : myOrgCompletedData?.donutChartData[0]?.assigned_trainings
                  }
                  donutLabelText={donutLabelText}
                  count={
                    isMultiColorDonut
                      ? compliantProfileCount
                      : myOrgCompletedData?.donutChartData[0]?.completed_trainings
                  }
                />
              </Stack>
            </>
          ) : (
            <></>
          )}
        </Stack>
        {isDisclaimerToShow &&
          <Box
            sx={{
              width: '100%',
              height: screen.resolveStyles({
                desktop : '60px', 
                mobile : '70px', 
                tablet : '85px'
              }),
              borderRadius: '8px',
              bgcolor: redtheme.palette.warning.light,
            }}>
            <Disclaimer
              handleclose={() => setIsDisclaimerToShow(false)}
              spacing={0.75}
              innerWidth='100%'
              disclaimerText={disclaimerText}
            />
          </Box>}
        <Stack sx={{ display: 'flex', width: '100%' }}>
          {myOrgCompletedData &&
            Object.keys(myOrgCompletedData).length &&
            complianceBarChartData &&
            Object.keys(complianceBarChartData[0]).length ? (
            <Box>
              <BarChartFilter legends={legends} filters={filters} onChangeFilter={handleFilter} handleButtonClick={handleButtonClick} activeButton={activeButton}></BarChartFilter>
              {isMultiColorBarLine ? (
                <BarChartComponent
                  data={complianceBarChartData}
                  height={205}
                  // To Control Thickness of bar
                  padding={0.85}
                  yAxisTickValues={[
                    percent.pr0,
                    percent.pr25,
                    percent.pr50,
                    percent.pr75,
                    percent.pr100,
                  ]}
                  bottomBarColor={redtheme.palette.success.main}
                  middleBarColor={redtheme.palette.warning.main}
                  topBarColor={redtheme.palette.error.dark}
                />
              ) : (
                <BarChart
                  id={barId}
                  setValue={''}
                  width={530}
                  height={210}
                  data={myOrgCompletedData?.barChartData}
                  level={timeRangeState}
                  color={barChartColor}
                />
              )}
            </Box>
          ) : (
            <></>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};