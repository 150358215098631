import { styled } from '@mui/material/styles';
import { useState } from 'react';
import MuiButton from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { NUMBER_ARRAY } from 'common/global/constants';

export const Button = styled(MuiButton)(() => ({}));

export const ButtonsTab = () => {
  const [activeButton, setActiveButton] = useState(NUMBER_ARRAY.two);

  const handleClick = button => {
    if (activeButton !== button) {
      setActiveButton(button);
    } else {
      // Do nothing
    }
  };

  return (
    <Stack
      direction='row'
      spacing={NUMBER_ARRAY.two}
      sx={{
        border: '1px solid LightGray',
        padding: '5px',
        borderRadius: '10px',
        display: 'inline-block',
        margin: '5px',
      }}
    >
      <Button
        onClick={() => handleClick(NUMBER_ARRAY.one)}
        variant={activeButton === NUMBER_ARRAY.one ? 'contained' : 'text'}
        size='small'
        color='primary'
        data-testid='btn-yearly'
      >
        Yearly
      </Button>
      <Button
        onClick={() => handleClick(NUMBER_ARRAY.two)}
        variant={activeButton === NUMBER_ARRAY.two ? 'contained' : 'text'}
        size='small'
        color='primary'
        data-testid='btn-quarterly'
      >
        Quarterly
      </Button>
      <Button
        onClick={() => handleClick(NUMBER_ARRAY.three)}
        variant={activeButton === NUMBER_ARRAY.three ? 'contained' : 'text'}
        size='small'
        color='primary'
        data-testid='btn-monthly'
      >
        Monthly
      </Button>
    </Stack>
  );
};