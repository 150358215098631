import { Box, Button, IconButton, Modal, Typography } from "@mui/material"
import { RedTheme } from "common/global";
import CloseIcon from '@mui/icons-material/Close';
import { useScreen } from "common/hooks/useScreen";

export const TrainingExportPopup = ({
    open,
    handleClose,
    popupHeader,
    BtnText,
}) => {
    let redtheme = RedTheme;
    const screen = useScreen(); 

    return (
        <Modal open={open}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: redtheme.palette.box.white,
                    padding: screen.isMobile? '12px':'24px',
                    gap: '36px',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end',
                    width: screen.isMobile ? '95vw':'410px',
                    height: screen.isMobile? '255px':'280px',
                    borderRadius: '16px'
                }}
            >
                <IconButton
                    className='btn-close-popup'
                    sx={{
                        width: '20.01px',
                        height: '20px',
                        position: 'absolute',
                        top: '24px',
                        right: '24px',
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
                <Box sx={{ width: screen.isMobile? '330px':'370px', height: '96px' }}>
                    <Typography
                        component='h4'
                        variant='h4'
                        sx={{
                            fontSize: '20px',
                            lineHeight: '32px',
                            fontWeight: 700,
                            marginTop: '38px',
                            fontFamily: '"JohnsonDisplay-Bold"',
                            width: screen.isMobile? '330px':'370px',
                            color: redtheme.palette.error.main,
                        }}
                    >
                        {popupHeader}
                    </Typography>
                </Box>
                <Box sx={{ width: screen.isMobile? '330px':'372px', height: '40px', gap: '8px', marginTop: '48px' }}>
                    <Button
                        onClick={handleClose}
                        sx={{
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '16px',
                            width: screen.isMobile? '161px':'182px',
                            height: screen.isMobile? '30px':'40px',
                            borderRadius: '8px',
                            marginLeft: screen.isMobile? '25%':'95px',
                            fontFamily: '"JohnsonText-Medium"',
                            textTransform: 'none',
                            color: redtheme.palette.button.white,
                            bgcolor: redtheme.palette.primary.main,
                            '&:hover': {
                                color: redtheme.palette.button.white,
                                bgcolor: redtheme.palette.primary.main,
                                fontFamily: '"JohnsonText-Medium"',
                            }
                        }}
                        variant="primary"
                    >
                        {BtnText}
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}