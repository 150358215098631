import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { Header } from '../navigation';
import { Header as MobileHeader } from 'common/components';
import { Footer } from 'common/components/footer';
import { RedTheme } from 'common/global';
import { useScreen } from 'common/hooks/useScreen';
import TAP_LOGO from '../../common/assets/images/TAPLogo.svg';
import { NUMBER_ARRAY } from 'common/global/constants';

export const HomeRootLayoutView = () => {
  let redtheme = RedTheme;
  const screen = useScreen();

  return (
    <>
      <Box component='main' sx={{ flex: '1 0 auto' }}>
        {/** 12 Colum Grid defined for base layout */}
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(12, 1fr)',
            gap: 1,
            gridTemplateRows: 'auto',
            gridTemplateAreas: `"main main main main main main main main main main main main"`,
          }}
        >
          <Box
            className='tap-page-layout'
            sx={{ gridArea: 'main', background: redtheme.palette.box.light_grayish_orange }}
          >
            {/** Header */}
            {screen.isMobile ? (
              <MobileHeader showNavigation logo={<img src={TAP_LOGO} height='40' width='47' />} />
            ) : (
              <Header />
            )}
            <Box
              className='tap-content-area'
              sx={{
                width: '100%',
              }}
            >
              {/** ContentArea loads dynamic routes pages */}
              <Box
                className='dashboard'
                sx={{
                  p: screen.resolveStyles({
                    mobile: NUMBER_ARRAY.zero,
                    tablet: '12px 0 0 0',
                    desktop: '22px 0 0 0',
                  }),
                }}
              >
                <Outlet />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/** Footer */}
      <Box component='footer' sx={{ flexShrink: 0 }}>
        <Footer></Footer>
      </Box>
    </>
  );
};
