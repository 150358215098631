import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';
import {
    DataRefresh,
    // Error,
    LogoutView,
    StyleGuide,
    // UserNotFound
} from 'common/pages';

import { NotFound } from 'common/navigation';
import React from 'react';
import { TAPRouterConfig } from 'TAP/navigation';
import {CustomErrorPage} from '../../common/pages/customErrorPage'
import { labels } from 'common/global/constants';
import { useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import ReactGA from "react-ga4";
import { getGAMeasurementId, isTimeOfRunningCDCPipeline } from 'TAP/utils/commonMethods';
import { TapGlobalMessages } from "TAP/global"


export const RouterConfig = () => {
  let { instance } = useMsal();

    const handlelogoutUser = () => {
        localStorage.clear();
        instance.logout();
    };

    const navigateToDTH = () => {
        window.location = process.env.REACT_APP_REDIRECT_DTH
    };
  
  const isMaintenanceTime = isTimeOfRunningCDCPipeline();

  useEffect(()=>{
    const urlArray = window.location.href.split('/'); 
    if(urlArray[urlArray.length -1 ]==='unauthorized' && isMaintenanceTime) {
        ReactGA.initialize(`G-${getGAMeasurementId()}`);
        ReactGA.send({ hitType: "pageview", page: "/maintainance", title: " Under Maintainance" });
    }
  }, [window.location.href]);

    return (
        <BrowserRouter >
            <Routes>
                <Route element={<TAPRouterConfig />} />
                <Route index element={<TAPRouterConfig />} />
                <Route path="TAP/*" element={<TAPRouterConfig />} />
                <Route path="*" element={<NotFound />} />
                <Route path='logout' element={<LogoutView />} />
                <Route path='styleguide' element={<StyleGuide />} />
                <Route path='error' element={<CustomErrorPage header={labels.errorHeader} body={labels.errorBody} buttonText={labels.errorButtonText} isButtonVisible={true} buttonHandler={handlelogoutUser} subHeader={labels.errorSubHeader} />} />
                <Route path='unauthorized' element={<CustomErrorPage header={isMaintenanceTime ? TapGlobalMessages.tapMaintenance : labels.unauthorizedHeader} body={isMaintenanceTime ? '':labels.unauthorizedBody} buttonText={labels.unauthorizedButtonText} isButtonVisible={true} buttonHandler={navigateToDTH} subHeader={isMaintenanceTime ? TapGlobalMessages.siteWillBack: ''} isMaintenancePage={isMaintenanceTime} />} />
                <Route path='datarefresh' element={<DataRefresh />}></Route>
            </Routes>
        </BrowserRouter >
    )
}