export const TapTrainingPlanViewDirectReporteeMrgSnglTeamReducer = (state = {}, action) => {
    switch (action.type) {
        case 'POST_TRAININGPLANVIEW_DIRECTREPORTEE_MANAGERSINGLETEAM_DETAILS':
            return {
                ...state,
                directReporteeMrgerSnglTeamAPIResponse: action.payload
            }
   
        default:
            return state;
    }

};
