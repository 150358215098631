import apiAction from 'common/redux/actions/apiAction';
import { POST_USER_DETAILS_URL } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';
/****
 * Get LoggedIN user details
 ****/
const getUserDetails = data => {
    return {
        type: 'GET_USER_DETAILS',
        payload: data,
    };
};

export const UserDetails = configParams => {
    return apiAction.postAPI({
        url: POST_USER_DETAILS_URL(),
        data: configParams,
        onSuccess: getUserDetails,
        onFailure: err => logError('Error occured in fetching dashboard data.',err),
        label: 'FETCH_USERDETAILS_DATA',
    });
};
