import React from 'react';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';
import { SelectedTheme } from 'common/global';

export const CircleWithText = ({
  id,
  data,
  size = '68',
  bgColor,
  fgColor = 'black',
  fontSize,
  fontWeight,
  fontFamily,
  lineHeight,
  marginBottom,
}) => {
  const theme = SelectedTheme();
  theme.typography.h4.fontSize = fontSize;
  theme.typography.h4.lineHeight = lineHeight;
  theme.typography.h4.marginBottom = marginBottom;
  theme.typography.h4.fontFamily = fontFamily;
  return (
    <Avatar
      id={id}
      sx={{
        width: `${size}px`,
        height: `${size}px`,
        bgcolor: `${bgColor}`,
        color: `${fgColor}`,
        margin: '10px',
      }}
      data-testid='circle'
    >
      <Typography
        gutterBottom
        variant='h4'
        component='h4'
        sx={{
          fontWeight: `${fontWeight}`,
          fontSize: `${(theme.typography.h4.fontSize  + 'px')}`,
          lineHeight: `${(theme.typography.h4.lineHeight  + 'px')}`,
          marginBottom: `${(theme.typography.h4.marginBottom)}`,
          fontFamily: `${(theme.typography.h4.fontFamily)}`,
          color: `${fgColor}`,
        }}
        data-testid='text'
      >
        {data}
      </Typography>
    </Avatar>
  );
};
