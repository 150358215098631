import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { Table as AntDesignTable } from 'antd';
import { ToolTip } from 'common/components/toolTip';
import GenericPopup from 'common/popup';
import { fetchTrainingTableData } from 'TAP/components/fetchManagerManyTeamsData';
import {RedTheme } from 'common/global';
import { padding } from 'TAP/styles/genericStyles/padding';
import {generic} from 'TAP/styles/genericStyles/Generic'
import { width } from 'TAP/styles/genericStyles/width';
import { fontWeightStyles } from 'TAP/styles/genericStyles/fontWeights';
import { DEFAULT_PAGE_SIZE, PAGE_SIZE, PAGE_SIZES_ARRAY } from 'common/global/constants';
import { height } from 'TAP/styles/genericStyles/height';
import Button from '@mui/material/Button';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { LeaveIndicator, ComplianceDonutChartTooltipBody } from 'TAP/components';
import { getTextColorForComplianceTable, getTextColor } from 'TAP/utils/commonMethods';
import { ToolTipWrapper } from 'common/components';
import { TapGlobalMessages } from 'TAP/global';
import { useScreen } from 'common/hooks/useScreen';


export const ManagerManyTeamsTable = ({
  isFirstLevel = false,
  userID,
  selectedStatus,
  isSearch,
  searchForUserID,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [loggedInUserID, setLoggedInUserID] = useState('');
  const [myTeamsPerformanceTrainingTableState, setMyTeamsPerformanceTrainingTableState] = useState(
    [],
  );
  const [selectedTabValue, setSelectedTabValue] = useState(0);
  const [myOrgTrainingsFilteredData, setMyOrgTrainingsFilteredData] = useState([]);

  const [openPopup, setOpenPopup] = useState(false);
  const [popupHeader, setPopupHeader] = useState('');
  const [popupButtons, setPopupButtons] = useState([]);
  const [popUserId, setPopUserId] = useState('');
  const [popupStatus, setPopupStatus] = useState('');
  const [autocompleteConfigs, setAutocompleteConfigs] = useState([]);
  const [isCheckboxSelection, setIsCheckboxSelection] = useState(false);
  const [defaultExpandedRowKeys, setDefaultExpandedRowKeys] = useState([]);
  const screen = useScreen(); 
  
  const statusMapper = {
    'Job Description': 'jobDescription',
    'Summary of Experience': 'summaryOfExperience',
    'Individual Training Plan': 'individualTrainingPlan',
    'Trainings Completed On Time': 'trainings/completeOnTime',
    'Training Completed Overall' : 'trainings/completedOverall',
    'Inspection Readiness' : 'inspectionReadiness',
  };

  let redtheme = RedTheme;
  let teamMemberTabs = [{ name: 'Team Members' }];
  const handleChange = (_event, newValue) => {
    setSelectedTabValue(newValue);
  };

  let loggedInUserDetails = useSelector(state => state.tapUserDetailsRd);

  useEffect(() => {
    if (loggedInUserDetails && Object.keys(loggedInUserDetails).length) {
      setLoggedInUserID(loggedInUserDetails?.userDetails?.data[0].user_id);
    }
  }, [loggedInUserDetails]);

  const trainingTableFetch = response => {
    if (response && response?.data && response?.data?.data) {
      const apiResponse = response.data.data;

      if (apiResponse?.records) {
        const managerData = apiResponse.records.filter(
          member => member.user_id === userID,
        );
        const childrenData = apiResponse.records.filter(
          member => member.user_id !== userID,
        );

        // We display Manager Data first, followed by Team-member/children data
        const teamMembers = [...managerData, ...childrenData];
        setMyTeamsPerformanceTrainingTableState(teamMembers);
        setIsLoading(false);
      }
    } else {
      // Nothing to change
    }
  }



  useEffect(() => {
    if (userID?.length && loggedInUserID?.length) {
      const payloadApiType = isFirstLevel ? 'LOAD' : 'DRILL';
      const payload = {
        userId: userID,
        loggedInUserId: loggedInUserID,
        page: 0,
        limit: 1000,
        teamMember: '',
        status: selectedStatus,
        apiType: payloadApiType,
      };
      setIsLoading(true);

      fetchTrainingTableData(payload)
        .then(response => {
          trainingTableFetch(response); 
        })
        .catch(() => {
          setMyTeamsPerformanceTrainingTableState([]);
          setIsLoading(false);
        });
    }
  }, [isFirstLevel, userID, loggedInUserID, selectedStatus]);

  const columnTitleStyle = {
    fontFamily: '"JohnsonText-Bold"',
    fontWeight: fontWeightStyles.fw7.fontWeight,
    fontSize: '16px',
    lineHeight: '18px',
  };

  const rowContentStyle = {
    fontFamily: '"JohnsonText-Light"',
    fontWeight: fontWeightStyles.fw45.fontWeight,
    fontSize: '16px',
    lineHeight: '18px',
  };

  const handlePopupButtonClick = (rowData, heading, checkboxSelectionOpt) => {
    if (rowData.user_role > 1 && rowData.user_id !== userID) {
      setPopupStatus('drill');
    } else {
      setPopupStatus('single');
    }
    setPopUserId(rowData.user_id);
    setOpenPopup(true);
    setPopupHeader(heading);
    let configs;
    if (heading === 'Trainings Completed On Time' || heading === 'Training Completed Overall') {
      configs = [
        {
          label: 'Training Name',
          options: [],
          getOptionLabel: option => option,
          minWidth: '335px',
          mr: 2,
        },
      ];
    }
    setAutocompleteConfigs(configs);
    setIsCheckboxSelection(checkboxSelectionOpt);

    if (heading === 'Trainings Completed On Time' || heading === 'Training Completed Overall') {
      setPopupButtons([
        {
          label: 'Download Excel',
        },
      ]);
    }
  };

  const renderManagerName = (name, record) => {
    if (name?.length) {
      if (isSearch) {
        if (record.user_role > 1) {
          name += ' Team';
        }
      } else {
        if (record.user_role > 1 && record.user_id !== userID) {
          name += ' Team';
        }
      }
    }
    return (
      <Box sx={{ position: 'relative', paddingLeft: padding.pd15 * (record.drill_level - 1) + 'px' }}>
        <Grid container spacing={4} alignItems="center">
            <Grid item>
        <Typography sx={{ color: redtheme.palette.fontColor.black_gray, ...columnTitleStyle }}>{name}</Typography>
        <Typography variant='subtitle2' sx={{ color: redtheme.palette.fontColor.gray, ...rowContentStyle }}>
          {record.user_id}
        </Typography>
        </Grid>
        <Grid item>
          {record.leave_of_absence === 'Y' ? 
                <LeaveIndicator />
            :
            null}
        </Grid>
        </Grid>
      </Box>
    );
  };

  const renderjobDescription = (jobDescription, rowData) => {
    const newcolor=getTextColorForComplianceTable(rowData.jobDescription_Flag);
    return (
      rowData.leave_of_absence === 'N' ? 
      <span
        data-id={'jobDescription'}
        style={{ color: newcolor, ...columnTitleStyle }}
      >
        {jobDescription}
      </span>:
       <span
       data-id={'jobDescription'}
       style={{ color: redtheme.palette.fontColor.gray_orange, ...columnTitleStyle }}
     >
       -
     </span>
    );
  };
  const rendersummaryOfExperience = (summaryOfExperience, rowData) => {
    const newcolor=getTextColorForComplianceTable(rowData.summaryOfExperience_Flag);
    return (
      rowData.leave_of_absence === 'N' ? 
      <span
        data-id={'summaryOfExperience'}
        style={{ color:  newcolor, ...columnTitleStyle }}
      >
        {summaryOfExperience}
      </span>:
      <span
      data-id={'summaryOfExperience'}
      style={{ color:  redtheme.palette.fontColor.gray_orange, ...columnTitleStyle }}
    >
      -
    </span> 
    );
  };
  const renderindividualTrainingPlan = (individualTrainingPlan, rowData) => {
    const newcolor=getTextColorForComplianceTable(rowData.individualTrainingPlan_Flag);
    return (
      rowData.leave_of_absence==='N'?
      <span
        data-id={'individualTrainingPlan'}
        style={{ color: newcolor, ...columnTitleStyle }}
      >
        {individualTrainingPlan}
      </span>:
      <span
      data-id={'individualTrainingPlan'}
      style={{ color: redtheme.palette.fontColor.gray_orange, ...columnTitleStyle }}
    >
      -
    </span>
    );
  };
  const renderCompleteOnTime = (training_completed_on_time_percent, rowData) => {
    const dynamicCursor = rowData.training_completed_on_time_percent !== 0 ? 'pointer' : 'not-allowed';
    const completion_on_time_color=getTextColor(rowData.training_completed_on_time_percent);
    return (
      rowData.leave_of_absence==='N'?<span
      data-id={'training_completed_on_time_percent'}
      style={{ color:completion_on_time_color, cursor: dynamicCursor, ...columnTitleStyle }}
    >
        {training_completed_on_time_percent}
      </span>:
      <span
      data-id={'training_completed_on_time_percent'}
      style={{ color: redtheme.palette.fontColor.gray_orange, cursor: dynamicCursor, ...columnTitleStyle }}
    >
      -
    </span>
    );
  };
  const rendertrainingCompletedOverall = (training_completed_overall_percent, rowData) => {
    const dynamicCursor = rowData.training_completed_overall !== 0 ? 'pointer' : 'not-allowed';
    const completion_overall_color=getTextColor(rowData.training_completed_overall_percent);
    return (
      rowData.leave_of_absence==='N'?
      <span
        data-id={'training_completed_overall_percent'}
        style={{ color:completion_overall_color, cursor: dynamicCursor, ...columnTitleStyle }}
      >
        {training_completed_overall_percent}
      </span>:
      <span
      data-id={'training_completed_overall_percent'}
      style={{ color: redtheme.palette.fontColor.gray_orange, cursor: dynamicCursor, ...columnTitleStyle }}
    >
      -
    </span>
    );
  };
  const renderInspectionReadiness = (_inspectionReadiness_Indicator, rowData) => {
    let backgroundcolor;
    let new_text;
    if(rowData.inspectionReadiness_Indicator === 'G'){
      backgroundcolor = redtheme.palette.dataGrid.light_moderate_lime_green;
      new_text = 'Compliant';
    }else if(rowData.inspectionReadiness_Indicator === 'Y'){
      backgroundcolor = redtheme.palette.dataGrid.yellow;
      new_text = 'At Risk';
    }else{
      backgroundcolor = redtheme.palette.dataGrid.red;
      new_text = 'Non-Com.';
    }

    return (
      rowData.leave_of_absence === 'N'?
      <span
        data-id={'inspectionReadiness_Indicator'} 
      >
        <Button variant="text" sx={{ fontFamily: '"JohnsonText-Bold"',
        fontSize: '10px',
        backgroundColor:backgroundcolor,
        color:rowData.inspectionReadiness_Indicator==='Y'? redtheme.palette.fontColor.very_dark_grey:redtheme.palette.fontColor.white,
        borderRadius: '8px', textTransform:'none',pointerEvents: 'none',  }}>
          {new_text}
          </Button>
      </span>:
      <span
      data-id={'inspectionReadiness_Indicator'}
      style={{ color: redtheme.palette.fontColor.gray_orange, ...columnTitleStyle }}
    >
      <Button variant="text" sx={{ fontFamily: '"JohnsonText-Bold"',
      fontSize: '10px',
        backgroundColor: redtheme.palette.fontColor.gray_orange,
        color: redtheme.palette.fontColor.gray_orange,
        borderRadius: '8px', textTransform:'none', pointerEvents: 'none'  }}>
          -
          </Button>
    </span>
    );
  };

  const managerColumnTitle = (
    <Box>
      <Typography sx={columnTitleStyle}>{isFirstLevel ? 'Users' : 'Team Members'}</Typography>
      <Typography variant='subtitle2' sx={{ color: redtheme.palette.fontColor.gray, ...columnTitleStyle }}>
        WWID
      </Typography>
    </Box>
  );
  const jobDescriptionColumnTitle = (
    <Box> 
      <ToolTipWrapper  title = {TapGlobalMessages.columnHeaderTooltip.jobDescriptionTooltip} placement='top'>
      <Typography sx={columnTitleStyle}>Job Description</Typography>
      </ToolTipWrapper>
    </Box>
  );
  const summaryOfExperienceColumnTitle = (
    <Box>
      <ToolTipWrapper  title = {TapGlobalMessages.columnHeaderTooltip.summaryOfExpTooltip} placement='top'>
      <Typography sx={columnTitleStyle}>Summary of</Typography>
      <Typography sx={columnTitleStyle}>Experience</Typography>
      </ToolTipWrapper>
     </Box>
  );
  const individualTrainingPlanColumnTitle = (
    <Box>
      <ToolTipWrapper  title = {TapGlobalMessages.columnHeaderTooltip.IndiviTrainingPlanTooltip} placement='top'>
      <Typography sx={columnTitleStyle}>Individual Training</Typography>
      <Typography sx={columnTitleStyle}>Plan</Typography>
      </ToolTipWrapper>
    </Box>
  );
  const completedOnTimeColumnTitle = (
    <Box sx={columnTitleStyle}>
       <ToolTipWrapper   title = {TapGlobalMessages.columnHeaderTooltip.completedOnTimeTooltip} placement='top'>
      <Typography sx={columnTitleStyle}>Training Completed</Typography>
      <Typography sx={columnTitleStyle}>On Time</Typography>
      </ToolTipWrapper>
    </Box>
  );
  const trainingCompletedOverallColumnTitle = (
    <Box sx={columnTitleStyle}>
      <ToolTipWrapper title = {TapGlobalMessages.columnHeaderTooltip.completedOverallTooltip} placement='top'>
      <Typography sx={columnTitleStyle}>Training Completed</Typography>
      <Typography sx={columnTitleStyle}>Overall</Typography>
      </ToolTipWrapper>
    </Box>
  );
  const inspectionReadinesseColumnTitle = (
    <Box sx={columnTitleStyle}>
      <ToolTipWrapper title = {<ComplianceDonutChartTooltipBody/>} placement='top'> 
      <Typography sx={columnTitleStyle}>Compliance</Typography>
      <Typography sx={columnTitleStyle}>Profile</Typography>
      </ToolTipWrapper>  
    </Box>
  );

  const antDesignTableColumns = [
    {
      title: managerColumnTitle,
      dataIndex: 'full_name',
      key: 'full_name',
      width: 400,
      render: renderManagerName,
    },
    {
      title: jobDescriptionColumnTitle,
      dataIndex: 'jobDescription',
      key: 'jobDescription',
      width: 150,
      align: 'center',
      render: renderjobDescription,
    },
    {
      title: summaryOfExperienceColumnTitle,
      dataIndex: 'summaryOfExperience',
      key: 'summaryOfExperience',
      width: 180,
      align: 'center',
      render: rendersummaryOfExperience,
    },
    {
      title: individualTrainingPlanColumnTitle,
      dataIndex: 'individualTrainingPlan',
      key: 'individualTrainingPlan',
      width: 180,
      align: 'center',
      render: renderindividualTrainingPlan,
    },
    {
      title: completedOnTimeColumnTitle,
      dataIndex: 'training_completed_on_time_percent',
      key: 'training_completed_on_time_percent',
      width: 180,
      align: 'center',
      render: renderCompleteOnTime,
      onCell: record => {
        return {
          onClick: () => {
            if (record.training_completed_on_time_percent !== 0) {
              handlePopupButtonClick(record, 'Trainings Completed On Time', false);
            }
          },
        };
      },
    },
    {
      title: trainingCompletedOverallColumnTitle,
      dataIndex: 'training_completed_overall_percent',
      key: 'training_completed_overall_percent',
      width: 180,
      align: 'center',
      render: rendertrainingCompletedOverall,
      onCell: record => {
        return {
          onClick: () => {
            if (record.training_completed_overall !== 0) {
              handlePopupButtonClick(record, 'Training Completed Overall', false);
            }
          },
        };
      },
    },
    {
      title: inspectionReadinesseColumnTitle,
      dataIndex: 'inspectionReadiness_Indicator',
      key: 'inspectionReadiness_Indicator',
      width: 180,
      align: 'center',
      render: renderInspectionReadiness,
      
    },
  ];

  const completionStatus = params => {
    // param is the parameter which include all the required datas
    if(popupHeader === 'Trainings Completed On Time' || popupHeader === 'Training Completed Overall') {
      return (<Box sx={{ color:params.row.days_overdue == 0?redtheme.palette.fontColor.gray:redtheme.palette.box.red  }}>
        <span>{params.row?.days_overdue} Days Overdue</span>
      </Box>)
    }
    else {
      return (<Box sx={{ color:  redtheme.palette.box.red }}>
        <span></span>
      </Box>)
    }
    
  }
  const genericPopupColumns = [
    {
      field: 'training_title',
      width: screen.resolveStyles({
        mobile : 220, 
        tablet : 220, 
        desktop : null, 
      }),
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Training Name</Typography>
        </Box>
      ),
      flex:  !screen.isDesktop ? 0 : 2,
      disableColumnMenu: true,
      renderCell: params => {
        const training_title = <Typography sx={{fontFamily: '"JohnsonText-Regular"'}}>{params.value || ''}</Typography>;
        return (
          <ToolTip title={training_title} placement='top' header='Training Name' noOfLetters={40} />
        );
      },
    },
    {
      field: 'transcript_completed_date',
      width: screen.resolveStyles({
        mobile : 180, 
        tablet : 180, 
        desktop : null, 
      }),
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Completion Date</Typography>
        </Box>
      ),
      disableColumnMenu: true,
      flex:  !screen.isDesktop ? 0 : 1,
      renderCell: params => <Box sx={{fontFamily: '"JohnsonText-Regular"'}}><span>{params.row?.transcript_completed_date}</span></Box>,
    },
    {
      field: 'transcript_due_date',
      width: screen.resolveStyles({
        mobile : 220, 
        tablet : 220, 
        desktop : null, 
      }),
      renderHeader: () => (
        <Box>
          <Typography sx={{ fontFamily: '"JohnsonText-Bold"' }}>Due Date</Typography>
          {(popupHeader == 'Trainings Completed On Time' || popupHeader == 'Training Completed Overall') && (
            <Typography
              variant='subtitle2'
              sx={{ color:  redtheme.palette.fontColor.gray, fontWeight: fontWeightStyles.fw7.fontWeight, fontFamily: '"JohnsonText-Bold"' }}
            >
                Days Overdue
            </Typography>
          )}
        </Box>
      ),
      disableColumnMenu: true,
      flex:  !screen.isDesktop ? 0 : 1,
      renderCell: params => {
        return (
          <Box display='flex' flexDirection='column' sx={{fontFamily: '"JohnsonText-Regular"'}}>
            <span> {params.value}</span>
            {completionStatus(params)}
          </Box>
        );
      },
    },
  ];

  const filteredColumns = genericPopupColumns.filter(column => {
    if (popupHeader === 'Trainings Completed On Time' || popupHeader === 'Training Completed Overall') {
      return column.field !== 'is_active';
    } else {
      // Keep all other fields
      return true; 
    }
  });

  const [expandedRowKeys, setExpandedRowKeys] = React.useState([]);
  const onExpand = (expanded, record) => {
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, record.key]);
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== record.key));
    }
  };
 const loadExpandIconWithoutSearch = (expanded, onExpand, record) => {
  return (expanded ? 
    (
      <ExpandLessIcon onClick={e => onExpand(record, e)} sx={{ cursor: 'pointer' }} />
    ):
    (
      <ExpandMoreIcon onClick={e => onExpand(record, e)} sx={{ cursor: 'pointer' }} />
    )
  );
 }
 const loadExpandIconOnSearch = (expanded, onExpand, record, paddingVal) => {
  return (expanded ? 
    (
      <div style={{ paddingLeft: paddingVal + 'px' }}>
        <ExpandLessIcon onClick={e => onExpand(record, e)} sx={{ cursor: 'pointer' }} />
      </div>
    ):
    (
      <div style={{ paddingLeft: paddingVal + 'px' }}>
        <ExpandMoreIcon onClick={e => onExpand(record, e)} sx={{ cursor: 'pointer' }} />
      </div>
    )
  ); 
 }
  const loadExpandIcon = ({ expanded, onExpand, record }) => {
    if (record.user_role > 1 && record.user_id !== userID) {
      if (isSearch) {
        if (record?.children?.length > 0 || record.user_role > 1) {
          let paddingVal = record?.drill_level ? padding.pd15 * (record.drill_level - 1) : 0;
          return loadExpandIconOnSearch(expanded, onExpand, record, paddingVal)
        }
      } else {
          return loadExpandIconWithoutSearch(expanded, onExpand, record)
      }
    } else {
      return <></>;
    }
  };

  const renderCompOnExpand = record => {
    return (
      <Box sx={{ position: 'relative' }}>
        <ManagerManyTeamsTable
          userID={record.user_id}
          selectedStatus={selectedStatus}
        ></ManagerManyTeamsTable>
      </Box>
    );
  };

  const renderCompOnSearchExpand = record => {
    if (record.user_id === searchForUserID) {
      return (
        <Box sx={{ position: 'relative', paddingLeft: padding.pd15 * record.drill_level + 'px' }}>
          <ManagerManyTeamsTable
            userID={record.user_id}
            selectedStatus={selectedStatus}
          ></ManagerManyTeamsTable>
        </Box>
      );
    }
  };

  useEffect(() => {
    if (isSearch && userID?.length && loggedInUserID?.length) {
      const payload = {
        userId: userID,
        loggedInUserId: loggedInUserID,
        teamMember: searchForUserID !== undefined ? searchForUserID : '',
        apiType: 'SEARCH',
      };
      setIsLoading(true);

      fetchTrainingTableData(payload)
        .then(response => {
          if (response && response?.data && response?.data?.data && response?.data?.data?.records) {
            setMyOrgTrainingsFilteredData(response?.data?.data?.records);

            let listRowKeys = getUserKeys(response?.data?.data?.records);
            setDefaultExpandedRowKeys(listRowKeys);

            setIsLoading(false);
          } else {
            setMyOrgTrainingsFilteredData([]);
            setIsLoading(false);
          }
        })
        .catch(() => {
          setMyOrgTrainingsFilteredData([]);
          setIsLoading(false);
        });
    }
  }, [userID, loggedInUserID, isSearch, searchForUserID]);

  let listUserRowKeys = [];
  const getUserKeys = obj => {
    obj?.map(item => {
      if (item?.user_id && item?.user_id !== searchForUserID) {
        listUserRowKeys.push(item.user_id);
      }
      if (item?.children && item.children.length) {
        getUserKeys(item.children);
      }
    });
    return listUserRowKeys;
  };

  const CustomNoDataOverlay = () => {
    let overlayMessage = 'No Team Members assigned to you at the moment';
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px',
          width: '100%',
          height: '480px',
          fontFamily: '"J&J CircularTT Black Web"',
          fontWeight: fontWeightStyles.fw9.fontWeight,
          fontSize: '26px',
          lineHeight: '32px',
          color:  redtheme.palette.box.dark_grayish_blue ,
          backgroundColor: redtheme.palette.box.very_light_grey,
        }}
      >
        {overlayMessage}
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ width: width.wid1, minHeight: height.h100 }}>
        {!isFirstLevel ? (
          <Tabs
            data-testid='managerSingleTeamMainTabs'
            value={selectedTabValue}
            onChange={handleChange}
            TabIndicatorProps={{
              sx: {
                height: '3px',
                borderRadius: '3px',
              },
            }}
            sx={{
              minHeight: '45px',
              '& .Mui-selected': {
                fontWeight: fontWeightStyles.b.fontWeight,
              },
            }}
            aria-label='TAP Navigation'
          >
            {teamMemberTabs.map((link, index) => (
              <Tab
                key={index}
                label={link.name}
                data-testid={link.name}
                sx={{
                  textTransform: 'none',
                  borderBottom: 3,
                  borderColor: 'divider',
                  minHeight: '45px',
                  color: `${redtheme.palette.tab.black} !important`
                }}
              />
            ))}
          </Tabs>
        ) : null}

        {isSearch && myOrgTrainingsFilteredData !== undefined && !isLoading ? (
          <AntDesignTable
            className='my-teams-perf-nested-table ant-spin-container ant-table'
            loading={isLoading}
            rowClassName={(record, index) =>
              record.leave_of_absence==='Y'?'manager-many-teams-table-row-disabled':
              index % generic.gen2 === 0
                ? 'manager-many-teams-table-row-dark'
                : 'manager-many-teams-table-row-light'
            }
            rowKey={e => e.key}
            columns={antDesignTableColumns}
            dataSource={myOrgTrainingsFilteredData}
            expandable={{
              defaultExpandedRowKeys: defaultExpandedRowKeys,
              expandIcon: loadExpandIcon,
              expandedRowRender: renderCompOnSearchExpand,
            }}
          />
        ) : (
          <Box
            sx={{
              padding: '10px 0',
            }}
          >
            <AntDesignTable
              className='my-teams-perf-nested-table ant-spin-container ant-table'
              loading={isLoading}
              rowClassName={(record, index) =>
                record.leave_of_absence==='Y'?'manager-many-teams-table-row-disabled':
                index % generic.gen2 === 0
                  ? 'manager-many-teams-table-row-dark'
                  : 'manager-many-teams-table-row-light'
              }
              rowKey={row => row.key}
              columns={antDesignTableColumns}
              dataSource={myTeamsPerformanceTrainingTableState}
              locale={{ emptyText: CustomNoDataOverlay }}
              pagination={
                isFirstLevel
                  ? {
                    defaultPageSize: DEFAULT_PAGE_SIZE,
                    showSizeChanger: true,
                    pageSizeOptions: PAGE_SIZES_ARRAY,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
                  }
                  : {
                    pageSize: PAGE_SIZE,
                  }
              }
              expandable={{
                expandedRowRender: renderCompOnExpand,
                expandIcon: loadExpandIcon,
                expandRowByClick: false,
                expandedRowKeys,
                onExpand,
              }}
            />
          </Box>
        )}
      </Box>
      <GenericPopup
        open={openPopup}
        onClose={() => {
          setOpenPopup(false);
          setPopupHeader(null);
          setPopupStatus('');
        }}
        header={popupHeader}
        columns={filteredColumns}
        buttons={popupButtons}
        autocompletes={autocompleteConfigs}
        setAutocompleteConfigs={setAutocompleteConfigs}
        popupHeader={popupHeader}
        statusMapper={statusMapper}
        popUserId={popUserId}
        popupStatus={popupStatus}
        isCheckboxSelection={isCheckboxSelection}
      />
    </>
  );
};