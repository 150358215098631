import { Box, Autocomplete, TextField, Typography, Grid } from "@mui/material";
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { GenericPopupTooltip } from "common/components/genericPopupTooltip";
import { RedTheme } from "common/global"; 
import { IOSSwitch } from "common/components/switch";
import { ReactComponent as CrossIcon } from 'common/assets/images/CrossIcon.svg';
import { useScreen } from "common/hooks/useScreen";
import { NUMBER_ARRAY } from "common/global/constants";

export const GenericAutocomplete = ({
    options,
    values,
    switchChangeHandler, 
    checked,
    getLabeledOptions,
    handleAdminStatusChange,
    getEqualityCheck,
    type,
    placeholder,
    heading,
    switchIndicator,
    isSwitchToShow = true
}) => 
{
    let redtheme = RedTheme;
    const screen = useScreen(); 

    return (
        <>
            {isSwitchToShow ? <Box sx={{ marginLeft: '80%', marginBottom: '-18px' }}>
                <GenericPopupTooltip title={'Switch to Selected'} placement='top' header={' '}>
                    <IOSSwitch checked={checked} onChange={(e) => switchChangeHandler(e, type)} />
                </GenericPopupTooltip>
            </Box> : null}
            <Autocomplete
                id='search-training-by-title'
                data-testid='search-training-by-title'
                options={switchIndicator ? options : values}
                popupIcon={''}
                getOptionLabel={option => getLabeledOptions(option,type)}
                isOptionEqualToValue={(option, value) => getEqualityCheck(option, value, type)}   /* This will show the selected value highlighted on the Search part of Autocomplete */
                renderOption={(props, option) => (
                    <li {...props}>
                        {!switchIndicator ? <Grid container > 
                            <Grid item xs={11.5}>
                                {getLabeledOptions(option,type)}
                            </Grid>
                            <Grid item xs={0.5}>
                                {!switchIndicator ? <Box><CrossIcon/></Box> : null}
                            </Grid>
                        </Grid> : getLabeledOptions(option,type)}
                    </li>
                )}
                multiple
                value={values}
                onChange={(e, Value) => handleAdminStatusChange(Value, type)}
                renderInput={params => (
                    <TextField
                        data-testid='trainingNameInput'
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            shrink: true,
                            startAdornment: (
                              <InputAdornment position="start">
                                <SearchIcon />
                              </InputAdornment>
                            ),
                          }}
                        placeholder={placeholder}
                        label={
                            <Typography
                                sx={{
                                    fontSize: screen.isTablet ? '20px' : '14px',
                                    lineHeight: '12px',
                                    fontWeight: 500,
                                    fontFamily: '"JohnsonText-Bold"',
                                    color: 'rgba(33, 33, 33, 1)',
                                    width: '728px',
                                    height:  screen.isTablet ? '25px' :  '16px',
                                    paddingTop : screen.isTablet ? '5px' : NUMBER_ARRAY.zero
                                }}
                            >
                                {heading}
                            </Typography>
                        }
                        sx={{
                            borderBottom: `1px solid ${redtheme.palette.fontColor.gray_orange}`,
                        }}
                        variant='standard'
                        margin='normal'
                    />
                )}
                sx={{ mr: 2 }}
                clearOnBlur
                renderTags={() => {}}
            />
        </>)
}