
import apiAction from 'common/redux/actions/apiAction';
import {
  POST_TEAM_PERFORMANCE_TRAINING_OVERDUE_URL,
  POST_TEAM_PERFORMANCE_TRAINING_UPCOMINGDUE_URL,
} from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

const getTrainingOverdueData = data => {
  return {
    type: 'GET_TRAINING_OVERDUE_DATA',
    payload: data,
  };
};

export const TrainingOverdueData = configParams => {
  return apiAction.postAPI({
    url: POST_TEAM_PERFORMANCE_TRAINING_OVERDUE_URL(),
    data: configParams,
    onSuccess: getTrainingOverdueData,
    onFailure: err =>  logError('Error occurred in fetching TrainingOverdueDataa:', err),
    label: 'FETCH_TRAININGOVERDUE_DATA',
  });
};
const getTrainingUpcomingOverdueData = data => {
  return {
    type: 'GET_TRAINING_UPCOMINGDUE_DATA',
    payload: data,
  };
};

export const TrainingUpcomingOverdueData = configParams => {
  return apiAction.postAPI({
    url: POST_TEAM_PERFORMANCE_TRAINING_UPCOMINGDUE_URL(),
    data: configParams,
    onSuccess: getTrainingUpcomingOverdueData,
    onFailure: err => logError('Error occured in TrainingUpcomingOverdueData data.:', err),
    label: 'FETCH_TRAININGUPCOMINGDUE_DATA',
  });
};
