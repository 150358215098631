export const AdminComplianceActionsReducer = (state = {}, action) => {
    switch (action.type) {
      case 'GET_ADMIN_NON_COMPLIANT_DATA':
        return {
          ...state,
          nonCompliantData: action.payload,
        };
      case 'GET_ADMIN_AT_RISK_DATA':
        return {
          ...state,
          atRiskData: action.payload,
        };
  
      case 'API_START':
        if (action.payload === 'FETCH_ADMIN_NON_COMPLIANT_DATA') {
          return {
            ...state,
            isLoadingAdminNonCompliantData: true,
          };
        } else if (action.payload === 'FETCH_ADMIN_AT_RISK_DATA') {
          return {
            ...state,
            isLoadingAdminAtRiskData: true,
          };
        } else {
          return state;
        }
  
      case 'API_END':
        if (action.payload === 'FETCH_ADMIN_NON_COMPLIANT_DATA') {
          return {
            ...state,
            isLoadingAdminNonCompliantData: false,
          };
        } else if (action.payload === 'FETCH_ADMIN_AT_RISK_DATA') {
          return {
            ...state,
            isLoadingAdminAtRiskData: false,
          };
        } else {
          return state;
        }
  
      default:
        return state;
    }
  };
  