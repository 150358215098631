import React, {useState, useEffect, useRef} from 'react';
import { Tooltip, Typography, Box, tooltipClasses, styled } from '@mui/material';
import {  RedTheme } from 'common/global';
import { useScreen } from 'common/hooks/useScreen';

let redtheme = RedTheme;
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: redtheme.palette.tooltip.white,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: redtheme.palette.tooltip.white,
    color: redtheme.palette.tooltip.black_gray,
    maxWidth: '357px',
    fontSize: '16px',
    boxShadow: '0px 4px 24px 0px rgba(0, 0, 0, 0.24)',
    border: `1px solid ${redtheme.palette.tooltip.white}`,
    borderRadius: '16px',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: '20px',
    gap: '8px',
    maxHeight: '186px',
  },
}));

export const ToolTip = ({ title, placement, header, noOfLetters }) => {
  const screen = useScreen(); 
  const [isToolTipOpen,setIsToolTipOpen] = useState(false); 
  const tooltipRef = useRef(null);
  const { isMobile } = useScreen();
  const toolTipTitle = () => {
    return (<>
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Bold"',
              color: redtheme.palette.tooltip.black,
              fontWeight: '700',
              fontSize: '16px',
              lineHeight: '16px',
              marginBottom: '8px',
            }}
          >
            {header}
          </Typography>
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Light"',
              color: redtheme.palette.tooltip.black,
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '16px',
              marginBottom: '8px',
            }}
          >
            {title}
          </Typography>
        </>)
  }
  const toolTipBody = () => {
    return (<Box
        width={isMobile ? '100%' : 'auto'}
        sx={{
          color: redtheme.palette.box.black_gray,
          fontSize: '14px',
          lineHeight: '14px',
          fontFamily: '"JohnsonText-Regular"',
          fontWeight: 400,
        }}
      >
        {title.length > noOfLetters ? `${title.slice(0, noOfLetters)}...` : title}
      </Box>)
  }
  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setIsToolTipOpen(false);
    }
  };
   useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    screen.isDesktop ? 
    <HtmlTooltip
      placement={placement}
      title={toolTipTitle()}
      arrow
    >
      {toolTipBody()}
    </HtmlTooltip>
    :
    <div ref={tooltipRef}>
    <HtmlTooltip
      placement={placement}
      title={toolTipTitle()}
      onClose={() => setIsToolTipOpen(false)}
      onClick={() => setIsToolTipOpen(!isToolTipOpen)}
      open={isToolTipOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      arrow
    >
      {toolTipBody()}
    </HtmlTooltip>
    </div>
  );
};
