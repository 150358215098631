import { Provider, ReactReduxContext } from 'react-redux';

import App from './App';
import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './common/authentication/authConfig';
import configureStore from './common/redux/store/configureStore'; 
import 'common/styles/style.css';
// JNJ Style Guide fonts
import 'common/assets/fonts/J&J CircularTT-Black.eot';
import 'common/assets/fonts/J&J CircularTT-Black.woff';
import 'common/assets/fonts/J&J CircularTT-Black.woff2';

import 'common/assets/fonts/J&J CircularTT-MediumItalic.eot';
import 'common/assets/fonts/J&J CircularTT-MediumItalic.woff2';
import 'common/assets/fonts/J&J CircularTT-MediumItalic.woff';

import 'common/assets/fonts/J&J CircularTT-Medium.eot';
import 'common/assets/fonts/J&J CircularTT-Medium.woff2';
import 'common/assets/fonts/J&J CircularTT-Medium.woff';

import 'common/assets/fonts/J&J CircularTT-LightItalic.eot';
import 'common/assets/fonts/J&J CircularTT-LightItalic.woff2';
import 'common/assets/fonts/J&J CircularTT-LightItalic.woff';

import 'common/assets/fonts/J&J CircularTT-Light.eot';
import 'common/assets/fonts/J&J CircularTT-Light.woff2';
import 'common/assets/fonts/J&J CircularTT-Light.woff';

import 'common/assets/fonts/J&J CircularTT-BookItalic.eot';
import 'common/assets/fonts/J&J CircularTT-BookItalic.woff2';
import 'common/assets/fonts/J&J CircularTT-BookItalic.woff';

import 'common/assets/fonts/J&J CircularTT-Book.eot';
import 'common/assets/fonts/J&J CircularTT-Book.woff2';
import 'common/assets/fonts/J&J CircularTT-Book.woff';

import 'common/assets/fonts/J&J CircularTT-BoldItalic.eot';
import 'common/assets/fonts/J&J CircularTT-BoldItalic.woff2';
import 'common/assets/fonts/J&J CircularTT-BoldItalic.woff';

import 'common/assets/fonts/J&J CircularTT-Bold.eot';
import 'common/assets/fonts/J&J CircularTT-Bold.woff2';
import 'common/assets/fonts/J&J CircularTT-Bold.woff';

import 'common/assets/fonts/J&J CircularTT-BlackItalic.eot';
import 'common/assets/fonts/J&J CircularTT-BlackItalic.woff2';
import 'common/assets/fonts/J&J CircularTT-BlackItalic.woff';
import { MediaProvider } from 'common/context/MediaContext';

const store = configureStore();

const msalInstance = new PublicClientApplication(msalConfig);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store} context={ReactReduxContext}>
    {/* <ConnectedRouter history={history} context={ReactReduxContext}> */}
    <MsalProvider instance={msalInstance}>
      <MediaProvider>
        <App />
      </MediaProvider>
    </MsalProvider>
    {/* </ConnectedRouter> */}
  </Provider>,
);
