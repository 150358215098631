import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { LoaToolTip } from 'common/components/loaToolTip';
import { ReactComponent as Leave } from 'common/assets/images/Leave.svg';
import { RedTheme } from 'common/global';
import { fontsize } from 'TAP/styles/genericStyles/fontSize';

export const LeaveIndicator = () => {
  let redtheme = RedTheme;
  return (
    <Box sx={{}}>
      <LoaToolTip title='This User is on Leave' placement='right'>
        <Typography
        data-testid='onLeaveText'
          sx={{
            color: redtheme.palette.fontColor.dark_blue,
            display: 'flex',
            alignItems: 'center',
            gap: '3px',
            fontFamily: '"JohnsonText-Medium"',
            fontWeight: '500',
            fontSize: fontsize.fs10,
            lineHeight: '18px',
          }}
        >
          <Leave />
          On Leave
        </Typography>
      </LoaToolTip>
    </Box>
  );
};
