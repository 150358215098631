import apiAction from 'common/redux/actions/apiAction';
import { POST_TEAM_PERFORMANCE_POPUP_FILTER } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

/****
 * POST POPUP LISTING DETAILS
 ****/
const getPopupUpFilterData = data => {
  return {
    type: 'GET_POPUP_FILTER_DATA',
    payload: data,
  };
};

export const PopupUpFilterData = configParams => {
  return apiAction.postAPI({
    url: POST_TEAM_PERFORMANCE_POPUP_FILTER(configParams.actionsType),
    data: configParams,
    onSuccess: getPopupUpFilterData,
    onFailure: err => logError('Error occured in fetching dashboard data.:', err),
    label: 'FETCH_POPUPFILTERDATA_DATA',
  });
};