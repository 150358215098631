export const DirectReporteeComplianceActionsReducer = (state = {}, action) => {
    switch (action.type) {
      case 'GET_DIRECT_NON_COMPLIANT_DATA':
        return {
          ...state,
          nonCompliantData: action.payload,
        };
      case 'GET_DIRECT_AT_RISK_DATA':
        return {
          ...state,
          atRiskData: action.payload,
        };
  
      case 'API_START':
        if (action.payload === 'FETCH_DIRECT_NON_COMPLIANT_DATA') {
          return {
            ...state,
            isLoadingDRNonCompliantData: true,
          };
        } else if (action.payload === 'FETCH_DIRECT_AT_RISK_DATA') {
          return {
            ...state,
            isLoadingDRAtRiskData: true,
          };
        } else {
          return state;
        }
  
      case 'API_END':
        if (action.payload === 'FETCH_DIRECT_NON_COMPLIANT_DATA') {
          return {
            ...state,
            isLoadingDRNonCompliantData: false,
          };
        } else if (action.payload === 'FETCH_DIRECT_AT_RISK_DATA') {
          return {
            ...state,
            isLoadingDRAtRiskData: false,
          };
        } else {
          return state;
        }
  
      default:
        return state;
    }
  };
  