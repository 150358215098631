import { useState, useEffect } from 'react';
import { UserDetail } from 'common/components/userDetail';
import { UserDetails } from 'TAP/redux/actions';
import { useSelector, useDispatch } from 'react-redux';
import { getAuthToken } from 'common/authentication/authToken';

export const RoleMatrix = () => {
  const [initializer, setInitializer] = useState(false);
  const uerDetailFrmMSAL = UserDetail().userEmailId;
  const dispatch = useDispatch();

  let getLoggedInUserDetails = useSelector((state) => state.tapUserDetailsRd);

    useEffect(() => {
        if (!initializer) {
            // Invoke the /user/profile API only if access token is available
            let token = getAuthToken();
            if(!getLoggedInUserDetails?.userDetails && token?.idToken && uerDetailFrmMSAL ){
                dispatch(UserDetails({
                    "email": uerDetailFrmMSAL
                }));
            }
        }
        setInitializer(true);
    }, [initializer]);

  useEffect(() => {
    if (getLoggedInUserDetails && Object.keys(getLoggedInUserDetails).length) {
      localStorage.setItem(
        'userDetails',
        JSON.stringify(getLoggedInUserDetails.userDetails?.data[0]),
      );
    }
  }, [getLoggedInUserDetails]);

  return getLoggedInUserDetails?.userDetails &&
    Object.keys(getLoggedInUserDetails.userDetails).length
    ? {
        user_id: getLoggedInUserDetails.userDetails?.data[0].user_id,
        full_name: getLoggedInUserDetails.userDetails?.data[0].full_name,
        user_email: getLoggedInUserDetails.userDetails?.data[0].user_email,
        user_hire_date: getLoggedInUserDetails.userDetails?.data[0].user_hire_date,
        manager_id: getLoggedInUserDetails.userDetails?.data[0].manager_id,
        indirect_manager_id: getLoggedInUserDetails.userDetails?.data[0].indirect_manager_id,
        is_admin: getLoggedInUserDetails.userDetails?.data[0].is_admin,
        is_active: getLoggedInUserDetails.userDetails?.data[0].is_active,
        is_deleted: getLoggedInUserDetails.userDetails?.data[0].is_deleted,
        created_date: getLoggedInUserDetails.userDetails?.data[0].created_date,
        updated_date: getLoggedInUserDetails.userDetails?.data[0].updated_date,
        last_refresh_date: getLoggedInUserDetails.userDetails?.data[0].last_refresh_date,
        is_manager: getLoggedInUserDetails.userDetails?.data[0].is_manager,
        user_role: getLoggedInUserDetails.userDetails?.data[0].user_role,
      }
    : {};
};
