export const StaticResponse = {
  dataTabulator: [
    {
      id: 1,
      name: 'John Smith',
      age: 35,
      email: 'john.smith@example.com',
      city: 'New York',
    },
    {
      id: 2,
      name: 'Alice Green',
      age: 42,
      email: 'alice.green@example.com',
      city: 'Los Angeles',
    },
    {
      id: 3,
      name: 'David Lee',
      age: 28,
      email: 'david.lee@example.com',
      city: 'San Francisco',
    },
    {
      id: 4,
      name: 'Sarah Johnson',
      age: 31,
      email: 'sarah.johnson@example.com',
      city: 'Chicago',
    },
    {
      id: 5,
      name: 'Michael Brown',
      age: 45,
      email: 'michael.brown@example.com',
      city: 'Houston',
    },
    {
      id: 6,
      name: 'Emily Davis',
      age: 29,
      email: 'emily.davis@example.com',
      city: 'Boston',
    },
    {
      id: 7,
      name: 'Robert Wilson',
      age: 37,
      email: 'robert.wilson@example.com',
      city: 'Seattle',
    },
    {
      id: 8,
      name: 'Jennifer Kim',
      age: 26,
      email: 'jennifer.kim@example.com',
      city: 'Washington DC',
    },
    {
      id: 9,
      name: 'Kevin Park',
      age: 33,
      email: 'kevin.park@example.com',
      city: 'Atlanta',
    },
    {
      id: 10,
      name: 'Laura Hernandez',
      age: 39,
      email: 'laura.hernandez@example.com',
      city: 'Miami',
    },
    {
      id: 11,
      name: 'Daniel Kim',
      age: 41,
      email: 'daniel.kim@example.com',
      city: 'Los Angeles',
    },
    {
      id: 12,
      name: 'Olivia Lee',
      age: 24,
      email: 'olivia.lee@example.com',
      city: 'San Francisco',
    },
    {
      id: 13,
      name: 'Ryan Rodriguez',
      age: 36,
      email: 'ryan.rodriguez@example.com',
      city: 'Houston',
    },
    {
      id: 14,
      name: 'Sophia Martinez',
      age: 27,
      email: 'sophia.martinez@example.com',
      city: 'Chicago',
    },
    {
      id: 15,
      name: 'William Johnson',
      age: 32,
      email: 'william.johnson@example.com',
      city: 'New York',
    },
    {
      id: 16,
      name: 'Natalie Brown',
      age: 29,
      email: 'natalie.brown@example.com',
      city: 'Boston',
    },
    {
      id: 17,
      name: 'Lucas Garcia',
      age: 38,
      email: 'lucas.garcia@example.com',
      city: 'Seattle',
    },
    {
      id: 18,
      name: 'Ava Davis',
      age: 25,
      email: 'ava.davis@example.com',
      city: 'Washington DC',
    },
    {
      id: 19,
      name: 'Ethan Smith',
      age: 43,
      email: 'ethan.smith@example.com',
      city: 'Atlanta',
    },
    {
      id: 20,
      name: 'Isabella Hernandez',
      age: 30,
      email: 'isabella.hernandez@example.com',
      city: 'Miami',
    },
  ],
  pieChart: [
    {
      Completed_On_Time: 76.38,
    },
    {
      Completed_OverAll: 88.74,
    },
  ],
  barChart: [
    {
      DATA: 99,
      MONTH: 'January',
      MONTH_NO: 1,
      Quarter: 1,
      YEAR: 2022,
    },
    {
      DATA: 99,
      MONTH: 'February',
      MONTH_NO: 2,
      Quarter: 1,
      YEAR: 2022,
    },
    {
      DATA: 98,
      MONTH: 'March',
      MONTH_NO: 3,
      Quarter: 1,
      YEAR: 2022,
    },
    {
      DATA: 96,
      MONTH: 'April',
      MONTH_NO: 4,
      Quarter: 2,
      YEAR: 2022,
    },
    {
      DATA: 98,
      MONTH: 'May',
      MONTH_NO: 5,
      Quarter: 2,
      YEAR: 2022,
    },
    {
      DATA: 100,
      MONTH: 'June',
      MONTH_NO: 6,
      Quarter: 2,
      YEAR: 2022,
    },
    {
      DATA: 85,
      MONTH: 'July',
      MONTH_NO: 7,
      Quarter: 3,
      YEAR: 2022,
    },
    {
      DATA: 86,
      MONTH: 'August',
      MONTH_NO: 8,
      Quarter: 3,
      YEAR: 2022,
    },
    {
      DATA: 85,
      MONTH: 'September',
      MONTH_NO: 9,
      Quarter: 3,
      YEAR: 2022,
    },
    {
      DATA: 81,
      MONTH: 'October',
      MONTH_NO: 10,
      Quarter: 4,
      YEAR: 2022,
    },
    {
      DATA: 82,
      MONTH: 'Novemebr',
      MONTH_NO: 11,
      Quarter: 4,
      YEAR: 2022,
    },
    {
      DATA: 81,
      MONTH: 'December',
      MONTH_NO: 12,
      Quarter: 4,
      YEAR: 2022,
    },
    {
      DATA: 67,
      MONTH: 'Januaray',
      MONTH_NO: 1,
      Quarter: 1,
      YEAR: 2023,
    },
    {
      DATA: 67,
      MONTH: 'Januaray',
      MONTH_NO: 1,
      Quarter: 1,
      YEAR: 2023,
    },
    {
      DATA: 69,
      MONTH: 'February',
      MONTH_NO: 2,
      Quarter: 1,
      YEAR: 2023,
    },
    {
      DATA: 69,
      MONTH: 'March',
      MONTH_NO: 3,
      Quarter: 1,
      YEAR: 2023,
    },
  ],
};

export const mockDataTest = [
  {
    id: 1,
    training_name: 'Johnson & Johnson GxP and Risk management',
    curriculam_status: 'Sarah Johnson',
    Indirect_curriculam_status: 'David Lee',
    status: 'Completed on time',
    Curriculum_Title: 'Introduction to No due date',
    Training_Title: 'No due date Basics',
    Transcript_Due_Date: '2023-04-30',
    Transcript_Completed_Date: '2023-04-15',
  },
  {
    id: 2,
    training_name: 'Jane Doe',
    curriculam_status: 'Alice Green',
    Indirect_curriculam_status: 'David Lee',
    status: 'Incomplete Overdue',
    Curriculum_Title: 'Diversity and Inclusion',
    Training_Title: 'Unconscious Bias',
    Transcript_Due_Date: '2023-05-31',
    Transcript_Completed_Date: '',
  },
  {
    id: 3,
    training_name: 'Bob Johnson',
    curriculam_status: 'John Smith',
    Indirect_curriculam_status: 'David Lee',
    status: 'Completed on time',
    Curriculum_Title: 'No due date  Techniques',
    Training_Title: 'Negotiation Skills',
    Transcript_Due_Date: '2023-06-30',
    Transcript_Completed_Date: '',
  },
  {
    id: 4,
    training_name: 'Amy Lee',
    curriculam_status: 'David Lee',
    Indirect_curriculam_status: '',
    status: 'Completed on time',
    Curriculum_Title: 'Cybersecurity',
    Training_Title: 'Data Privacy',
    Transcript_Due_Date: '2023-05-15',
    Transcript_Completed_Date: '2023-05-10',
  },
  {
    id: 5,
    training_name: 'Tom Brown',
    curriculam_status: 'John Smith',
    Indirect_curriculam_status: 'David Lee',
    status: 'Incomplete Overdue',
    Curriculum_Title: 'Financial Management',
    Training_Title: 'Budgeting',
    Transcript_Due_Date: '2023-06-30',
    Transcript_Completed_Date: '',
  },
  {
    id: 6,
    training_name: 'Sarah Johnson',
    curriculam_status: 'David Lee',
    Indirect_curriculam_status: '',
    status: 'No due date',
    Curriculum_Title: 'Social Media No due date',
    Training_Title: 'Content Creation',
    Transcript_Due_Date: '2023-07-31',
    Transcript_Completed_Date: '',
  },
  {
    id: 7,
    training_name: 'Alice Green',
    curriculam_status: 'David Lee',
    Indirect_curriculam_status: '',
    status: 'Incomplete',
    Curriculum_Title: 'Leadership Development',
    Training_Title: 'Coaching and Mentoring',
    Transcript_Due_Date: '2023-06-15',
    Transcript_Completed_Date: '2023-06-12',
  },
  {
    id: 8,
    training_name: 'David Lee',
    curriculam_status: '',
    Indirect_curriculam_status: '',
    status: 'Incomplete',
    Curriculum_Title: 'Incomplete Leadership',
    Training_Title: 'Strategic Planning',
    Transcript_Due_Date: '2023-08-31',
    Transcript_Completed_Date: '',
  },
  {
    id: 9,
    training_name: 'Jane Doe',
    curriculam_status: 'Green',
    Indirect_curriculam_status: 'David Lee',
    status: 'Incomplete',
    Curriculum_Title: 'Performance Management',
    Training_Title: 'Feedback and Coaching',
    Transcript_Due_Date: '2023-05-31',
    Transcript_Completed_Date: '2023-05-25',
  },
  {
    id: 10,
    training_name: 'Mark Davis',
    curriculam_status: 'John Smith',
    Indirect_curriculam_status: 'David Lee',
    status: 'No due date',
    Curriculum_Title: 'No due date  Techniques',
    Training_Title: 'Closing Deals',
    Transcript_Due_Date: '2023-07-15',
    Transcript_Completed_Date: '',
  },
  {
    id: 11,
    training_name: 'Maggie Wong',
    curriculam_status: 'David Lee',
    Indirect_curriculam_status: '',
    status: 'Completed on time',
    Curriculum_Title: 'Agile Methodology',
    Training_Title: 'Scrum Fundamentals',
    Transcript_Due_Date: '2023-08-31',
    Transcript_Completed_Date: '',
  },
  {
    id: 12,
    training_name: 'Oliver White',
    curriculam_status: 'John Smith',
    Indirect_curriculam_status: 'David Lee',
    status: 'Incomplete',
    Curriculum_Title: 'Financial Reporting',
    Training_Title: 'Accounting Basics',
    Transcript_Due_Date: '2023-07-31',
    Transcript_Completed_Date: '2023-07-25',
  },
  {
    id: 13,
    training_name: 'Karen Chen',
    curriculam_status: 'Sarah Johnson',
    Indirect_curriculam_status: 'David Lee',
    status: 'No due date',
    Curriculum_Title: 'Product No due date',
    Training_Title: 'Market Research',
    Transcript_Due_Date: '2023-09-30',
    Transcript_Completed_Date: '',
  },
  {
    id: 14,
    training_name: 'Mike Lee',
    curriculam_status: 'John Smith',
    Indirect_curriculam_status: 'David Lee',
    status: 'No due date',
    Curriculum_Title: 'No due date  Techniques',
    Training_Title: 'Presentation Skills',
    Transcript_Due_Date: '2023-08-31',
    Transcript_Completed_Date: '',
  },
  {
    id: 15,
    training_name: 'Nina Kim',
    curriculam_status: 'David Lee',
    Indirect_curriculam_status: '',
    status: 'Completed on time',
    Curriculum_Title: 'Cloud Computing',
    Training_Title: 'AWS Fundamentals',
    Transcript_Due_Date: '2023-10-31',
    Transcript_Completed_Date: '',
  },
  {
    id: 16,
    training_name: 'Rachel Zhang',
    curriculam_status: 'Sarah Johnson',
    Indirect_curriculam_status: 'David Lee',
    status: 'No due date',
    Curriculum_Title: 'Email No due date',
    Training_Title: 'List Building',
    Transcript_Due_Date: '2023-11-30',
    Transcript_Completed_Date: '',
  },
  {
    id: 17,
    training_name: 'Samuel Kim',
    curriculam_status: 'David Lee',
    Indirect_curriculam_status: '',
    status: 'Completed on time',
    Curriculum_Title: 'Network Security',
    Training_Title: 'Firewall Configuration',
    Transcript_Due_Date: '2023-12-31',
    Transcript_Completed_Date: '',
  },
  {
    id: 18,
    training_name: 'Sophie Lee',
    curriculam_status: 'Alice Green',
    Indirect_curriculam_status: 'David Lee',
    status: 'Incomplete',
    Curriculum_Title: 'Diversity and Inclusion',
    Training_Title: 'Unconscious Bias',
    Transcript_Due_Date: '2024-01-31',
    Transcript_Completed_Date: '',
  },
  {
    id: 19,
    training_name: 'Tom Jackson',
    curriculam_status: 'John Smith',
    Indirect_curriculam_status: 'David Lee',
    status: 'No due date',
    Curriculum_Title: 'No due date  Techniques',
    Training_Title: 'Negotiation Skills',
    Transcript_Due_Date: '2023-10-31',
    Transcript_Completed_Date: '',
  },
  {
    id: 20,
    training_name: 'Good Documentation and Date and time',
    curriculam_status: 'David Lee',
    Indirect_curriculam_status: '',
    status: 'Completed on time',
    Curriculum_Title: 'Database Management',
    Training_Title: 'SQL Fundamentals',
    Transcript_Due_Date: '2024-02-28',
    Transcript_Completed_Date: '',
  },
  {
    id: 21,
    training_name: 'William Chen',
    curriculam_status: 'Alice Green',
    Indirect_curriculam_status: 'David Lee',
    status: 'Incomplete',
    Curriculum_Title: 'Leadership Development',
    Training_Title: 'Coaching and Mentoring',
    Transcript_Due_Date: '2023-11-15',
    Transcript_Completed_Date: '',
  },
  {
    id: 22,
    training_name: 'Yuna Kim',
    curriculam_status: 'David Lee',
    Indirect_curriculam_status: '',
    status: 'Completed on time',
    Curriculum_Title: 'Artificial Intelligence',
    Training_Title: 'Machine Learning Basics',
    Transcript_Due_Date: '2024-03-31',
    Transcript_Completed_Date: '',
  },
];
