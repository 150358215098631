/**
* MyOrgTrainingCompletionReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const MyOrgTrainingCompletionReducer = (state = {}, action) => {
  if(action.type === 'GET_MY_ORG_COMPLETION_DATA'){
    return {
      ...state,
      completionDetails: action.payload,
    };
  }
  else{
    return state;
  }
};