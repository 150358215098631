/**
* TeamPerformanceReducer.
* This Reducer is used for Team Performance Data
* @param {object} state
* @param {string} action
* @returns {object}
*/
export const TeamPerformanceReducer = (state = {}, action) => {
  switch (action.type) {

    case 'GET_TRAINING_OVERDUE_DATA':
      return {
        ...state,
        trainingOverdueData: action.payload,
      };
    case 'GET_TRAINING_UPCOMINGDUE_DATA':
      return {
        ...state,
        trainingUpcomingdueData: action.payload,
      };
   
    case 'API_START':
      if (action.payload === "FETCH_TRAININGOVERDUE_DATA") {
        return {
          ...state,
          isLoadingTrainingOverDueData: true,
        };
      } else if (action.payload === "FETCH_TRAININGUPCOMINGDUE_DATA") {
        return {
          ...state,
          isLoadingTrainingUpcomingDueData: true,
        };
      } else {
        return state;
      }

    case 'API_END':
      if (action.payload === "FETCH_TRAININGOVERDUE_DATA") {
        return {
          ...state,
          isLoadingTrainingOverDueData: false,
        };
      } else if (action.payload === "FETCH_TRAININGUPCOMINGDUE_DATA") {
        return {
          ...state,
          isLoadingTrainingUpcomingDueData: false,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};