import React from 'react';
import { Box, Typography } from '@mui/material';
import { RedTheme } from 'common/global';

let redtheme = RedTheme;
// any app specific constants that you don't want to keep in config can go here.
export const TapGlobalMessages = {
  dashboard: {
    NOT_FOUND_404: '404: page not found!',
  },
  themes: ['RedTheme', 'BlueTheme'],
  URLS: { ASK_GS: 'https://jnjgsportal.my.site.com/s/submit-request?' },
  LOA: {
    LOA_NO_DATA:
      'There is no data available because you are indicated as being on a leave of absence in SUMMIT.',
    ASK_GS_HELPER:
      'If this is incorrect and you are not on a leave of absence, please open a ticket with AskGS to request that your profile be updated to reflect your current leave status.',
    LOA_NO_DATA_MANAGER:
      'You can’t access your Performance Data as you are indicated as being on ‘Leave of Absence’ in SUMMIT.',
    ASK_GS_HELPER_MANAGER: 'Please open a ticket with AskGS to request update on your status.',
  },
  columnHeaderTooltip: {
    jobDescriptionTooltip:
      'From EMERGE, latest manager review date for the Job Description (JD). If there is no value, the JD was not yet reviewed.',
    summaryOfExpTooltip:
      'From EMERGE, latest manager review date for the Summary of Experience (SOE). If there is no value, the SOE was not yet reviewed.',
    IndiviTrainingPlanTooltip:
      'From EMERGE, latest manager review date for the Individual Training Plan (ITP). If there is no value, the ITP was not yet reviewed.',
    completedOnTimeTooltip:
      'Completed on Time = total trainings completed on or before the due date / total trainings due for the past six (6) months.',
    completedOverallTooltip:
      'Completed Overall = total trainings completed (regardless of due date) / total trainings completed for current year plus past three (3) years.',
  },
  routeLabels: {
    myPerformance: 'My Performance',
    myTeamsPerformance: "My Team's Performance",
    trainingPlanView: 'Training Plan View',
    adminView: 'Administrative View',
  },
  routePaths: {
    myPerformance: '/',
    myTeamsPerformance: '/TAP/myorganisation',
    trainingPlanView: '/TAP/trainingview',
    adminView: '/TAP/adminview',
  },

  myPerformance: {
    statusOptionsList: [
      'Completed On Time',
      'Complete Overdue',
      'Incomplete',
      'Incomplete Overdue',
    ],
    myTrainingStatusDateRange: () => {
      return (
        <>
          <Box>
            The data is as per <Box sx={{ fontWeight: 'bold' }}>Training Due Date.</Box>
          </Box>
        </>
      );
    },
    completedOnTime:
      'Completed On Time = total trainings completed on or before the due date / total trainings completed',
    percentageCalculationHeader: 'Percentage Calculation',
    completedOverAll:
      'Completed Overall = total trainings completed (regardless of due date) / total trainings',
  },
  myTeamsPerformance: {
    statusOptionsList: ['Compliant', 'At Risk', 'Non-Compliant'],
    singleTeam: {
      searchByMangOrWWIDInfoTooltip: () => {
        return (
          <>
            <Box>
              {`User can search either by Username or by
                WWID based on their preference. For Example : If
                user types alphabets, the search feature will
                automatically start searching by Username and
                if user types numbers, the search feature
                will automatically start searching by WWID.`}
              <Box sx={{ marginTop: '5px' }}>
                <Typography component='span' sx={{ display: 'block', height: '15px' }}></Typography>
                <Typography component='span' sx={{ color: '#003479', fontWeight: 900 }}>
                  Please Note:{' '}
                </Typography>
                User cannot search both by Username and WWID simultaneously.
              </Box>
            </Box>
          </>
        );
      },
    },
    trainingsCompletedOnTimeTooltip:
      'Click the toggle to change the view from Trainings Completed on Time to Trainings Completed Overall.',
    trainingsCompletedOverAllTooltip:
      'Click the toggle to change the view from Trainings Completed Overall to Trainings Completed on Time.',
    statusMapper: param => {
      const statusMapper = [
        {
          title: 'Overdue Trainings',
          status: 'totalOverDue',
        },
      ];
      return statusMapper.filter(item => item.status === param);
    },
    trainingsOverdueTooltipHeader: 'Calculation',
    trainingsOverdueTooltipBody: (
      <Box>
        This view shows all{' '}
        <Box component='span' sx={{ fontWeight: 'bold' }}>
          active
        </Box>{' '}
        training that is past the due date. Inactive training that was past due at the time of
        inactivation is not shown in this view.
      </Box>
    ),
    trainingsComingDueTooltipHeader: 'Calculation',
    trainingsComingDueTooltipBody: (
      <Box>
        <Box>
          Click &quot;
          <Box
            component='span'
            sx={{
              color: redtheme.palette.warning.main,
              fontFamily: '"J&J CircularTT Medium Web"',
              fontSize: '10px',
              fontWeight: 'bold',
            }}
          >
            SEE MORE
          </Box>
          &quot; to see currently assigned and actionable training coming due within the time period
          selected.
        </Box>

        <br />

        <Box>
          <Box component='span' sx={{ color: '#003479', fontWeight: 900 }}>
            Please Note:
          </Box>{' '}
          This list does not show training that has not yet been assigned within SUMMIT.
        </Box>
      </Box>
    ),
    percentageCalculationHeader: 'Percentage Calculation',
  },
  trainingPlanView: {
    breifDescription:
      'This view reflects all curricula currently assigned in order to be qualified to perform the duties of the position. Click on a curriculum title to view the training items within.',
    directReports: {
      searchByTMOrWWIDInfoTooltip: () => {
        return (
          <>
            <Box>
              {`User can search either by Team Member or by
                WWID based on their preference. For Example : If
                user types alphabets, the search feature will
                automatically start searching by Team Member and
                if user types numbers, the search feature
                will automatically start searching by WWID.`}
              <Box sx={{ marginTop: '5px' }}>
                <Typography component='span' sx={{ display: 'block', height: '15px' }}></Typography>
                <Typography component='span' sx={{ color: '#003479', fontWeight: 900 }}>
                  Please Note:{' '}
                </Typography>
                User cannot search both by Team Member and WWID simultaneously.
              </Box>
            </Box>
          </>
        );
      },
      downloadExcelToolTip: () => {
        return (
          <>
            <Box>
              {`Please note, the Excel output may contain more information than is displayed on the screen.`}
            </Box>
          </>
        );
      },
    },
  },
  disclaimertext: {
    myperdisclaimertext:
      'Job Description, Summary of Experience and Individual Training Plan data will only be coming from the EMERGE platform. When your information is in EMERGE, the data fields in TAP will be updated and reflected here.',
    myorgdisclaimertext:
      'Job Description, Summary of Experience and Individual Training Plan data will only be coming from the EMERGE platform. When the information for your team is in EMERGE, the data fields in TAP will be updated and reflected here.',
    admindisclaimertext:
      'Job Description, Summary of Experience and Individual Training Plan data will only be coming from the EMERGE platform. When your organization information is in EMERGE, the data fields in TAP will be updated and reflected here.',
  },
  tapMaintenance: 'TAP under Maintenance!',
  unAuthorizedAccess: 'Unauthorized Access',
  siteWillBack: 'Site will be back soon',
  userNotFound: 'User not found',
  //used before going to expire
  sessionGoingToExpireInactivity:
    'Your session is going to expire in next 60 seconds due to inactivity!',
  userIdlePopupTitle: 'You have been idle!',
  extendButtonText: 'Extend Session',
  //used after expired
  sessionExpiredText:
    'To protect privacy and enhance security, the page you are trying to access is no longer available. You will be redirected to DTH',
  sessionExpiredTitle: 'Your Session has Expired',
  redirectButtonLabel: 'Redirect',
  overallComplianceProfileTitle :'Overall Compliance Profile',
  myTeamsComplianceDonutLabelText : 'Overall Compliance </br> profile for my team',
  myOrgComplianceDonutLabelText: 'Overall Compliance </br> profile for my organization'
};
