import React, { useEffect } from 'react';
import { select, selectAll, arc, pie} from 'd3';
import Box from '@mui/material/Box';
import { NUMBER_ARRAY } from 'common/global/constants';

function drawChart(id, data, boxSize, colors) {
  selectAll('#' + id + ' svg').remove();
  let svg = select('#' + id);
  const innerRadSize = boxSize/NUMBER_ARRAY.twoPointEight;
  const outerRadSize = boxSize/NUMBER_ARRAY.twoPointFive;

  svg = svg
    .append('svg')
    .attr('preserveAspectRatio', 'xMaxYMax meet')
    .attr('height', '100%')
    .attr('width', '100%')
    .attr('viewBox', `0 0 ${boxSize} ${boxSize}`)
    .append('g')
    .attr('transform', `translate(${boxSize / NUMBER_ARRAY.two}, ${boxSize / NUMBER_ARRAY.two})`);

  svg
    .append('g')
    .append('text')
    .attr('text-anchor', 'middle')
    .text('*Current plus 4 past quarters')
    .style('font-size', '50px')
    .style('font-weight', '450')
    .style('line-height', '100px')
    .attr('transform', `translate(0, ${boxSize / NUMBER_ARRAY.twoPointTwo})`);

  
  // Creating a pie chart with radius 250
  const arcGenerator = arc().innerRadius((innerRadSize)).outerRadius((outerRadSize));
  // Define which field name will be used to render the chart data
  const pieGenerator = pie()
    .endAngle(NUMBER_ARRAY.minusTwo * Math.PI)
    .value(d => d.value);
  // Render pie chart
  const arcs = svg.selectAll().data(pieGenerator(data)).enter();
  arcs
    .append('path')
    .attr('d', arcGenerator)
    // .style("stroke", "black") // Add border
    // .style("stroke-width", 2)
    .style('fill', (_d, i) => colors[i % data.length])
    .append('title')
    .text((d, i) => {
      if (i === 0) {
        return `${d.data.value}% Completed`;
      } else {
        return `${d.data.value}% Incomplete`;
      }
    });

  arcs
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('dy', '.35em')
    .text((d, i) => {
      if (i === 0) {
        return `${d.data.value}%`;
      } else {
        return '';
      }
    })
    .style('fill', 'black')
    .style('font-size', '100px')
    .style('font-weight', 'bold');
}

export const PieChart = ({ id, data, boxSize, colors }) => {
  useEffect(() => {
    if (data) {
      let tranformData = [];
      let dataObj1 = {},
        dataObj2 = {};
      dataObj1.value = data.toFixed();
      tranformData.push(dataObj1);
      if (data < NUMBER_ARRAY.hundred) {
        dataObj2.value = NUMBER_ARRAY.hundred - dataObj1.value;
        tranformData.push(dataObj2);
      }
      drawChart(id, tranformData, boxSize, colors);
    }
  }, [id, data, boxSize, colors]);
  return <Box id={id} className='chart piechart'></Box>;
};