import React, { useEffect, useState } from 'react';
import { Stack, flexbox } from '@mui/system';
import { Button, Typography, Box, Divider } from '@mui/material';
import Grid from '@mui/system/Unstable_Grid/Grid';
import { useSelector } from 'react-redux';
import { RedTheme } from 'common/global';
import { NUMBER_ARRAY } from 'common/global/constants';
import { ToolTipWrapper } from 'common/components';
import { TapGlobalMessages } from 'TAP/global';
import { ComplianceDonutChartTooltipBody } from 'TAP/components';
import { useScreen } from 'common/hooks/useScreen';

const columnTitleStyle = {
  display: flexbox,
  fontSize: '16px',
  lineHeight: '16px',
  fontWeight: 400,
  textAlign: 'center',
};

const columnValueStyle = {
  display: flexbox,
  fontSize: '16px',
  lineHeight: '16px',
  fontWeight: 400,
  textAlign: 'center',
  marginTop: '10px',
};

const ButtonStyle = {
  textTransform: 'none',
  borderRadius: '10px',
  cursor: 'none',
  pointerEvents: 'none',
  marginTop: '30px',
  marginLeft: '70px',
};

export const MyComplianceStatus = () => {
  const screen = useScreen();
  const [myComplianceUserStatusData, setMyComplianceUserStatusData] = useState('');
  const redtheme = RedTheme;
 
  // Fetch MyComplianceStatus data and dashboard data from redux store
  let getMyComplianceStatusDetails = useSelector(
    (state) => state.myPerformanceComplianceStatusRd?.myComplainceStatusSearchData?.data[0],
  );

  useEffect(() => {
    if (getMyComplianceStatusDetails !== undefined) {
      setMyComplianceUserStatusData(getMyComplianceStatusDetails);
    } else {
      setMyComplianceUserStatusData('');
    }
  }, [getMyComplianceStatusDetails]);

  // MyComplianceStatusvalues conditions for color

  const ValueColor = (flag) => {
    if (flag === 'G') {
      return redtheme.palette.dataGrid.dark_moderate_lime_green;
    } else if (flag === 'Y') {
      return redtheme.palette.dataGrid.yellow;
    } else if (flag === 'R') {
      return redtheme.palette.dataGrid.red;
    } else {
      return redtheme.palette.fontColor.dark_grayish_orange;
    }
  };

  const CPValue = () => {
    if (myComplianceUserStatusData?.inspectionReadiness_Indicator === 'G') {
      return 'Compliant';
    } else if (myComplianceUserStatusData?.inspectionReadiness_Indicator === 'Y') {
      return 'At Risk';
    } else if (myComplianceUserStatusData?.inspectionReadiness_Indicator === 'R') {
      return 'Non-Com.';
    } else {
      return ' ';
    }
  };

  const CPValueColor = () => {
    const getCPValue = CPValue();
    if (getCPValue === 'Compliant') {
      return redtheme.palette.dataGrid.white;
    } else if (getCPValue === 'At Risk') {
      return redtheme.palette.dataGrid.very_dark_grey;
    } else if (getCPValue === 'Non-Com.') {
      return redtheme.palette.dataGrid.white;
    } else {
      return redtheme.palette.fontColor.dark_grayish_orange;
    }
  };

  // MyComplianceStatusvalues data
  const title = ['My Compliance', 'Status'];
  const Header = [
    'Job Description',
    'Summary of',
    'Experience',
    'Individual Training',
    'Plan',
    'Training Completed',
    'on Time',
    'Training Completed',
    'Overall',
    'Compliance',
    'Profile',
  ];

  const values = [
    {
      value: myComplianceUserStatusData?.jobDescription,
      color: ValueColor(myComplianceUserStatusData?.jobDescription_Flag),
      label: 'Job Description',
    },
    {
      value: myComplianceUserStatusData?.summaryOfExperience,
      color: ValueColor(myComplianceUserStatusData?.summaryOfExperience_Flag),
      label: 'Summary of Experience',
    },
    {
      value: myComplianceUserStatusData?.individualTrainingPlan,
      color: ValueColor(myComplianceUserStatusData?.individualTrainingPlan_Flag),
      label: 'Individual Training Plan',
    },
    {
      value: myComplianceUserStatusData?.training_completed_on_time_percent,
      color: ValueColor(myComplianceUserStatusData?.training_completed_on_time_percent_Flag),
      label: 'Training Completed on Time',
    },
    {
      value: myComplianceUserStatusData?.training_completed_overall_percent,
      color: ValueColor(myComplianceUserStatusData?.training_completed_overall_percent_Flag),
      label: 'Training Completed Overall',
    },
    {
      value: CPValue(),
      color: ValueColor(myComplianceUserStatusData?.inspectionReadiness_Indicator),
      label: 'Compliance Profile',
    },
  ];

  const getColumnTitleStyle = () => ({
    ...columnTitleStyle,
    ...(screen.isTablet ? { fontSize: '14px', lineHeight: '16px' } : {}),
  });

  const getColumnValueStyle = () => ({
    ...columnValueStyle,
    ...(screen.isTablet ? { fontSize: '14px', lineHeight: '16px', margin: '10px 0 0 0' } : {}),
  });

  const getButtonStyle = () => ({
    ...ButtonStyle,
    ...(screen.isTablet ? { margin: '3px 0 0 0' } : {}),
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'nowrap',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: screen.resolveStyles({ mobile: '100%', deskto: '1900px' }, 'mobile'),
        height: screen.resolveStyles({ mobile: 'auto', desktop: '110px' }, 'mobile'),
        flexDirection: screen.isDesktop ? 'row' : 'column',
      }}
    >
      <Box
        width={screen.isDesktop ? '13%' : '100%'}
        sx={{
          display: 'flex',
          flexDirection: screen.isMobile ? 'row' : 'column',
          marginBottom: screen.resolveStyles({
            mobile: '12px',
            tablet: '8px',
            desktop: NUMBER_ARRAY.zero,
          }),
        }}
      >
        <Typography
          component='h4'
          variant='h4'
          sx={{
            alignContent: 'center',
            fontSize: screen.isTablet ? '25px' : '20px',
            lineHeight: screen.isTablet ? '32px' : '24px',
            fontWeight: 700,
            margin: screen.isMobile ? 0 : '0px 26px 0px 0px',
            fontFamily: '"JohnsonDisplay-Bold"',
          }}
        >
          {!screen.isDesktop ? title.join(' ') : title[0]}
        </Typography>
        {screen.isDesktop && (
          <Typography
            component='h4'
            variant='h4'
            sx={{
              alignContent: 'center',
              fontSize: '20px',
              lineHeight: '24px',
              fontWeight: 700,
              margin: screen.isMobile ? 0 : '0px 26px 0px 0px',
              fontFamily: '"JohnsonDisplay-Bold"',
            }}
          >
            {title[1]}
          </Typography>
        )}
      </Box>
      {screen.isMobile ? (
        <Stack width={'100%'} spacing={NUMBER_ARRAY.two} divider={<Divider />}>
          {values.map((item) => (
            <Box
              width={'100%'}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0 4px',
              }}
              key={item.label}
            >
              <Typography
                width={'60%'}
                sx={{ fontSize: '14px', lineHeight: '16px', fontWeight: '400' }}
              >
                {item.label}
              </Typography>
              <Box
                width={'40%'}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                {item.label === 'Compliance Profile' ? (
                  <Button
                    size='small'
                    sx={{
                      backgroundColor: item.color,
                      color: CPValueColor(),
                      borderRadius: '8px',
                      fontSize: '10px',
                      lineHeight: '12px',
                    }}
                  >
                    {' '}
                    {item.value}{' '}
                  </Button>
                ) : (
                  <Typography
                    sx={{
                      color: item.color,
                      fontSize: '14px',
                      lineHeight: '16px',
                      fontWeight: '400',
                    }}
                  >
                    {item.value}
                  </Typography>
                )}
              </Box>
            </Box>
          ))}
        </Stack>
      ) : (
        <Box width={screen.isDesktop ? '87%' : '100%'}>
          <Grid container spacing={screen.isDesktop && NUMBER_ARRAY.four}>
            <Grid item xs={2}>
              <ToolTipWrapper
                title={TapGlobalMessages.columnHeaderTooltip.jobDescriptionTooltip}
                placement='top'
              >
                {<Typography sx={getColumnTitleStyle()}> {Header[0]} </Typography>}
              </ToolTipWrapper>
            </Grid>
            <Grid item xs={2}>
              <ToolTipWrapper
                title={TapGlobalMessages.columnHeaderTooltip.summaryOfExpTooltip}
                placement='top'
              >
                <Typography sx={getColumnTitleStyle()}> {Header[1]} </Typography>
                <Typography sx={getColumnTitleStyle()}> {Header[NUMBER_ARRAY.two]} </Typography>
              </ToolTipWrapper>
            </Grid>
            <Grid item xs={2}>
              <ToolTipWrapper
                title={TapGlobalMessages.columnHeaderTooltip.IndiviTrainingPlanTooltip}
                placement='top'
              >
                <Typography sx={getColumnTitleStyle()}> {Header[NUMBER_ARRAY.three]} </Typography>
                <Typography sx={getColumnTitleStyle()}> {Header[NUMBER_ARRAY.four]} </Typography>
              </ToolTipWrapper>
            </Grid>
            <Grid item xs={2}>
              <ToolTipWrapper
                title={TapGlobalMessages.columnHeaderTooltip.completedOnTimeTooltip}
                placement='top'
              >
                <Typography sx={getColumnTitleStyle()}> {Header[NUMBER_ARRAY.five]} </Typography>
                <Typography sx={getColumnTitleStyle()}> {Header[NUMBER_ARRAY.six]} </Typography>
              </ToolTipWrapper>
            </Grid>
            <Grid item xs={2}>
              <ToolTipWrapper
                title={TapGlobalMessages.columnHeaderTooltip.completedOverallTooltip}
                placement='top'
              >
                <Typography sx={getColumnTitleStyle()}> {Header[NUMBER_ARRAY.seven]} </Typography>
                <Typography sx={getColumnTitleStyle()}> {Header[NUMBER_ARRAY.eight]} </Typography>
              </ToolTipWrapper>
            </Grid>
            <Grid item xs={2}>
              <ToolTipWrapper title={<ComplianceDonutChartTooltipBody />} placement='top'>
                <Typography
                  sx={{
                    fontSize: '16px',
                    lineHeight: '16px',
                    display: flexbox,
                    textAlign: 'center',
                    fontWeight: 400,
                  }}
                >
                  {' '}
                  {Header[NUMBER_ARRAY.nine]}{' '}
                </Typography>
                <Typography sx={getColumnTitleStyle()}> {Header[NUMBER_ARRAY.ten]} </Typography>
              </ToolTipWrapper>
            </Grid>
          </Grid>
          <Divider
            sx={{
              marginBottom: '10.5px',
              marginTop: '10.5px',
              strokeWidth: 1.5,
            }}
          />
          <Grid container spacing={screen.isDesktop && NUMBER_ARRAY.seven}>
            <Grid item xs={2}>
              <Typography
                sx={{ marginLeft: '10px', color: values[0].color, ...getColumnValueStyle() }}
              >
                {' '}
                {values[0].value == ' ' ? 'dd.mm.yyyy' : values[0].value}{' '}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                sx={{ marginLeft: '10px', color: values[1].color, ...getColumnValueStyle() }}
              >
                {' '}
                {values[1].value == ' ' ? 'dd.mm.yyyy' : values[1].value}{' '}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ color: values[NUMBER_ARRAY.two].color, ...getColumnValueStyle() }}>
                {' '}
                {values[NUMBER_ARRAY.two].value == ' '
                  ? 'dd.mm.yyyy'
                  : values[NUMBER_ARRAY.two].value}{' '}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                sx={{ color: values[NUMBER_ARRAY.three].color, ...getColumnValueStyle() }}
              >
                {' '}
                {values[NUMBER_ARRAY.three].value}{' '}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography
                sx={{
                  marginRight: '10px',
                  color: values[NUMBER_ARRAY.four].color,
                  ...getColumnValueStyle(),
                }}
              >
                {' '}
                {values[NUMBER_ARRAY.four].value}{' '}
              </Typography>
            </Grid>
            <Grid item xs={NUMBER_ARRAY.two} display='flex' justifyContent='center'>
              <Button
                size='small'
                sx={{
                  backgroundColor: values[NUMBER_ARRAY.five].color,
                  color: CPValueColor(),
                  ...getButtonStyle(),
                  margin: NUMBER_ARRAY.zero,
                }}
              >
                {' '}
                {values[NUMBER_ARRAY.five].value}{' '}
              </Button>
            </Grid>
            {/* {screen.isDesktop && (
              <Typography sx={{ marginLeft: screen.isDesktop && '25px' }} align='center'>
                <Button
                  size='small'
                  sx={{
                    backgroundColor: values[NUMBER_ARRAY.five].color,
                    color: CPValueColor(),
                    ...getButtonStyle(),
                  }}
                >
                  {' '}
                  {values[NUMBER_ARRAY.five].value}{' '}
                </Button>
              </Typography> )}*/}
          </Grid>
        </Box>
      )}
    </Box>
  );
};
