import apiAction from 'common/redux/actions/apiAction';
import { POST_ADMINVIEW_POPUP_FILTER } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

/****
 * POST POPUP LISTING DETAILS
 ****/
const getAdminPopupUpFilterData = data => {
  return {
    type: 'GET_ADMIN_POPUP_FILTER_DATA',
    payload: data,
  };
};

export const PopupAdminFilterData = configParams => {
  return apiAction.postAPI({
    url: POST_ADMINVIEW_POPUP_FILTER(configParams.actionsType),
    data: configParams,
    onSuccess: getAdminPopupUpFilterData,
    onFailure: err => logError('Error occured in fetching dashboard data:', err),
    label: 'FETCH_ADMIN_POPUPFILTERDATA_DATA',
  });
};