import { apiEnd, apiStart } from '../redux/actions/appActions';

import axios from 'axios';
import { getAuthToken } from '../authentication/authToken';
import { loginRequest, msalConfig } from '../authentication/authConfig';
import { NUMBER_ARRAY } from 'common/global/constants';
import { InteractionRequiredAuthError, PublicClientApplication } from '@azure/msal-browser';

export const ApiMiddleware =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);

    if (action.type !== 'API') return;

    const { label } = action.payload;

    let token = getAuthToken();
    // axios default configs
    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || '';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['Authorization'] = `Bearer ${token.idToken}`;
    axios.defaults.headers.common['GraphToken'] = `Bearer ${token.graphToken}`;

    if (label) {
      dispatch(apiStart(label));
    }

    // Request for a new token before calling any API's
    return getToken()
      .then(() => {
        const newToken = getAuthToken();
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken.idToken}`;
        // call using new token
        handleAPICalls(action, dispatch);
      })
      .catch(() => {
        // could not get a new token- use old token; response will handle if token expired
        handleAPICalls(action, dispatch);
      });
  };

const getToken = async () => {
  const instance = new PublicClientApplication(msalConfig);
  // Initialize the instance
  await instance.initialize();
  let account = instance.getAllAccounts()[0];
  const config = {
    scopes: loginRequest.scopes,
    authority: msalConfig.auth.authority,
    account: account,
  };
  const redRequest = {
    scopes: loginRequest.scopes,
    loginHint: account?.username,
  };
  return instance
    .acquireTokenSilent(config)
    .then(
      (res) => {
        return res;
      },
      (err) => {
        if (err instanceof InteractionRequiredAuthError) {
          return instance.acquireTokenRedirect(redRequest);
        }
      },
    )
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        return instance.acquireTokenRedirect(redRequest);
      }
    });
};

const handleAPICalls = (action, dispatch) => {
  const { url, method, data, onSuccess, label, headers } = action.payload;
  const dataOrParams = ['GET'].includes(method) ? 'params' : 'data';
  axios
    .request({
      url,
      method,
      headers,
      [dataOrParams]: data,
    })
    .then(({ data }) => {
      if (window.location.pathname.includes('TAP') && data === 'Data processing on going.') {
        if (window.location.pathname && window.location.pathname !== '/datarefresh')
          window.location.href = '/datarefresh';
      } else if (
        window.location.pathname === '/datarefresh' &&
        data !== 'Data processing on going.'
      ) {
        window.location.href = '/';
      } else if (
        !data &&
        window.location.pathname !== undefined &&
        window.location.pathname !== '/unauthorized'
      ) {
        window.location.href = '/unauthorized';
      } else if (data && typeof data === 'object' && window.location.pathname === '/unauthorized') {
        window.location.href = '/';
      } else {
        dispatch(onSuccess(data));
      }
    })
    .catch((error) => {
      if (
        error.response &&
        (error.response.status === NUMBER_ARRAY.fourHundredThree ||
          error.response.status === NUMBER_ARRAY.fourHundredOne)
      ) {
        // failed to acquire token- logout user
        console.log('failed to acquire token');
        const instance = window.msalInstance;
        instance.logout();
      }
      if (
        error.response &&
        (error.response.status === NUMBER_ARRAY.fiveHundredTwo ||
          error.response.status === NUMBER_ARRAY.fourHundredOne ||
          error.response.status === NUMBER_ARRAY.fourHundredFour)
      ) {
        setTimeout(() => {
          if (window.location.pathname !== undefined && window.location.pathname !== '/error')
            window.location.href = '/error';
        }, NUMBER_ARRAY.twoThousand);
      }
    })
    .finally(() => {
      if (label) {
        dispatch(apiEnd(label));
      }
    });
};
