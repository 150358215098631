/**
* FilterMyOrgReducer.
* This Reducer is used for Filter My organization data
* @param {object} state
* @param {string} action
* @returns {object}
*/
export const FilterMyOrgReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_MY_ORG_TRAINIGS_FILTERED_DATA':
            return {
                ...state,
                myOrgTrainingsFilteredData: action.payload,
            };
        case 'GET_MY_ORG_TRAINIGS_SEARCH_DATA':
            return {
                ...state,
                myOrgTrainingsSearchSuggestions: action.payload,
            };
        case 'API_START':
            if (action.payload === 'FILTER_MY_ORG_TRAININGS_DATA') {
                return {
                    ...state,
                    isFilteringMyOrgTrainingsData: true,
                };
            } else if (action.payload === 'SEARCH_MY_ORG_TRAININGS_DATA') {
                return {
                    ...state,
                    isSearchingMyOrgTrainingsData: true,
                };
            } else {
                return state;
            }

        case 'API_END':
            if (action.payload === 'FILTER_MY_ORG_TRAININGS_DATA') {
                return {
                    ...state,
                    isFilteringMyOrgTrainingsData: false,
                };
            } else if (action.payload === 'SEARCH_MY_ORG_TRAININGS_DATA') {
                return {
                    ...state,
                    isSearchingMyOrgTrainingsData: false,
                };
            } else {
                return state;
            }

        default:
            return state;
    }
};
