export const pagination = {
  pg5: 5, 
  pg10: 10, 
  pg15: 15,
  pg20: 20, 
  pg30: 30, 
  pg50: 50, 
  pg70: 70,
  pg100: 100
};

