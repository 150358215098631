import React from 'react';
import { useSelector } from 'react-redux';
import { BlockOutlined } from '@mui/icons-material';
import loadingSpinner from 'common/assets/images/loading-spinner.gif';

export function ShowLoader() {
  const isLoadingMyPerfTrainingStatusData = useSelector(
    state => state.trainingMetricsRd?.isLoadingMyPerfTrainingStatusData,
  );
  const isLoadingCompletedOnTimeData = useSelector(
    state => state.trainingMetricsRd?.isLoadingCompletedOnTimeData,
  );
  const isLoadingCompletedOverallData = useSelector(
    state => state.trainingMetricsRd?.isLoadingCompletedOverallData,
  );
  const isLoadingMyOrgCompletionData = useSelector(
    state => state.myOrgTrainingCompletionRd?.isLoadingMyOrgCompletionData,
  );
  const isLoadingMyDirectCompletionData = useSelector(
    state => state.myDirectTrainingCompletionRd?.isLoadingMyDirectCompletionData,
  );
  const isLoadingMyDirectComplianceData = useSelector(
    state => state.MyDirectReporteeComplianceProfileRd?.isLoadingMyDirectComplianceData,
  );
  const isLoadingNonCompliantData = useSelector(
    state => state?.myOrgComplianceActionsRd?.isLoadingNonCompliantData,
  );
  const isLoadingAtRiskData = useSelector(
    state => state?.myOrgComplianceActionsRd?.isLoadingAtRiskData,
  );
  const isLoadingDRNonCompliantData = useSelector(
    state => state?.directReporteeComplianceActionsRd?.isLoadingDRNonCompliantData,
  );
  const isLoadingDRAtRiskData = useSelector(
    state => state?.directReporteeComplianceActionsRd?.isLoadingDRAtRiskData,
  );
  const isLoadingTrainingOverDueData = useSelector(
    state => state?.teamPerformanceRd?.isLoadingTrainingOverDueData,
  );
  const isLoadingTrainingUpcomingDueData = useSelector(
    state => state?.teamPerformanceRd?.isLoadingTrainingUpcomingDueData,
  );
  const isLoadingDRTrainingOverDueData = useSelector(
    state => state?.teamPerformanceDirectRd?.isLoadingDRTrainingOverDueData,
  );
  const isLoadingDRTrainingUpcomingDueData = useSelector(
    state => state?.teamPerformanceDirectRd?.isLoadingDRTrainingUpcomingDueData,
  );
  let isFilteringMyOrgTrainingsData = useSelector(
    state => state.filterMyOrgTrainingsRd?.isFilteringMyOrgTrainingsData,
  );
  let isFetchingTVIndividualUserData = useSelector(
    state => state.trainingViewIndividualUserRd?.isFetchingTVIndividualUserData,
  );
  let isFetchingTVManagerDRData = useSelector(
    state => state.tapTrainingPlanViewDirectReporteeMrgSnglTeamRd?.isFetchingTVManagerDRData,
  );
  const isFetchingAVCompleteStatus = useSelector(
    state => state.tapAdminViewCompleteStatusRd?.isFetchingAVCompleteStatus,
  );
  const isFetchingAVComplianceStatus = useSelector(
    state => state.adminComplianceProfileRd?.isFetchingAVComplianceStatus,
  );
  const isLoadingAdminNonCompliantData = useSelector(
    state => state?.adminComplianceActionsRd?.isLoadingAdminNonCompliantData,
  );
  const isLoadingAdminAtRiskData = useSelector(
    state => state?.adminComplianceActionsRd?.isLoadingAdminAtRiskData,
  );
  const isLoadingADTrainingOverDueData = useSelector(
    state => state?.adminRd?.isLoadingADTrainingOverDueData,
  );
  const isLoadingADTrainingUpcomingDueData = useSelector(
    state => state?.adminRd?.isLoadingADTrainingUpcomingDueData,
  );

  return (
    <div>
      {isLoadingMyPerfTrainingStatusData ||
      isLoadingCompletedOnTimeData ||
      isLoadingCompletedOverallData ||
      isLoadingMyOrgCompletionData ||
      isLoadingMyDirectCompletionData ||
      isLoadingMyDirectComplianceData ||
      isLoadingDRNonCompliantData ||
      isLoadingDRAtRiskData || 
      isLoadingNonCompliantData ||
      isLoadingAtRiskData ||
      isLoadingTrainingOverDueData ||
      isLoadingTrainingUpcomingDueData ||
      isLoadingDRTrainingOverDueData ||
      isLoadingDRTrainingUpcomingDueData ||
      isFilteringMyOrgTrainingsData ||
      isFetchingTVIndividualUserData ||
      isFetchingTVManagerDRData || 
      isFetchingAVCompleteStatus ||
      isFetchingAVComplianceStatus ||
      isLoadingAdminNonCompliantData ||
      isLoadingADTrainingOverDueData || 
      isLoadingADTrainingUpcomingDueData || 
      isLoadingAdminAtRiskData ? (
        <div
          className='loading-spinner'
          style={{
            position: 'fixed',
            top: '0px',
            left: '0px',
            height: '100vh',
            width: '98.9vw',
            zIndex: 99999,
            backgroundColor: 'rgb(0 0 0 / 60%)',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            style={{
              height: '80px',
              margin: 'auto',
              display: BlockOutlined,
            }}
            src={loadingSpinner}
            alt='Loading...'
          />
        </div>
      ) : (
        ''
      )}
    </div>
  );
}
