import { TapTrainingPlanViewMngSnglTeamCurriculumFltrReducer, TapTrainingPlanViewMngSnglTeamTrainingFltrReducer, TapTrainingPlanViewMngSnglTeamWWIDFltrReducer } from 'TAP/redux/reducers/filterTrainingPlanViewMngerSnglTeamReducers';
import { TrainingViewIndividualUserReducer, TrainingViewIndividualUserSearchSuggestionsReducer } from 'TAP/redux/reducers/trainingViewIndividualUserReducer';

import { FilterMyOrgReducer } from 'TAP/redux/reducers/filterMyOrgReducer';
import { FilterPopUpDetailsReducer } from 'TAP/redux/reducers/filterPopUpDetailsReducer';
import { FilterDirectReporteeActionPopUpDetailsReducer } from 'TAP/redux/reducers/filterPopUpDirectReporteeActionReducer';
import { FilterDirectReporteeTrainingPopUpDetailsReducer } from 'TAP/redux/reducers/filterPopUpDirectReporteeTrainingReducer';
import { MyOrgTrainingCompletionReducer } from 'TAP/redux/reducers/myOrgTrainingCompletionReducer';
import { MyDirectTrainingCompletionReducer } from 'TAP/redux/reducers/myDirectTrainingCompletionReducer';
import { MyOrgComplianceProfileReducer } from 'TAP/redux/reducers/myOrgComplianceProfileReducer';
import { MyDirectReporteeComplianceProfileReducer } from 'TAP/redux/reducers/myDirectReporteeComplianceProfileReducer';
import { PopUpDetailsReducer } from 'TAP/redux/reducers/popUpDetailsReducer';
import { DirectReporteePopUpDetailsReducer } from 'TAP/redux/reducers/directRporteePopUpDetailsReducer';
import { PopUpReminderReducer } from 'TAP/redux/reducers/popUpReminderReducer';
import { TapManagerSingleTeamFilterReducer } from 'TAP/redux/reducers/filterManagerSingleTeamReducer';
import { TapManagerSingleTeamReducer } from 'TAP/redux/reducers/managerSingleTeamReducer';
import { TapTrainingPlanViewDirectReporteeMrgSnglTeamReducer } from 'TAP/redux/reducers/trainingPlanViewDRMngSinglTeamReducer';
import { TapUserDetailsReducer } from 'TAP/redux/reducers/tapUserDetailsReducer';
import { TeamPerformanceReducer } from 'TAP/redux/reducers/teamPerformanceReducer';
import { TeamPerformanceDirectReducer } from 'TAP/redux/reducers/teamPerformanceDirectReducer';
import { AdminReducer } from 'TAP/redux/reducers/AdminReducer';
import { MyOrgComplianceActionsReducer } from 'TAP/redux/reducers/myOrgComplianceActionsReducer';
import { DirectReporteeComplianceActionsReducer } from 'TAP/redux/reducers/directReporteeComplianceActionReducer';
import { ThemeSwitcherReducer } from './themeSwitcherReducer';
import { TrainingMetricsReducer } from 'TAP/redux/reducers/trainingMetricsReducer';
import { TrainingPlanPopupReducer } from 'TAP/redux/reducers/trainingPlanPopupReducer';
import { AdminViewCompleteStatReducer } from 'TAP/redux/reducers/adminViewCSReducer';
import { AdminComplianceProfileReducer } from 'TAP/redux/reducers/adminComplianceProfileReducer';
import { AdminComplianceActionsReducer } from 'TAP/redux/reducers/adminComplianceActionsReducer';
import { AdminPopUpDetailsReducer } from 'TAP/redux/reducers/adminPopUpDetailsReducer';
import { AdminFilterPopUpDetailsReducer } from 'TAP/redux/reducers/adminFilterPopUpDetailsReducer';
import { myPerformanceComplianceStatusReducer } from 'TAP/redux/reducers/myPerformanceCSReducer';
import { combineReducers } from 'redux';
import { AdminQuickFilterReducer } from 'TAP/redux/reducers/adminQuickFilterReducer';
import { AdminFavouriteFilterReducer } from 'TAP/redux/reducers/adminFavouriteFilterReducer';
import { AdminManageFilterListReducer,AdminManageFilterLoadReducer , AdminManageFilterDeleteReducer } from 'TAP/redux/reducers/adminManageFilterReducer';

export const RootReducer = () =>
  combineReducers({
    themeSwitcherRd: ThemeSwitcherReducer,
    tapUserDetailsRd: TapUserDetailsReducer,
    trainingMetricsRd: TrainingMetricsReducer,
    teamPerformanceRd: TeamPerformanceReducer,
    teamPerformanceDirectRd: TeamPerformanceDirectReducer,
    adminRd: AdminReducer,
    myOrgTrainingCompletionRd: MyOrgTrainingCompletionReducer,
    myDirectTrainingCompletionRd: MyDirectTrainingCompletionReducer,
    myOrgComplianceProfileRd: MyOrgComplianceProfileReducer,
    MyDirectReporteeComplianceProfileRd : MyDirectReporteeComplianceProfileReducer,
    myOrgComplianceActionsRd: MyOrgComplianceActionsReducer,
    directReporteeComplianceActionsRd: DirectReporteeComplianceActionsReducer,
    filterMyOrgTrainingsRd: FilterMyOrgReducer,
    tapManagerSingleTeamReducerRd: TapManagerSingleTeamReducer,
    tapManagerSingleTeamFilterRd: TapManagerSingleTeamFilterReducer,
    tapPopUpDetailsRd: PopUpDetailsReducer,
    tapDirectReporteePopUpDetailsRd: DirectReporteePopUpDetailsReducer,
    tappopUpFilterRd: FilterPopUpDetailsReducer,
    directReporteeActionpopUpFilterRd: FilterDirectReporteeActionPopUpDetailsReducer,
    directReporteeTrainingpopUpFilterRd: FilterDirectReporteeTrainingPopUpDetailsReducer,
    tapPopUpReminder: PopUpReminderReducer,
    trainingViewIndividualUserRd: TrainingViewIndividualUserReducer,
    trainingViewIndividualUserSearchSuggestionsRd: TrainingViewIndividualUserSearchSuggestionsReducer,
    tapTrainingPlanPopupRd: TrainingPlanPopupReducer,
    tapTrainingPlanViewDirectReporteeMrgSnglTeamRd: TapTrainingPlanViewDirectReporteeMrgSnglTeamReducer,
    tapAdminViewCompleteStatusRd: AdminViewCompleteStatReducer,
    tapTrainingPlanViewMngSnglTeamWWIDFltrRd: TapTrainingPlanViewMngSnglTeamWWIDFltrReducer,
    tapTrainingPlanViewMngSnglTeamCurriculumFltrRd: TapTrainingPlanViewMngSnglTeamCurriculumFltrReducer,
    tapTrainingPlanViewMngSnglTeamTrainingFltrRd: TapTrainingPlanViewMngSnglTeamTrainingFltrReducer,
    adminComplianceProfileRd: AdminComplianceProfileReducer,
    adminComplianceActionsRd: AdminComplianceActionsReducer,
    tapAdminPopUpDetailsRd : AdminPopUpDetailsReducer,
    adminPopUpFilterRd: AdminFilterPopUpDetailsReducer,
    myPerformanceComplianceStatusRd: myPerformanceComplianceStatusReducer,
    adminQuickFilterRd: AdminQuickFilterReducer,
    adminFavouriteFilterRd: AdminFavouriteFilterReducer,
    adminManageFilterListRd : AdminManageFilterListReducer,
    adminManageFilterLoadRd : AdminManageFilterLoadReducer,
    adminManageFilterDeleteRd : AdminManageFilterDeleteReducer,
    router: '', //connectRouter(history),
  });
