import apiAction from 'common/redux/actions/apiAction';
import { POST_MANAGER_SINGLETEAM_URL } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';


/****
 * POST MANAGER SINGLE TEAM fILTER DETAILS
 ****/
const getFilterManagerSingleTeamDataByWWID = data => {
    return {
        type: 'POST_FILTER_MANAGER_SINGLETEAM_MEMBER_DATA_BYWWID',
        payload: data,
    };
};

export const FilterManagerSingleTeamDataWWID = configParams => {
    return apiAction.postAPI({
        url: POST_MANAGER_SINGLETEAM_URL(),
        data: configParams,
        onSuccess: getFilterManagerSingleTeamDataByWWID,
        onFailure: err => logError('Error occured in fetching dashboard data:', err),
        label: 'FETCH_FILTER_MANAGER_SINGLETEAM_MEMBER_DATA_BYWWID',
    });
};
