import apiAction from 'common/redux/actions/apiAction';
import { logError } from 'TAP/utils/commonMethods';

import {
    POST_MY_DIRECT_REPOTEE_COMPLETION_URL
} from './apiPathConstants';

const getMyDirectReporteeCompletionData = (data) => {
  return {
    type: 'GET_MY_DIRECT_REPORT_COMPLETION_DATA',
    payload: data,
  };
};

export const MyDirectCompletionData = (configParams) => {
  return apiAction.postAPI({
    url: POST_MY_DIRECT_REPOTEE_COMPLETION_URL(),
    data: configParams,
    onSuccess: getMyDirectReporteeCompletionData,
    onFailure: err => logError('Error occured in fetching MyDirectCompletionData data.:', err),
    label: 'FETCH_MYDIRECTREPORT_COMPLETION_DATA',
  });
};