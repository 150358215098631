import { FormControl, MenuItem, Select, Typography, Grid, Button } from "@mui/material"
import { RedTheme } from "common/global";
import { DropDownIcon } from 'common/components';
import { ReactComponent as CrossIcon } from 'common/assets/images/CrossIcon.svg';
import { DeletePopup } from 'TAP/components/DeletePopup';
import { useState } from "react";

export const FavDropDown = ({
    options,
    indicator,
    selectFav,
    handleAdminStatusChange,
    handleFavDelete,
}) => {
    let redtheme = RedTheme;
    const [openPopup, setOpenPopup] = useState(false);
    const [deleteFilterName, setDeleteFiltername] = useState('');

    const handleDeletePopup = (e, value) => {
        setDeleteFiltername(value);
        e.stopPropagation();          //Stop the parent action   
    }

    return (
        <>
            <FormControl
                variant='standard'
                data-testid='status_filter'
                sx={{ width: '100%', mt: 2, fontFamily: '"JohnsonText-Medium"' }}
            >
                <Select
                    IconComponent={DropDownIcon}
                    displayEmpty={true}
                    value={selectFav}
                    onChange={(e) => handleAdminStatusChange(e, indicator)}
                    renderValue={selected => {
                        if (selected === undefined || selected?.length === 0) {
                            return (
                                <Typography
                                    sx={{
                                        color: redtheme.palette.fontColor.gray_orange,
                                        fontSize: '16px',
                                        fontWeight: 400,
                                    }}
                                >
                                   Select Saved Favourite 
                                </Typography>
                            );
                        }
                        return selected;
                    }}>
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {options?.map((obj, index) =>
                        <MenuItem key={index} value={obj.filterName}>
                            <Grid container>
                                <Grid item xs={11.35}>
                                    {obj.filterName}
                                </Grid>
                                <Grid item xs={0.65}>
                                    {
                                        <Button
                                            onClick={(e) => { handleDeletePopup(e, obj.filterName); setOpenPopup(true); }}
                                            startIcon={<CrossIcon />}
                                            sx={{
                                                maxWidth: '25px',
                                                height: '25px',
                                                borderRadius: '60px',
                                                padding: '0px'
                                            }}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </MenuItem>
                    )}
                </Select>
            </FormControl>
            <DeletePopup
                popupHeader='Are you sure you want to delete the "favourite filter"?'
                SubHeader="This item will be deleted permanently. You can't undo this action."
                leftBtnText='Cancel'
                rightBtnText='Delete'
                open={openPopup}
                deleteFilterName={deleteFilterName}
                handleFavDelete={handleFavDelete}
                handleClose={() => setOpenPopup(false)}
            />
        </>
    )
}