/**
* FilterPopUpDetailsReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const FilterDirectReporteeActionPopUpDetailsReducer = (state = {}, action) => {
    if(action.type === 'GET_DIRECT_REPORTEE_ACTION_POPUP_FILTER_DATA'){
      return {
        ...state,
        popupFilterData: action.payload,
      }
    }
    else{
      return state;
    }
  };
  