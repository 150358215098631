/**
* TapTrainingPlanViewMngSnglTeamWWIDFltrReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const TapTrainingPlanViewMngSnglTeamWWIDFltrReducer = (state = {}, action) => {
    if (action.type === 'POST_TPV_FILTER_MRNG_SNGLTEAM_MEMBER_DATA_BYWWID') {
        return {
            ...state,
            tpvmanagerSingleTeamFilterByWWID: action.payload,
        };
    }
    else {
        return state;
    }
};

/**
* TapTrainingPlanViewMngSnglTeamCurriculumFltrReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const TapTrainingPlanViewMngSnglTeamCurriculumFltrReducer = (state = {}, action) => {
    if(action.type === 'POST_TPV_FILTER_MRNG_SNGLTEAM_MEMBER_DATA_BYCURRICULUM'){
        return {
            ...state,
            tpvmanagerSingleTeamFilterByCurriculum: action.payload,
        };
    }
    else {
        return state;
    }
};

/**
* TapTrainingPlanViewMngSnglTeamTrainingFltrReducer.
* @param {object} state
* @param {object} action
* @returns {object}
*/
export const TapTrainingPlanViewMngSnglTeamTrainingFltrReducer = (state = {}, action) => {
    if(action.type === 'POST_TPV_FILTER_MRNG_SNGLTEAM_MEMBER_DATA_BYTRAINING'){
        return {
            ...state,
            tpvmanagerSingleTeamFilterByCurriculum: action.payload,
        };
    }
    else {
        return state;
    }
};

