import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
/**
* Request To Add Or Remove Trainings.
* Contain BOX and Link component
* @returns {JSX.Element}
*/
export const RequestToAddOrRemoveTrainings = () => {

    return (
        <>
            <Box>
            <Link
                        
                        underline='none'
                        href='mailto:ra-jacnl-lprdadmins@its.jnj.com'
                    >
                <Button
                    sx={{
                        float: 'right',
                        marginTop: '13px',
                        color: 'black',
                        border: `1px solid #212121`,
                        bgcolor: '#FFFFFF',
                        textTransform: 'none',
                        fontWeight: 500,
                        width: '293px', 
                        height: '32px', 
                        borderRadius: '8px',
                        fontSize: '16px',
                        fontFamily: '"JohnsonText-Medium"',
                        '&:hover': { backgroundColor: '#212121', color: `#FFFFFF`, fontFamily: '"JohnsonText-Medium"' },
                        '&:focus': { outline: 'none' },
                    }}
                >
                        Request to Add or Remove Trainings
                </Button>
                </Link>
            </Box>
        </>
    )
}
