import apiAction from 'common/redux/actions/apiAction';
import { POST_TRAININGPLANVIEW_DIRECTREPORTEE_MRGSINGLETEAM_URL } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';
/****
 * POST TRAINING PLAN VIEW DIRECT REPORTEE MANAGER SINGLE TEAM DETAILS
 ****/
const getDiretReporteeManagerSingleTeamDetails = data => {
    return {
        type: 'POST_TRAININGPLANVIEW_DIRECTREPORTEE_MANAGERSINGLETEAM_DETAILS',
        payload: data,
    };
};

export const DirectReporteeManagerSingleTeamDetails = configParams => {
    return apiAction.postAPI({
        url: POST_TRAININGPLANVIEW_DIRECTREPORTEE_MRGSINGLETEAM_URL(),
        data: configParams,
        onSuccess: getDiretReporteeManagerSingleTeamDetails,
        onFailure: err => logError('Error occured in fetching dashboard data.',err),
        label: 'FETCH_TRAININGPLANVIEW_DIRECTREPORTEE_MANAGERSINGLETEAM_DATA',
    });
};
