import React from 'react';
import { messages } from 'common/global';

export const NotFound = () => {
  return (
    <>
      {/* Page Not Found! */}
      <h1>{messages.dashboard.NOT_FOUND_404}</h1>
    </>
  );
};
