/**
* TeamPerformanceReducer.
* This Reducer is used for Team Performance Data
* @param {object} state
* @param {string} action
* @returns {object}
*/
export const TeamPerformanceDirectReducer = (state = {}, action) => {
    switch (action.type) {
  
      case 'GET_DIRECT_TRAINING_OVERDUE_DATA':
        return {
          ...state,
          trainingOverdueData: action.payload,
        };
      case 'GET_DIRECT_TRAINING_UPCOMINGDUE_DATA':
        return {
          ...state,
          trainingUpcomingdueData: action.payload,
        };
     
      case 'API_START':
        if (action.payload === "FETCH_DIRECT_TRAININGOVERDUE_DATA") {
          return {
            ...state,
            isLoadingDRTrainingOverDueData: true,
          };
        } else if (action.payload === "FETCH_DIRECT_TRAININGUPCOMINGDUE_DATA") {
          return {
            ...state,
            isLoadingDRTrainingUpcomingDueData: true,
          };
        } else {
          return state;
        }
  
      case 'API_END':
        if (action.payload === "FETCH_DIRECT_TRAININGOVERDUE_DATA") {
          return {
            ...state,
            isLoadingDRTrainingOverDueData: false,
          };
        } else if (action.payload === "FETCH_DIRECT_TRAININGUPCOMINGDUE_DATA") {
          return {
            ...state,
            isLoadingDRTrainingUpcomingDueData: false,
          };
        } else {
          return state;
        }
  
      default:
        return state;
    }
  };