export const MyOrgComplianceProfileReducer = (state = {}, action) => {
  if (action.type === 'GET_MY_ORG_COMPLIANCE_DATA') {
    return {
      ...state,
      orgComplianceDetails: action.payload,
    };
  } else {
    return state;
  }
};
