import React, {useState, useEffect, useRef} from 'react';
import { Tooltip, Typography, Box, tooltipClasses, styled } from '@mui/material';
import {  RedTheme } from 'common/global';
import { useScreen } from 'common/hooks/useScreen';

let redtheme =RedTheme;
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: redtheme.palette.tooltip.black_gray,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: redtheme.palette.tooltip.black_gray,
    color: redtheme.palette.tooltip.white ,
    maxWidth: '300px',
    fontSize: '16px',
    //border: `1px solid ${redtheme.palette.tooltip.white}`,
    borderRadius: '18px',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    padding: '12px',
    gap: '8px',
    maxHeight: '40px',
  },
}));

export const GenericPopupTooltip = ({ title, placement, header, children}) => {
  const screen = useScreen(); 
  const [isToolTipOpen,setIsToolTipOpen] = useState(false); 
  const tooltipRef = useRef(null);
  const toolTipTitle = () => {
    return (<>
          <Typography
            sx={{
              fontFamily: '"JohnsonText-Regular"',
              color: redtheme.palette.tooltip.white ,
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '12px',
            }}
          >
          {title}
          </Typography>
        </>)
  }
  const toolTipBody = () => {
    return (header === ' '? children : <Box sx={{ color: redtheme.palette.box.black_gray, fontSize: '14px', lineHeight: '16px', fontFamily: '"JohnsonText-Bold"', fontWeight: 700}}>
        {header}
      </Box>)
  }
  const handleClickOutside = (event) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
      setIsToolTipOpen(false);
    }
  };
   useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  return (
    screen.isDesktop ? 
    <HtmlTooltip
      placement={placement}
      title={toolTipTitle()}
      arrow
    >
      {toolTipBody()}
    </HtmlTooltip>
    :
    <div ref={tooltipRef}>
    <HtmlTooltip
      placement={placement}
      title={toolTipTitle()}
      onClose={() => setIsToolTipOpen(false)}
      onClick={() => setIsToolTipOpen(!isToolTipOpen)}
      open={isToolTipOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      arrow
    >
      {toolTipBody()}
    </HtmlTooltip>
    </div>
  );
};
