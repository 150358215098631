import apiAction from 'common/redux/actions/apiAction';
import { POST_ADMIN_QUICK_FILTER } from './apiPathConstants';
import { logError } from 'TAP/utils/commonMethods';

const getAdminQuickFilter = data => {
  return {
    type: 'GET_ADMIN_QUICK_FILTER',
    payload: data,
  };
};

export const AdminQuickFilterAction = configParams => {
  return apiAction.postAPI({
    url: POST_ADMIN_QUICK_FILTER(),
    data: configParams,
    onSuccess: getAdminQuickFilter,
    onFailure: err => logError('Error occured in fetching Admin Quick Filter data:', err),
    label: 'FETCH_ADMIN_QUICK_FILTER',
  });
};
